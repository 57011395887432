<template lang="html">
  <div class="frameWrapperWide">
    <p class="subTitle">Texture Overview</p>
    <div class="textureWrapper">
      <svg class="leftArrow" v-if="pages > 1" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" @click="flipPage(-1)">
      <path d="M1 8.5L13 15.4282L13 1.5718L1 8.5Z" stroke-width="2" stroke-linejoin="round"/>
      </svg>
      <transition mode="out-in" :name="direction">
        <div class="textureContainer" :key="currentPage">
            <tasteTextureOverviewTile
            v-for= "(texture,index) in texturePages[currentPage]"
            class="textureTile"
            :key = "index"
            :title = "texture.title"
            :main = "texture.main"
            :taste = "texture.taste"
            :style="[{flexBasis: setFlexBasis, height: setHeight}]"
            />
        </div>
    </transition>
      <svg class="rightArrow" v-if="pages > 1" width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" @click="flipPage(1)">
        <path d="M16 8.5L4 15.4282L4 1.5718L16 8.5Z" stroke-width="2" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="pageIndicator" v-if="pages > 1">
      <UiPageIndicator
      v-for="page in pages"
      :key="page"
      :class="{active: currentPage === page - 1}"
      @click="currentPage = page-1"
      />
    </div>
  </div>
</template>

<script>
import TasteTextureOverviewTile from '@/components/TasteTextureOverviewTile.vue';
import UiPageIndicator from '@/components/UiPageIndicator.vue';

export default {
  components: {
    TasteTextureOverviewTile,
    UiPageIndicator,
  },
  created() {
    this.currentPage = 0;
    if (window.innerWidth < 750) {
      this.mobile = true;
      this.calcPages(8);
    } else {
      this.mobile = false;
      this.calcPages(8);
    }
  },
  methods: {
    calcPages(num) {
      this.texturePages = [];
      this.pages = Math.ceil(this.textures.length / num);
      if (this.pages > 1) {
        for (let i = 0; i < this.pages; i += 1) {
          this.texturePages.push(this.textures.slice(i * num, ((i + 1) * num)));
        }
      } else {
        this.centerFlex = !this.mobile;
        this.texturePages.push(this.textures.slice(0, this.textures.length));
      }
    },
    flipPage(direction) {
      const newPage = this.currentPage + direction;
      // // // console.log(newPage);
      if (newPage > this.pages - 1) {
        this.currentPage = 0;
      } else if (newPage < 0) {
        this.currentPage = this.pages - 1;
      } else {
        this.currentPage = newPage;
      }
      if (direction === -1) { this.direction = 'right'; }
      if (direction === 1) { this.direction = 'left'; }
    },
  },
  watch: {
    windowWidth(newValue) {
      // // console.log(newValue);
      this.currentPage = 0;
      if (newValue < 750) {
        this.mobile = true;
        this.calcPages(8);
      } else {
        this.mobile = false;
        this.calcPages(8);
      }
    },
  },
  computed: {
    windowWidth() {
      // // console.log(this.$store.state.windowWidth);
      return this.$store.state.windowWidth;
    },
    setFlexBasis() {
      if (this.mobile) return '46.2%';
      if (this.texturePages[0].length < 4) { return `${((100 / this.texturePages[0].length) - 1)}%`; }
      return '24%';
    },
    setHeight() {
      if (this.mobile) return '22%';
      if (this.texturePages[0].length > 4) { return '43%'; }
      return '100%';
    },
  },
  props: {
    textures: Array,
    keyTextures: Array,
  },
  data() {
    return {
      mobile: false,
      centerFlex: false,
      direction: 'left',
      currentPage: 0,
      pages: 1,
      texturePages: [],
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.frameWrapperWide
  // display: inline-flex
  // flex-direction: column;
  // justify-content: space-between;
  width: (1414/1080 * 100)vh
  height: (470/1080 * 100)vh
  font-feature-settings: 'ss02' on;
  background-color: $white
  box-shadow: 0 0 0 (2/1080*100)vh $black
  border-radius: $tileRadiusDesktop
  overflow: hidden
  position: relative
  text-align: center

  .textureWrapper
    display: flex
    padding: (10/1080*100)vh 0
    padding-top:0
    height: (380/1080*100)vh
    margin-top: (20/1080*100)vh
    justify-content: center

    .leftArrow
      align-self: center
      height: (16/1080*100)vh
      flex-shrink: 0
      stroke: $black
      fill: $white
      margin-left: (10/1080*100)vh
      transition: all 150ms ease-out

      &:hover
        fill: $uiPurple
        cursor: pointer

    .rightArrow
      align-self: center
      height: (16/1080*100)vh
      margin-right: (10/1080*100)vh
      flex-shrink: 0
      stroke: $black
      fill: $white
      transition: all 150ms ease-out
      &:hover
        fill: $uiPurple
        cursor: pointer

    .textureContainer
      display: flex
      flex-wrap: wrap
      width: (1350/1080 * 100)vh
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: (17/1080*100)vh

      .textureTile
        // height: 43%
        // flex-basis: 32%
        margin: (4/1080*100)vh
        margin-right: 0.72vh

      &.left-enter-active, &.left-leave-active
        transition: all 150ms ease-out;

      &.left-enter-from
        transform: translateX(30%)
        opacity: 0

      &.left-leave-to
        transform: translateX(-30%)
        opacity: 0

      &.right-enter-active, &.right-leave-active
        transition: all 150ms ease-out;

      &.right-enter-from
        transform: translateX(-30%)
        opacity: 0

      &.right-leave-to
        transform: translateX(30%)
        opacity: 0

  .pageIndicator
    display: inline-flex;
    flex-direction: row
    margin-bottom: (14/1080*100)vh
    margin-left: auto
    margin-right: auto
    .circle.active
      background-color: $uiPurple
      border-color: $uiPurple

  .subTitle
    padding-left: (14/1080*100)vh
    padding-top: (18/1080*100)vh
    text-align: left
    font-size: $subHead
    font-weight: 600
    color: $gray

@media (max-width: 750px)
  .frameWrapperWide
    // display: inline-flex
    // flex-direction: column;
    // justify-content: space-between;
    width: (470/1080 * 100)vh
    height: (942/1080 * 100)vh
    font-feature-settings: 'ss02' on;
    background-color: $white
    box-shadow: 0 0 0 (2/1080*100)vh $black
    border-radius: $tileRadiusDesktop
    overflow: hidden
    position: relative
    text-align: center

    .textureWrapper
      display: flex
      padding: (10/1080*100)vh 0
      padding-top:0
      height: (850/1080*100)vh
      margin-top: (20/1080*100)vh
      justify-content: center

      .leftArrow
        align-self: center
        height: (16/1080*100)vh
        flex-shrink: 0
        stroke: $black
        fill: $white
        margin-left: (10/1080*100)vh
        transition: all 150ms ease-out

        &:hover
          fill: $uiPurple
          cursor: pointer

      .rightArrow
        align-self: center
        height: (16/1080*100)vh
        margin-right: (10/1080*100)vh
        flex-shrink: 0
        stroke: $black
        fill: $white
        transition: all 150ms ease-out
        &:hover
          fill: $uiPurple
          cursor: pointer

      .textureContainer
        display: flex
        flex-wrap: wrap
        width: (420/1080 * 100)vh
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 0
        align-content: flex-start;

        .textureTile
          // height: 43%
          // flex-basis: 32%
          margin-bottom: 1.2rem
          margin-right: 0

    .pageIndicator
      display: inline-flex;
      flex-direction: row
      margin-bottom: (14/1080*100)vh
      margin-left: auto
      margin-right: auto
      .circle.active
        background-color: $uiPurple
        border-color: $uiPurple

    .subTitle
      padding-left: (14/1080*100)vh
      padding-top: (18/1080*100)vh
      text-align: left
      font-size: (13/16)rem
      font-weight: 600
      color: $gray

</style>
