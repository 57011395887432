import content from '../data/content';

function nextBiggest(arr) {
  let max = -Infinity; let result = -Infinity;
  arr.forEach((nr) => {
    if (nr > max) {
      [result, max] = [max, nr]; // save previous max
    } else if (nr < max && nr > result) {
      result = nr; // new second biggest
    }
  });
  return result;
}

export default function findTasteTrigger(tasteInput) {
  // Finde das Maximum im Objekt
  const tastesToKeep = Object.keys(tasteInput).filter((key) => {
    let keep = false;
    if (tasteInput[key] !== 0) keep = true;
    return keep;
  });

  const tastes = {};
  tastesToKeep.forEach((key) => {
    tastes[key] = tasteInput[key];
  });

  // Erstelle ein Array aus den Werten des Objekts
  const arr = Object.values(tastes);
  const secondMax = nextBiggest(arr);
  const max = Math.max(...arr);
  const min = Math.min(...arr);
  // Erstelle nun die Trigger basierend auf Min und Max
  // Harmony
  if (max - min <= 1) {
    // Setze den Trigger
    const triggerOut = {
      title: 'harmony',
      descrption: content.trigger.harmony,
    };
    return triggerOut;
  }
  // Ab hier sprechen wir von einem Peak
  // Finde herraus ob es ein Single Peak ist
  let peakCount = 0;
  arr.forEach((number) => {
    if (number === max) peakCount += 1;
  });
  // console.log(peakCount);

  // Wenn wir einen Peak haben und davon nur einen!
  if (max - secondMax >= 3 && peakCount === 1) {
    // finde den Peak im Tasteintensity Objekt
    const triggerTaste = Object.keys(tasteInput).filter((key) => {
      let keepKey = false;
      if (tasteInput[key] === max) keepKey = true;
      return keepKey;
    });
    // Setze den Trigger auf den Wert
    if (triggerTaste[0] === 'sweet') {
      const triggerOut = {
        title: 'sweet',
        descrption: content.trigger.sweet,
      };
      return triggerOut;
    }
    if (triggerTaste[0] === 'sour') {
      const triggerOut = {
        title: 'sour',
        descrption: content.trigger.sour,
      };
      return triggerOut;
    }
    if (triggerTaste[0] === 'salty') {
      const triggerOut = {
        title: 'salty',
        descrption: content.trigger.salty,
      };
      return triggerOut;
    }
    if (triggerTaste[0] === 'umami') {
      const triggerOut = {
        title: 'umami',
        descrption: content.trigger.umami,
      };
      return triggerOut;
    }
    if (triggerTaste[0] === 'fatty') {
      const triggerOut = {
        title: 'fatty',
        descrption: content.trigger.fatty,
      };
      return triggerOut;
    }
  }
  const triggerOut = {
    title: '',
    descrption: '',
  };
  return triggerOut;
}
