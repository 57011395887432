<template lang="html">
  <div class="fristScrollGroup">

    <taste-text-frame
    class="tasteTextFrame"
    title = "01 → Ingredients &amp; Taste"
    subTitle = "Description of Taste"
    :text="taste.text"
    @toggleTrigger="handleTrigger"
    @hoverTrigger="handleHoverTrigger"
    />

    <taste-ingredient-frame
    v-for="(keyIngredient, index) in keyIngredients"
    :key = "keyIngredient.title"
    :ingredient="keyIngredient.title"
    :direction="directions[index]"
    />

    <!-- Austauschen gegen das Span 3 Element, wenn eine ungerade Anzahl an Mains existiert -->
    <taste-ingredient-overview-frame
    v-if ="keyIngredients.length === 2 || keyIngredients.length === 4"
    class="tasteOverview"
    :ingredients="taste.ingredients"
    />

    <taste-ingredient-overview-frame-wide
    v-if ="keyIngredients.length === 3"
    class="tasteOverviewWide"
    :ingredients="taste.ingredients"
    />
  </div>
</template>

<script>
import TasteIngredientFrame from '@/components/TasteIngredientFrame.vue';
import TasteTextFrame from '@/components/TasteTextFrame.vue';
import TasteIngredientOverviewFrame from '@/components/TasteIngredientOverviewFrame.vue';
import TasteIngredientOverviewFrameWide from '@/components/TasteIngredientOverviewFrameWide.vue';

export default {
  components: {
    TasteIngredientFrame,
    TasteTextFrame,
    TasteIngredientOverviewFrame,
    TasteIngredientOverviewFrameWide,
  },
  created() {
    // // // console.log(this.taste);
  },
  props: {
    keyIngredients: Array,
    taste: Object,
  },
  methods: {
    handleTrigger(data) {
      this.$emit('toggleTrigger', data);
    },
    handleHoverTrigger(data) {
      this.$emit('hoverTrigger', data);
    },
  },
  data() {
    return {
      directions: [
        [1, 1],
        [-1, -1],
        [1, -1],
        [1, 1],
      ],
    };
  },
};
</script>

<style lang="stylus" scoped>
.fristScrollGroup
  display: grid
  grid-template-rows: (470/1080*100)vh (470/1080*100)vh
  grid-auto-columns: (470/1080*100)vh
  grid-gap: (2/1080*100)vh
  grid-auto-flow: column;

  .tasteOverview
    grid-column-start: 2
    grid-row-start: 2
    grid-column-end: span 2

  .tasteOverviewWide
    grid-column-start: 2
    grid-row-start: 2
    grid-column-end: span 2

@media (max-width: 750px)
  .fristScrollGroup
    display: grid
    grid-template-rows: (470/1080*100)vh (470/1080*100)vh
    grid-auto-columns: (470/1080*100)vh
    grid-gap: (2/1080*100)vh
    grid-auto-flow: column;

    .tasteOverview
      grid-column-start: 2
      grid-row-start: 2
      grid-column-end: inherit

    .tasteOverviewWide
      grid-column-start: 2
      grid-row-start: 1
      grid-row-end: 3
      grid-column-end: inherit

</style>
