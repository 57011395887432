<template lang="html">
  <div class="formElement">
    <ui-header-help
    title="Nationalities"
    infoTitle="Adding nationalities"
    infoText="To define your dishes origin, you can add one or more nationalities. To add a nationality, use the input field and confirm your entries using the Add Nationality button on the right. A list of the nationalities you have added appears beneath it."/>
    <p class="errorIn">{{checkError}}</p>
    <div class="inputWrapper">
      <ui-input-search
      class="natioanlityInput"
      :error="errorInput"
      @onError="handleError"
      defaultText="Add one or more nationalities"
      @inputEnter="handleInput"
      :dataBaseItems="countryNames"
      type="nationality"/>
      <ui-button class="addButton" label="Add nationality" mode="cta" icon="add" @buttonClick="addNationality"/>
    </div>
    <!-- <p class="errorMessage">{{error}}</p> -->
    <div class="contentWrapper">
      <transition-group name="enterContent">
        <ui-content-label
        v-for="(nationality,index) in nationalities"
        class="label"
        :key= "nationality"
        :label="nationality"
        @removeItem="removeNationality(index)"/>
      </transition-group>
    </div>
    <!-- <p>{{nationalities}}</p> -->
  </div>
</template>

<script>
import UiButton from '@/components/UiButton.vue';
import UiInputSearch from '@/components/UiInputSearch.vue';
import UiContentLabel from '@/components/UiContentLabel.vue';
import UiHeaderHelp from '@/components/UiHeaderHelp.vue';

import countryNames from '../assets/data/countryNames';

export default {
  components: {
    UiButton,
    UiInputSearch,
    UiContentLabel,
    UiHeaderHelp,
  },
  mounted() {
    this.nationalities = [...this.nationalityIn];
  },
  computed: {
    checkError() {
      if (this.error) return this.error;
      if (this.errorMessage) return this.errorMessage;
      return '';
    },
  },
  props: {
    nationalityIn: Array,
    errorMessage: String,
  },
  methods: {
    handleError(error) {
      this.error = error;
      this.errorInput = true;
    },
    addNationality() {
      // let duplicateKey = false;
      for (let i = 0; i < this.nationalities.length; i += 1) {
        if (this.nationalities[i] === this.value) {
          // duplicateKey = true;
          this.errorInput = true;
          this.error = `You already added ${this.value}`;
          this.problemInput = this.value;
        }
      }
      // // console.log(duplicateKey);

      if (!this.errorInput) {
        this.nationalities.unshift(this.value);
      }
      // emite den Output nach draußen
      this.$emit('output', this.nationalities);
    },
    handleInput(value) {
      this.errorInput = false;
      this.error = '';
      this.value = value;
      // // console.log(value);
    },
    removeNationality(index) {
      // Wenn das problematische nationality rausgelöscht wird, lösche auch den Error
      if (this.nationalities[index] === this.problemInput) {
        this.error = '';
        this.errorInput = false;
        this.problemInput = '';
      }
      this.nationalities.splice(index, 1);
      this.$emit('output', this.nationalities);
    },
  },
  data() {
    return {
      countryNames,
      value: '',
      error: '',
      errorInput: false,
      problemInput: '',
      nationalities: [],
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.formElement
  color: $white
  height: 32vh
  position: relative;
  .errorIn
    color: $uiError
    position: absolute;
    top: 4rem
    font-family: 'PPPangramSansRounded'
    font-size: (16/16)rem
    font-weight: 600
    font-feature-settings: 'ss02' on;
  .inputWrapper
    display: flex
    justify-content: space-between;
    height: (48/16)rem
    .natioanlityInput
      width: 80%
      z-index: 1
    .button
      margin-left: 2vw
      flex-shrink: 0
  .error
    color: $uiError
    position: absolute;
    top: 4rem
  .errorMessage
    color: $uiError
    margin-left: (20/1920*100) vw
    margin-top: (12/16)rem
    font-family: 'PPPangramSansRounded'
    font-size: (16/16)rem
    font-weight: 600
    font-feature-settings: 'ss02' on;
  .contentWrapper
    z-index: 0
    margin-top: (36/16)rem
    position: inherit;
    max-height: 250px
    -webkit-mask-image: linear-gradient(180deg, $deepBlack 70%, transparent);
    overflow: scroll;

    .label
      margin-bottom: (14/16)rem
      width: 100%

.enterContent-enter-active,
.enterContent-leave-active
  transition: opacity 250ms ease-out, transform 250ms ease-out

.enterContent-leave-active
  position: absolute;

.enterContent-enter-from,
.enterContent-leave-to
  opacity: 0
  transform: translateY(30%)

.enterContent-move
  transition: transform 250ms ease

.enterContent-enter-to,
.enterContent-leave-from
  opacity: 1
  transform: translateY(0%)

@media (max-width: 750px)
  .formElement
    color: $white
    height: auto
    margin-bottom: 3rem
    .errorIn
      color: $uiError
      position: relative;
      top: 0
      margin-bottom: 1rem
      font-family: 'PPPangramSansRounded'
      font-size: (16/16)rem
      font-weight: 600
      font-feature-settings: 'ss02' on;
    .inputWrapper
      display: block
      height: auto
      .natioanlityInput
        width: 100%
        z-index: 1
      .button
        margin-left: 0
        margin-top: (12/16)rem
        width: 100%
    .errorMessage
      color: $uiError
      font-family: 'PPPangramSansRounded'
      font-size: (14/16)rem
      font-weight: 600
      font-feature-settings: 'ss02' on;
      margin-top: (10/16)rem
    .contentWrapper
      z-index: 0
      margin-top: 1rem
      max-height: inherit;
      -webkit-mask-image: inherit;
      overflow: inherit;

      .label
        margin-bottom: (10/16)rem
        width: 100%

</style>
