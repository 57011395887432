<template lang="html">
  <div class="formElement">
    <ui-header-help
    title="Title of dish"
    infoTitle="Add a title to your dish"
    infoText="Next to the photo the title is important to summarize the main information of a dish. A good way to start is to think about the key ingredients and how they are processed. You can also make use of adjectives to emphasize certain aromas or textures."/>
    <p v-if="errorMessage" class="error">{{errorMessage}}</p>
    <ui-input class="inputDefault" defaultText="Add a title to your dish" :inputText="titleInput" @inputEnter="handleInput"/>
    <!-- <p>{{title}}</p> -->
  </div>
</template>

<script>
import UiHeaderHelp from '@/components/UiHeaderHelp.vue';
import UiInput from '@/components/UiInput.vue';

export default {
  components: {
    UiHeaderHelp,
    UiInput,
  },
  props: {
    titleInput: String,
    errorMessage: String,
  },
  methods: {
    handleInput(value) {
      this.title = value;
      this.$emit('output', this.title);
    },
  },
  data() {
    return {
      title: 'dawda',
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.formElement
  position: relative;
  color: $white
  height: 32vh
  .error
    color: $uiError
    position: absolute;
    top: 2rem
  p
    position: absolute;
    margin-top: 30px
@media(max-width:750px)
  .formElement
    position: relative;
    color: $white
    height: auto
    margin-bottom: 4rem
    p
      display: none

</style>
