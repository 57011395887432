import Store from '../../store';

export default function calculateWeight(ingredients) {
  let sumOfweights = 0;
  ingredients.forEach((ingredient) => {
    sumOfweights += Store.state.ingredients.get(ingredient.title).weight;
  });
  const weight = Math.round(sumOfweights / ingredients.length);
  if (weight === 3) return 'average';
  if (weight > 3) return 'high';
  if (weight < 3) return 'low';
  return '-/-';
}
