export default function createTasteText(texturesIn, triggerIn) {
  // Erster Teil schauen ob es einen Trigger gibt und diesen nennen
  let firstSentence = '';
  // wenn es einen einzelnen Trigger gibt
  // console.log(triggerIn);
  if (triggerIn.length > 0 && triggerIn.length <= 1) {
    if (triggerIn[0].title === 'airyTender') {
      firstSentence = `Considering the mouthfeel, you can perceive a <span class="trigger"> → contrast </span> between <strong>${'airy'}</strong> and <strong>${'tender'}</strong>.`;
    }
    if (triggerIn[0].title === 'creamyCrunchy') {
      firstSentence = `Considering the mouthfeel, you can perceive a <span class="trigger"> → contrast </span> between <strong>${'creamy'}</strong> and <strong>${'crunchy'}</strong>.`;
    }
    if (triggerIn[0].title === 'crumblyLiquid') {
      firstSentence = `Considering the mouthfeel, you can perceive a <span class="trigger"> → contrast </span> between <strong>${'crumbly'}</strong> and <strong>${'liquid'}</strong>.`;
    }
    if (triggerIn[0].title === 'crispTender') {
      firstSentence = `Considering the mouthfeel, you can perceive a <span class="trigger"> → contrast </span> between <strong>${'tender'}</strong> and <strong>${'crisp'}</strong>.`;
    }
    if (triggerIn[0].title === 'coldWarm') {
      firstSentence = `Considering the mouthfeel, you can perceive a <span class="trigger"> → contrast </span> between <strong>${'warm'}</strong> and <strong>${'cold'}</strong>.`;
    }
  } else if (triggerIn.length > 1) {
    // Gibt es mehrere Trigger? Dann nenne keine sondern verweise
    firstSentence = 'Considering the mouthfeel, you can perceive a variety of different<span class="trigger"> → contrasts </span>.';
  }
  // console.log(firstSentence);
  // Alle Haupttexturen nennen.
  let secondSentence = '';
  // Erstelle ein Array aus allen Hauptingredients:
  const mainTextures = [];
  texturesIn.forEach((texture) => {
    if (texture.main) { mainTextures.push(texture.title); }
  });
  // console.log(mainTextures);
  if (mainTextures.length <= 1) {
    secondSentence = `The mouthfeel is above all ${mainTextures[0]}.`;
  } else {
    const lastIngredient = mainTextures.splice(-1);
    secondSentence = `The mouthfeel is above all ${mainTextures.join(', ')} and ${lastIngredient}.`;
  }

  return `${firstSentence} ${secondSentence}`;
}
