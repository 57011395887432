<template lang="html">
  <div class="colorWrapper">
    <div :class="{active: activeTaste === 'sweet'}" class="color sweet" @click="activeTaste = 'sweet'; $emit('setTileColor', '#FF546D')"></div>
    <div :class="{active: activeTaste === 'sour'}" class="color sour" @click="activeTaste = 'sour'; $emit('setTileColor', '#DEF25C')"></div>
    <div :class="{active: activeTaste === 'salty'}" class="color salty" @click="activeTaste = 'salty'; $emit('setTileColor', '#CEDEED')"></div>
    <div :class="{active: activeTaste === 'umami'}" class="color umami" @click="activeTaste = 'umami'; $emit('setTileColor', '#733F4B')"></div>
    <div :class="{active: activeTaste === 'fatty'}" class="color fatty" @click="activeTaste = 'fatty'; $emit('setTileColor', '#E5DFB8')"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.activeTaste = this.colorIn;
    // // console.log(this.activeTaste);
  },
  data() {
    return {
      activeTaste: 'sweet',
    };
  },
  props: {
    colorIn: String,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.colorWrapper
  display: flex
  justify-content: space-between;
  width: 98.5%
  margin: 0 auto
  .color
    width: 16%
    height: (40/16)rem
    border-radius: 5px
    transition: all 250ms ease-out
    position: relative;
    opacity: 0.8

    &:after
      transition: all 150ms ease-out
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 12px;
      opacity: 0
      border: 2px solid $white;

    &.active
      opacity: 1
      &:after
        content: '';
        display: block;
        position: absolute;
        top: (-9/16)rem;
        bottom: (-9/16)rem;
        left: (-8.5/16)rem;
        right: (-8.5/16)rem;
        border-radius: 9px;
        border: 2px solid $white;
        opacity: 1

    &.sweet
      background-color: $sweetRed
    &.umami
      background-color: $umamiPurple
    &.salty
      background-color: $saltyBlue
    &.fatty
      background-color: $fattyYellow
    &.sour
      background-color: $sourGreen
    &:hover
      cursor: pointer
      opacity: 1

@media(max-width: 750px)
  .colorWrapper
    display: flex
    justify-content: space-between;
    width: 98.5%
    margin: 0 auto
    .color
      width: 16%
      height: (30/16)rem

      &.active
        opacity: 1
        &:after
          content: '';
          display: block;
          position: absolute;
          top: (-8/16)rem;
          bottom: (-8/16)rem;
          left: (-7/16)rem;
          right: (-7/16)rem;
          border-radius: 9px;
          border: 1px solid $white;
          opacity: 1
</style>
