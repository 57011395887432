<template lang="html">
  <div class="inputSearchForm">
    <div class="inputWrapper" :class="{focussed:focussed, error:error}" @click="focusInput">
        <Ui-Icons v-if="icon" :icon="icon"/>
        <input
        class="inputField"
        type="text"
        :placeholder="defaultText"
        v-model="input"
        @focus="focussed = true"
        @blur="focussed = false"
        @input="handleInput"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.enter="onEnter"
        ref="inputField"
        >
        <Ui-icons class="addContent" :class="{active: input}" v-if="addContentButton" icon="add" @click="handleInput"/>
    </div>
    <transition name="openDropdown">
      <ul v-show="isOpen && dataBaseItems.length > 0 " class="autocompleteResults">
        <li
        class="autocompleteResult"
        v-for="(result, index) in results"
        :class="{ 'isActive': index === arrowCounter }"
        :key="index"
        @click ="setResult(result)"
        @mouseover = " arrowCounter = -1"
        >
        {{result}}
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import UiIcons from '@/components/UiIcons.vue';

export default {
  components: {
    UiIcons,
  },
  watch: {
    arrowCounter(newValue) {
      const elements = this.$el.querySelectorAll('.autocompleteResults > .autocompleteResult');
      if (elements[newValue]) {
        elements[newValue].scrollIntoView({ block: 'center' });
      }
    },
  },
  mounted() {
    if (this.dataBaseItems.length > 0) {
      document.addEventListener('click', this.handleClickOutside);
    }
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  props: {
    defaultText: String,
    icon: String,
    addContentButton: Boolean,
    error: Boolean,
    type: String,
    dataBaseItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      focussed: false,
      results: [],
      isOpen: false,
      input: '',
      arrowCounter: -1,
    };
  },
  methods: {
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
    onArrowDown(event) {
      event.preventDefault();
      // // console.log('down');
      if (this.arrowCounter < this.results.length - 1) {
        this.arrowCounter += 1;
        // this.input = this.results[this.arrowCounter];
      } else {
        this.arrowCounter = this.results.length - 1;
      }
      // // // console.log(this.arrowCounter);
    },
    onArrowUp() {
      // // console.log('up');
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        // this.input = this.results[this.arrowCounter];
      }
    },
    onEnter() {
      if (this.results.length > 0) {
        if (this.arrowCounter === -1) {
          this.arrowCounter = 0;
        }
        this.input = this.results[this.arrowCounter];
        this.arrowCounter = -1;
        this.isOpen = false;
        if (this.input) this.$emit('inputEnter', this.input.toLowerCase());
      } else {
        this.$emit('onError', `Unfortunately this ${this.type} doesnt exists`);
      }
    },
    setResult(result) {
      this.input = result;
      if (this.input) this.$emit('inputEnter', this.input.toLowerCase());
      this.isOpen = false;
    },
    filterResults() {
      this.results = this.dataBaseItems.filter((dataBaseItem) => dataBaseItem.toLowerCase().indexOf(this.input.toLowerCase()) > -1);
    },
    handleInput() {
      this.filterResults();
      // // // console.log(this.results.length);
      if (this.results.length > 0) this.isOpen = true;
      else this.isOpen = false;
      // Es wurden keine Elemente im Array mit diesem Input gefunden
      if (this.results.length === 0) {
        this.$emit('onError', `Unfortunately this ${this.type} doesnt exists`);
      } else if (this.results.length > 0) {
        this.$emit('inputEnter', this.input.toLowerCase());
      }
    },
    focusInput() {
      this.$refs.inputField.focus();
      this.focussed = true;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.inputSearchForm
  position: relative;
  .autocompleteResults
    position: absolute;
    width: 100%
    padding:0;
    margin: 0;
    border: 1px solid $white;
    border-radius: 5px
    min-height: 1em;
    max-height: 15em;
    margin-top: (10/16)rem
    overflow: auto;
    background-color: $black

    .autocompleteResult
      list-style: none;
      text-align: left;
      padding: 8px 0;
      cursor: pointer;
      text-indent:  (10/16)rem
      text-transform: capitalize;
      background-color: $focusBlack
      margin-bottom: (3/16)rem
      font-family: 'PPPangramSansRounded'
      font-size: (16/16)rem
      font-weight: 500
      font-feature-settings: 'ss02' on;

      &.isActive
        background-color: $black;
        color: white;

      &:hover
        background-color: $black;
        color: white;

  .inputWrapper
    width: 100%
    display: flex
    align-items: center
    justify-content: space-space-between;
    border: (1/16)rem solid $white
    border-radius: (5/16)rem
    padding: (7/1080*100)vh (10/1080*100)vh  (7/1080*100)vh  (10/1080*100)vh
    transition: background-color 250ms ease-out
    color: $white
    margin-right: $buttonSpacingDesktop
    height: (48/16)rem

    &.error
      border-color: $uiError
      .inputField
        color: $uiError

    &.focussed
      background-color: $focusBlack
    &:hover
      background-color: $focusBlack
      cursor: pointer

    .icon
      flex-shrink: 0

    .addContent
      margin-right: 0
      color: $gray

      &.active
        color: $white

        &:hover
          color: $uiPurple
          cursor: pointer

    .inputField
      -webkit-user-select:text;
      display: inline-block;
      color: $white
      font-family: 'PPPangramSansRounded'
      font-size: (16/16)rem
      font-weight: 500
      font-feature-settings: 'ss02' on;
      background-color: inherit;
      border: none
      width: 100%
      margin-left: (8/1920*100) vw
      text-transform: capitalize;
      &::placeholder
        color: $gray
      &:focus
        outline: none
.openDropdown-enter-active,
.openDropdown-leave-active
  transition: opacity 200ms ease-out, transform 150ms ease-out

.openDropdown-enter-from,
.openDropdown-leave-to
  opacity: 0
  transform: scaleY(0.5) translateY(-60%);

.openDropdown-enter-to,
.openDropdown-leave-from
  opacity: 1
  transform: scaleY(1) translateY(0);
</style>
