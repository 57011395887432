<template lang="html">
  <div class="headerHelp">
    <div class="headerWrapper" ref="head">
      <h2>{{title}}</h2>
      <div class="iconButton" @click="visible = !visible" :class="{active: visible}">
        <ui-icons icon="info"/>
      </div>
      <ui-tool-tipp :style="{left: `${positionLeft + 50}px`}" :title="infoTitle" :visible="visible" :text="infoText" @close="visible = false"/>
    </div>
  </div>
</template>

<script>
import UiIcons from '@/components/UiIcons.vue';
import UiToolTipp from '@/components/UiToolTipp.vue';

export default {
  mounted() {
    const element = this.$refs.head;
    // // // console.log(element, this.positionLeft = element.getBoundingClientRect());
    this.positionLeft = element.getBoundingClientRect().width;
    // // // console.log(this.positionLeft);
  },
  components: {
    UiIcons,
    UiToolTipp,
  },
  props: {
    title: String,
    infoTitle: String,
    infoText: String,
  },
  data() {
    return {
      visible: false,
      positionLeft: 0,
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.headerWrapper
  position: relative;
  display: inline-flex
  align-items: center
  margin-bottom: (65/16)rem
  color: $white
  .iconButton
    height: (35/16)rem
    width: @height
    display: flex
    align-items: center
    justify-content: center
    background-color: $deepBlack
    border-radius: 100px
    margin-left: (15/16rem)
    transition: all 150ms ease-out
    &.active
      background-color: $uiPurple
      &:hover
        background-color: $uiPurpleHover
    &:hover
      background-color: $focusBlack
      cursor: pointer
  h2
    line-height: 1
  .toolTipp
    position: absolute
    z-index: 3
    right: 0
    top: -(10/16)rem

@media (max-width: 750px)
  .headerWrapper
    margin-bottom: (30/16)rem
    .iconButton
      display: none
</style>
