function nextBiggest(arr) {
  let max = -Infinity; let result = -Infinity;
  arr.forEach((nr) => {
    if (nr > max) {
      [result, max] = [max, nr]; // save previous max
    } else if (nr < max && nr > result) {
      result = nr; // new second biggest
    }
  });
  return result;
}
export default function createTasteText(ingredientsIn, tastesIn, trigger) {
  const tastesToKeep = Object.keys(tastesIn).filter((key) => {
    let keep = false;
    if (tastesIn[key] !== 0) keep = true;
    return keep;
  });

  const tastes = {};
  tastesToKeep.forEach((key) => {
    tastes[key] = tastesIn[key];
  });

  // Erstelle ein Array aus den Werten des Objekts
  const arr = Object.values(tastes);
  const secondMax = nextBiggest(arr);
  const max = Math.max(...arr);
  const min = Math.min(...arr);
  // Existiert ein Trigger?
  // Text für Harmonie: --> Einbauen des Knopfes
  // console.log(trigger);
  let firstSentence = '';
  if (trigger[0].title === 'harmony') {
    // Fall volle Harmonie alle Geschmäcker sind gleich
    if (max === min) {
      firstSentence = 'An overall <span class="trigger">→ harmonious</span> dish without a tendency towards a specific taste.';
    } else {
      // Fall es gibt einen Ausschlag in eine Richtung
      // Finde die Geschmäcker mit dem Maximum und pack sie in ein Array
      const tasteMaxima = Object.keys(tastesIn).filter((key) => {
        let keepKey = false;
        if (tastesIn[key] === max) keepKey = true;
        return keepKey;
      });
      if (tasteMaxima.length <= 1) {
        firstSentence = `A <span class="trigger">→ harmonious</span> dish with a slight tendency towards ${tasteMaxima[0]}.`;
      } else {
        const lastTaste = tasteMaxima.splice(-1);
        firstSentence = `A <span class="trigger">→ harmonious</span> dish with a slight tendency towards ${tasteMaxima.join(', ')} and ${lastTaste}.`;
      }
    }
  } else if (trigger[0].title && trigger[0].title !== 'harmony') {
    // Text für Peak: --> Einbauen des Knopfes
    // Finde die zweiintensivsten Geschmäcker im Profil
    const tasteSecondMax = Object.keys(tastesIn).filter((key) => {
      let keepKey = false;
      if (tastesIn[key] === secondMax) keepKey = true;
      return keepKey;
    });
    // Es gibt nur einen anderen Geschmack
    if (tasteSecondMax.length <= 1) {
      firstSentence = `A very <span class="trigger">→ ${trigger[0].title}</span> dish with a slight tendency towards ${tasteSecondMax[0]}.`;
    } else {
      const lastTaste = tasteSecondMax.splice(-1);
      firstSentence = `A very <span class="trigger">→ ${trigger[0].title}</span> dish with a slight tendency towards ${tasteSecondMax.join(', ')} and ${lastTaste}.`;
    }
  } else if (!trigger[0].title && trigger[0].title !== 'harmony') {
    // Find die Maxima im Geschmack
    const tasteMaxima = Object.keys(tastesIn).filter((key) => {
      let keepKey = false;
      if (tastesIn[key] === max) keepKey = true;
      return keepKey;
    });
    // Ein vielseitiges Gericht das besonders ... ist
    if (tasteMaxima.length <= 1) {
      firstSentence = `A versatile dish that is above all ${tasteMaxima[0]}.`;
    } else {
      const lastTaste = tasteMaxima.splice(-1);
      firstSentence = `A versatile dish that is above all ${tasteMaxima.join(', ')} and ${lastTaste}.`;
    }
  }
  // Welche Hauptingredients sind enthalten?
  // Harmonious taste with a slight tendency towards sour and fatty.
  // console.log(firstSentence);
  // The aroma is determined by the ingredients, asparagus, butter, egg and tarragon.
  let secondSentence = '';
  // Erstelle ein Array aus allen Hauptingredients:
  const mainIngredients = [];
  ingredientsIn.forEach((ingredient) => {
    if (ingredient.main) { mainIngredients.push(ingredient.title); }
  });
  // console.log(mainIngredients);
  if (mainIngredients.length <= 1) {
    secondSentence = `The aroma is mainly determined by the dishes main ingredient ${mainIngredients[0]}.`;
  } else {
    const lastIngredient = mainIngredients.splice(-1);
    secondSentence = `The aroma is determined by the main ingredients ${mainIngredients.join(', ')} and ${lastIngredient}.`;
  }
  // console.log(secondSentence);

  return `${firstSentence} ${secondSentence}`;
}
