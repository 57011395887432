<template>
  <div class="home">
    <div class="headWrapper">
      <h3>{{content.wiki.title}}</h3>
      <div class="leftWrapper">
        <Ui-Input icon="search" defaultText="Enter Text" @inputEnter="filter=$event"/>
      </div>
      <div class="rightWrapper">
        <Ui-Button @buttonClick="navigateHome" :label="buttonType === 'dark' ? 'Get Back': ''" :mode="buttonType" icon="back"/>
        <Ui-Button @buttonClick="handleModal('help')" :label="buttonType === 'dark' ? 'Get Help': ''" :mode="buttonType" icon="help"/>
        <Ui-Button @buttonClick="handleModal('about')" :label="buttonType === 'dark' ? 'About': ''" :mode="buttonType" icon="info"/>
        <ui-modal @close="visibleModal=false" :visible="visibleModal" :title="modalTitle" confirm="Continue" :text="modalText"/>
      </div>
    </div>
    <ui-button class="toTop" v-if="scrolled" mode="noLabel" icon="top" @buttonClick="scrollTop"/>
    <div class="contentScrollPage">
      <div class="content">
        <!-- Hier kommt der Content hin -->
        <h3>Ingredient Elements</h3>
        <div class="elementList">
          <ui-ingredient-tile
          v-for="(element,index) in filteredIngredients"
          :ingredient-id="element"
          :key="index"/>
        </div>
        <h3>Texture Elements</h3>
        <div class="elementList">
          <ui-texture-tile
          v-for="(element,index) in textures"
          :texture-id="element"
          :key="index"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import UiButton from '@/components/UiButton.vue';
import UiInput from '@/components/UiInput.vue';
import UiIngredientTile from '@/components/UiIngredientTile.vue';
import UiTextureTile from '@/components/UiTextureTile.vue';
import UiModal from '@/components/UiModal.vue';

import content from '@/assets/data/content';

export default {
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      window.addEventListener('scroll', this.onScroll);
    });
    this.windowWidth = window.innerWidth;
    this.ingredients = Array.from(this.$store.state.ingredients.keys());
    this.textures = Array.from(this.$store.state.textures.keys());
  },
  name: 'Home',
  components: {
    UiButton,
    UiInput,
    UiIngredientTile,
    UiTextureTile,
    UiModal,
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.onScroll);
  },
  computed: {
    filteredIngredients() {
      if (!this.filter.trim()) {
        return this.ingredients;
      }
      return this.ingredients.filter((ingredient) => ingredient.toLowerCase().indexOf(this.filter.toLowerCase()) > -1);
    },
  },
  watch: {
    windowWidth(newValue) {
      if (newValue < 1350) {
        this.buttonType = 'noLabel';
      } else {
        this.buttonType = 'dark';
      }
    },
  },
  methods: {
    onScroll() {
      // // console.log(window.pageYOffset);
      if (window.pageYOffset > 1500) this.scrolled = true;
      if (window.pageYOffset === 0) this.scrolled = false;
    },
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    navigateHome() {
      if (this.$route.name === 'Home') return;
      this.$router.push({ name: 'Home' });
    },
    handleModal(type) {
      this.visibleModal = true;
      if (type === 'about') {
        this.modalText = this.content.home.about.info;
        this.modalTitle = this.content.home.about.infoTitle;
      }
      if (type === 'help') {
        this.modalText = this.content.wiki.info;
        this.modalTitle = this.content.wiki.infoTitle;
      }
    },
    onResize() {
      // // console.log(window.innerWidth);
      this.$store.commit('setWindowWidth', window.innerWidth);
      this.windowWidth = window.innerWidth;
      // // console.log(this.$store.state.windowWidth);
    },
    navigateDev() {
      if (this.$route.name === 'Development') return;
      this.$router.push({ name: 'Development' });
    },
  },
  data() {
    return {
      scrolled: true,
      filter: '',
      modalText: '',
      modalTitle: '',
      visibleModal: false,
      content,
      windowWidth: 0,
      buttonType: 'dark',
      ingredients: [],
      textures: [],
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.home
  .headWrapper
    position: fixed
    top: (40/16) rem
    display: grid
    grid-template-columns: 1fr 1fr
    grid-template-rows: auto
    width: 90%

    h3
      color: $white
      margin-bottom: (36/16)rem
    .leftWrapper
      grid-row: 2
      display: flex
      .inputWrapper
        max-width: (700/16)rem
        width: 28vw
    .rightWrapper
      grid-column: 2
      grid-row: 1 /span 2
      display: flex
      align-self: end
      justify-self: end
      .button
        margin-left:$buttonSpacingDesktop
  .toTop
    background-color: $uiPurple
    margin-left: (10/16)rem
    box-shadow: 1px 6px 6px rgba(0, 0, 0, 0.15);
    position: fixed
    right: 2%
    bottom: 1%
    z-index: 3
    &:hover
      background-color: $uiPurpleHover

  .contentScrollPage
    width:100vw
    background-color: $white
    border-radius: (18/16)rem
    position: absolute;
    left: 0
    top: (170/900*100)vh
    height:auto
    .content
      padding-top: 3%
      margin: 0 auto
      width: 90%
      .elementList
        margin-top: (45/16)rem
        display: flex
        flex-wrap: wrap
        justify-content: space-between;
        &::after
          content: "";
          flex-basis: 32.3%;
        .tileWrapper
          flex-grow: 0

@media (max-width:750px)
  .home
    .headWrapper
      position: fixed
      top: (40/16) rem
      display: grid
      grid-template-columns: 1fr 1fr
      grid-template-rows: (50/16)rem
      width: 92%
      transform: translateZ(-2001px);

      h3
        color: $white

      .leftWrapper
        grid-row: 2
        grid-column: 1 / span 2
        display: flex
        flex-wrap: wrap;
        .inputWrapper
          width: 100%
          margin-right: 0
        .button
          width: 100%
          margin-top: (16/16)rem
      .rightWrapper
        grid-column: 2
        grid-row: 1
        display: flex
        align-self: start
        justify-self: end
        margin-top: (-10/16)rem
        .button
          margin-left:$buttonSpacingDesktop

    .contentScrollPage
      top: (170/16)rem
      border-radius: 14px
      transform: translateZ(-2000px);
      .content
        padding-top: 3%
        width: 96%
        h3
          font-size: 1rem
          margin-top: 0.8rem

</style>
