<template lang="html">
  <div class="secondScrollGroup">
    <taste-graph-frame :tasteProfile="tasteProfile"/>

    <div class="aromaContentWrapper">
      <taste-aroma-frame :aromaProfile="aroma.aromaProfile"/>

      <taste-text-frame
      class="aromaTextFrame"
      title = "02 → Aroma Profile"
      subTitle = "Description of Aroma"
      :text="aroma.text"
      />
    </div>
  </div>
</template>

<script>
import TasteAromaFrame from '@/components/TasteAromaFrame.vue';
import TasteGraphFrame from '@/components/TasteGraphFrame.vue';
import TasteTextFrame from '@/components/TasteTextFrame.vue';

export default {
  components: {
    TasteAromaFrame,
    TasteGraphFrame,
    TasteTextFrame,
  },
  props: {
    tasteProfile: Array,
    aroma: Object,
  },
};
</script>

<style lang="stylus" scoped>
.secondScrollGroup
  display: flex
  .aromaContentWrapper
    margin-left: (2/1090*100)vh
    .aromaTextFrame
      margin-top: (2/1090*100)vh
</style>
