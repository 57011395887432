<template lang="html">
  <div class="aromaFrame" :class="{dark}">
    <div class="aromaWrapper" :class="{mainAroma:mainAroma}">
      <div class="aromaBar" :class="[aroma, {active: intensity > 9}]"></div>
      <div class="aromaBar" :class="[aroma, {active: intensity > 8}]"></div>
      <div class="aromaBar" :class="[aroma, {active: intensity > 7}]"></div>
      <div class="aromaBar" :class="[aroma, {active: intensity > 6}]"></div>
      <div class="aromaBar" :class="[aroma, {active: intensity > 5}]"></div>
      <div class="aromaBar" :class="[aroma, {active: intensity > 4}]"></div>
      <div class="aromaBar" :class="[aroma, {active: intensity > 3}]"></div>
      <div class="aromaBar" :class="[aroma, {active: intensity > 2}]"></div>
      <div class="aromaBar" :class="[aroma, {active: intensity > 1}]"></div>
      <div class="aromaBar" :class="[aroma, {active: intensity > 0}]"></div>
    </div>
    <p class="marignals">{{aroma}}</p>
  </div>
</template>

<script>
export default {
  props: {
    intensity: Number,
    aroma: String,
    dark: Boolean,
    mainAroma: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.aromaFrame
  display: flex;
  flex-direction: column;
  width: 100%
  height: (160/1080*100)vh

  &.dark
    color: $white

    .aromaWrapper
      outline-color: $white

      &.mainAroma
        .aromaBar.active

          &.fruity,&.floral,&.spicy
            background-color: $sweetRed
          &.herbal, &.meaty, &.roasted, &.woody, &.earthy
            background-color: $umamiPurple
          &.nutty, &.cheesy, &.sulphuric
            background-color: $fattyYellow
          &.brine, &.maritime
            background-color: $saltyBlue
          &.citrus, &.green, &.mustard
            background-color: $sourGreen

      .aromaBar
        background-color: $black
        outline: 1px solid $white
        &.active
          background-color: $uiPurple

  .marignals
    text-align: center
    font-size: $marginal
    font-weight: 500
    margin-top: (14/1080*100)vh
    text-transform: capitalize;
    font-feature-settings: 'ss02' on;

  .aromaWrapper
    outline: 2px solid $black
    width: 100%
    height: 100%
    display: flex;
    flex-direction: column;

    &.mainAroma
      .aromaBar.active

        &.fruity,&.floral,&.spicy
          background-color: $sweetRed
        &.herbal, &.meaty, &.roasted, &.woody, &.earthy
          background-color: $umamiPurple
        &.nutty, &.cheesy, &.sulphuric
          background-color: $fattyYellow
        &.brine, &.maritime
          background-color: $saltyBlue
        &.citrus, &.green, &.mustard
          background-color: $sourGreen

    .aromaBar
      height: 100%
      width: 100%
      background-color: $white
      outline: (1/1080*100)vh solid $black
      &.active
        background-color: $gray

@media (max-width:750px)
  .aromaFrame
    display: flex;
    flex-direction: column;
    width: 100%
    height: (160/1080*100)vh
    .marignals
      font-size: (12/16)rem

</style>
