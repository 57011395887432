<template lang="html">
  <div class="thirdScrollGroup">
    <taste-text-frame
    class="tasteTextFrame"
    title = "03 → Texture &amp; Consistency"
    subTitle = "Description of Texture"
    :text="texture.text"
    @toggleTrigger="handleTrigger"
    @hoverTrigger="handleHoverTrigger"
    />

    <taste-texture-frame
    v-for="(keyTexture, index) in keyTextures"
    :key = "keyTexture.title"
    :texture="keyTexture.title"
    :taste="keyTexture.taste"
    :direction ="directions[index]"
    />
    <!-- Austauschen gegen das Span 3 Element, wenn eine ungerade Anzahl an Mains existiert -->
    <taste-texture-overview-frame
    class="textureOverview"
    v-if ="keyTextures.length === 2 || keyTextures.length === 4"
    :textures="texture.textures"
    :keyTextures = "keyTextures"
    />
    <taste-texture-overview-frame-wide
    class="textureOverviewWide"
    v-if ="keyTextures.length === 3"
    :textures="texture.textures"
    :keyTextures = "keyTextures"
    />
  </div>
</template>

<script>
import TasteTextureFrame from '@/components/TasteTextureFrame.vue';
import TasteTextFrame from '@/components/TasteTextFrame.vue';
import TasteTextureOverviewFrame from '@/components/TasteTextureOverviewFrame.vue';
import TasteTextureOverviewFrameWide from '@/components/TasteTextureOverviewFrameWide.vue';

export default {
  components: {
    TasteTextureFrame,
    TasteTextFrame,
    TasteTextureOverviewFrame,
    TasteTextureOverviewFrameWide,
  },
  props: {
    texture: Object,
    keyTextures: Array,
  },
  methods: {
    handleTrigger(data) {
      this.$emit('toggleTrigger', data);
    },
    handleHoverTrigger(data) {
      this.$emit('hoverTrigger', data);
    },
  },
  data() {
    return {
      directions: [
        [1, 1],
        [-1, -1],
        [-1, -1],
        [-1, 1],
      ],
    };
  },
};
</script>

<style lang="stylus" scoped>
.thirdScrollGroup
  display: grid
  grid-template-rows: (470/1080*100)vh (470/1080*100)vh
  grid-auto-columns: (470/1080*100)vh
  grid-gap: (2/1080*100)vh
  grid-auto-flow: column;

  .textureOverview
    grid-column-start: 2
    grid-row-start: 2
    grid-column-end: span 2

  .textureOverviewWide
    grid-column-start: 2
    grid-row-start: 2
    grid-column-end: span 2

@media (max-width: 750px)
  .thirdScrollGroup
    display: grid
    grid-template-rows: (470/1080*100)vh (470/1080*100)vh
    grid-auto-columns: (470/1080*100)vh
    grid-gap: (2/1080*100)vh
    grid-auto-flow: column;

    .textureOverview
      grid-column-start: 2
      grid-row-start: 2
      grid-column-end: inherit

    .textureOverviewWide
      grid-column-start: 2
      grid-row-start: 1
      grid-row-end: 3
      grid-column-end: inherit

</style>
