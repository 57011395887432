import Store from '../../store';

export default function calculateSeason(ingredients) {
  const seasons = [];
  // Lade alle Seasons der ausgewählen Ingredients
  ingredients.forEach((ingredient) => {
    seasons.push(Store.state.ingredients.get(ingredient.title).season);
  });
  // Finde die am häufigsten verwendete Saison des Gerichts
  const hashmap = seasons.reduce((acc, val) => {
    acc[val] = (acc[val] || 0) + 1;
    return acc;
  }, {});
  // Lösche alle Einträge mit Season all aus dem Objekt, wenn es mehr als einen Eintrag gibt
  if (Object.keys(hashmap).length > 1) delete hashmap.all;
  // Suche den häufigsten Wert im Objekt und Schicke ihn hoch
  return Object.keys(hashmap).reduce((a, b) => (hashmap[a] > hashmap[b] ? a : b));
}
