<template lang="html">
  <div class="ingredientFrame"
    v-if="!mobile"
    @mouseleave="hoverTile(false)"
    >
    <TasteIngredientBack
    class="backTile"
    :ingredient-id= "ingredient"
    @buttonClick="moveTile"
    />
    <TasteKeyIngredientTile
    class="frontTile"
    :strokeWidth="10"
    :ingredient-id="ingredient"
    :style="{transform: `translate(${posX}vh, ${posY}vh)`, zIndex: `${zIndex}`}"
    @click="moveTile"
    @mouseenter="hoverTile(true)"
    />
  </div>
  <div class="textureFrame" v-else-if="mobile">
    <transition mode="out-in" name="flipIngredient">
      <TasteKeyIngredientTile
      class="frontTile"
      v-if="!active"
      key="front"
      @click="active = true"
      :strokeWidth="10"
      :ingredient-id="ingredient"
      />
      <TasteIngredientBack
      class="backTile"
      v-else-if="active"
      key="back"
      @click="active = false"
      :ingredient-id= "ingredient"
      />
    </transition>
  </div>
</template>

<script>
import TasteKeyIngredientTile from '@/components/TasteKeyIngredientTile.vue';
import TasteIngredientBack from '@/components/TasteIngredientBack.vue';

export default {
  components: {
    TasteKeyIngredientTile,
    TasteIngredientBack,
  },
  props: {
    ingredient: String,
    direction: Array,
  },
  data() {
    return {
      active: false,
      preventHover: false,
      posX: 0,
      posY: 0,
      zIndex: 0,
      mobile: false,
    };
  },
  mounted() {
    // // console.log(window.innerWidth);
    if (window.innerWidth < 750) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  },
  watch: {
    windowWidth(newValue) {
      if (newValue < 750) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    },
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  methods: {
    moveTile() {
      // // console.log('click');
      if (!this.active) {
        this.posX = ((430 / 1080) * 100) * this.direction[0];
        this.posY = ((-430 / 1080) * 100) * this.direction[1];
        this.active = true;
      } else {
        this.preventHover = true;
        this.posX = 0;
        this.posY = 0;
        this.active = false;
      }
    },
    hoverTile(hover) {
      if (this.active) {
        this.$store.commit('increaseHighestIndex');
        this.zIndex = this.$store.state.highestIndex;
        return;
      }
      if (hover === true && !this.preventHover) {
        if (window.innerWidth < 750) {
          this.$store.commit('increaseHighestIndex');
          this.zIndex = this.$store.state.highestIndex;
          return;
        }
        this.posX = ((50 / 1080) * 100) * this.direction[0];
        this.posY = ((-50 / 1080) * 100) * this.direction[1];
        this.$store.commit('increaseHighestIndex');
        this.zIndex = this.$store.state.highestIndex;
      } else if (hover === false) {
        this.preventHover = false;
        this.posX = 0;
        this.posY = 0;
        // // // console.log(this.zIndex);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.flipIngredient-enter-active,
.flipIngredient-leave-active
  -webkit-transform: translateZ(10000px)
  transition: transform 150ms ease-out

.flipIngredient-enter-from
  transform: rotateY(-90deg);

.flipIngredient-leave-to
  transform: rotateY(90deg);
.ingredientFrame
  display: grid
  .backTile
    grid-column: 1
    grid-row: 1

  .frontTile
    grid-column: 1
    grid-row: 1
    transition: transform 170ms ease-out, box-shadow 100ms ease-out

</style>
