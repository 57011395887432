<template lang="html">
  <div class="formElement">
    <ui-header-help
    :title="`Key textures (${activeTextures}/4)`"
    infoTitle="Add key textures"
    infoText="Key textures build the core of your dish’s mouthfeel and tell which consistencies you perceive first. You can highlight up to four of your previously added textures as key textures."/>
    <p v-if="!errorMessage" class="error">{{errorMessageLocal}}</p>
    <p v-if="errorMessage" class="error">{{errorMessage}}</p>
    <div class="buttonWrapper">
      <ui-button-center
      v-for="(texture,index) in keyTextures"
      :key="index"
      :label="texture.title"
      :activeButton="texture.main"
      :errorButton = "errorButton"
      @click="handleOutput(index)"
      />
    </div>
    <!-- <p>{{keyTextures}}</p> -->
  </div>
</template>

<script>
import UiHeaderHelp from '@/components/UiHeaderHelp.vue';
import UiButtonCenter from '@/components/UiButtonCenter.vue';

export default {
  mounted() {
    this.keyTextures = [...this.textures];
  },
  components: {
    UiHeaderHelp,
    UiButtonCenter,
  },
  props: {
    textures: Array,
    errorMessage: String,
  },
  methods: {
    handleOutput(index) {
      // // console.log(this.keyTextures[index].main);
      if (this.keyTextures[index].main) {
        this.keyTextures[index].main = false;
        this.errorMessageLocal = '';
        this.$emit('output', this.keyTextures);
        this.errorButton = false;
      } else if (!this.keyTextures[index].main && this.activeTextures < 3) {
        this.keyTextures[index].main = true;
        this.errorMessageLocal = '';
        this.$emit('output', this.keyTextures);
        this.errorButton = false;
      } else if (!this.keyTextures[index].main && this.activeTextures === 3) {
        this.keyTextures[index].main = true;
        this.errorMessageLocal = 'You reached the maximum amount of key textures';
        this.errorButton = true;
        this.$emit('output', this.keyTextures);
      }
      this.activeTextures = this.keyTextures.filter((element) => element.main).length;
    },
  },
  data() {
    return {
      errorMessageLocal: '',
      errorButton: false,
      keyTextures: [],
      activeTextures: 0,
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.formElement
  position: relative;
  color: $white
  .error
    position: absolute;
    top: 1.8rem
    color: $uiError
    font-family: 'PPPangramSansRounded'
    font-size: (16/16)rem
    font-weight: 600
    font-feature-settings: 'ss02' on;
  .buttonWrapper
    height: 63vh
    overflow: scroll
    -webkit-mask-image: linear-gradient(180deg, $deepBlack 70%, transparent);
    .buttonCenter
      margin-bottom: (15/16)rem
      text-align: left
      text-transform: capitalize;
  p
    position: absolute;
    margin-top: 30px

@media(max-width:750px)
  .formElement
    .error
      color: $uiError
      position: relative;
      top: 0
      margin-bottom: 1rem
      margin-top: 0
    .buttonWrapper
      justify-content: space-between;
      height:auto
      margin-bottom: 2rem
      overflow: inherit;
      -webkit-mask-image: inherit;
</style>
