<template lang="html">
  <div class="tileWrapper">
    <transition mode="out-in" name="flipTexture">
    <div class="front" v-if="!active" key="front" @click="active = true" :style="{backgroundImage: `url(${require(`@/assets/texture/square/${texture.title}.png`)})`}">
      <p>{{texture.title}}</p>
    </div>
    <div class="back" v-else-if="active" key="back" @click="active = false">
      <div class="headWrapper">
        <h2>{{texture.title}}</h2>
      </div>
      <div class="contentWrapper">
        <div class="elementFrame">
          <img :src="`${require(`@/assets/texture/square/${texture.title}.png`)}`" :alt="texture">
        </div>
        <div class="database">
          <p>{{`Cat.: ${texture.category}`}}</p>
          <p>{{`Bio.: ${texture.bio}`}}</p>
          <p>{{`Contrast: ${texture.contrast.join(', ')}`}}</p>
          <p>{{`Ingredients: ${texture.ingredients.join(', ')}`}}</p>
        </div>
      </div>
      <p>Related Ingredients:</p>
      <div class="ingredientWrapper">
        <div class="ingredientTextWrapper">
          <Taste-ingredient :width="4" :ingredient="texture.ingredients[0]" mode="outlined" strokeColor="#E5DFB8"/>
          <p>{{texture.ingredients[0]}}</p>
        </div>
        <div class="ingredientTextWrapper">
          <Taste-ingredient :width="4" :ingredient="texture.ingredients[1]" mode="outlined" strokeColor="#CEDEED"/>
          <p>{{texture.ingredients[1]}}</p>
        </div>
        <div class="ingredientTextWrapper">
          <Taste-ingredient :width="4" :ingredient="texture.ingredients[2]" mode="outlined" strokeColor="#FF546D"/>
          <p>{{texture.ingredients[2]}}</p>
        </div>
        <div class="ingredientTextWrapper">
          <Taste-ingredient :width="4" :ingredient="texture.ingredients[3]" mode="outlined" strokeColor="#E5DFB8"/>
          <p>{{texture.ingredients[3]}}</p>
        </div>
      </div>
    </div>
  </transition>
  </div>
</template>

<script>
import TasteIngredient from '@/components/TasteIngredient.vue';
// import TasteAromaGraph from '@/components/TasteAromaGraph.vue';

export default {
  data() {
    return {
      ingredientAromas: [],
      active: false,
    };
  },
  computed: {
    calcWeight() {
      if (this.ingredient.weight === 3) return 'average';
      if (this.ingredient.weight > 3) return 'high';
      if (this.ingredient.weight < 3) return 'low';
      return 'undefined';
    },
    calcIntensity() {
      if (this.ingredient.intensity === 3) return 'average';
      if (this.ingredient.intensity > 3) return 'heavy';
      if (this.ingredient.intensity < 3) return 'light';
      return 'undefined';
    },
    texture() {
      return this.$store.state.textures.get(this.textureId);
    },
  },
  components: {
    TasteIngredient,
    // TasteAromaGraph,
  },
  props: {
    textureId: String,
    strokeWidth: Number,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.tileWrapper
  margin-bottom: 2vw
  transform: translateZ(200px);
  .front
    background-color: $black
    display: flex
    flex-direction: column
    // height: (470/16) rem
    height: 29vw
    width: @height
    box-shadow: 0 0 0 2px $black;
    border-radius: 12px
    position: relative
    padding-top: (5/1920*135)vw
    cursor: pointer;
    background-size: cover

    &:hover
      box-shadow: 0 0 0 2px $uiPurple, 5px 5px 10px rgba(47, 35, 45, 0.15);

    p
      color: $white
      text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
      text-transform: capitalize;
      position: absolute;
      top: (12/1920*135)vw
      left (12/1920*135)vw
      font-family: 'PPPangramSansRounded'
      font-size: (24/1920*100)vw
      font-weight: 400
      font-feature-settings: 'ss02' on;
      text-transform: capitalize;

    .ingredient
      width: 100%
      height: 100%
  .back
    height: 29vw
    width: @height
    background-color: $black
    padding: (18.7/1920*135)vw
    border-radius: 12px
    color: $white
    text-align: left
    box-shadow: 0 0 0 (2/1920*135)vw $black;
    cursor: pointer;

    &:hover
      box-shadow: 0 0 0 (3/1920*135)vw $uiPurple, 5px 5px 10px rgba(47, 35, 45, 0.15);

    p
      font-size: (16/1920*135)vw
      font-weight: 500
      text-transform: capitalize;
      margin-bottom: (10/1920*135)vw
    .headWrapper
        display: flex
        flex-direction: row
        justify-content: space-between;
        color: $white
      h2
        display: block;
        flex-shrink: 0
        text-overflow: ellipsis
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden
        font-size: (30/1920*135)vw
    .ingredientWrapper
      margin-top: (20/1920*135)vw
      display: flex
      justify-content: space-between;
      width: 100%
      .ingredientTextWrapper
        overflow: hidden
        text-overflow: ellipsis;
        .ingredient
          height: auto
        p
          margin-top: 0.5rem
          font-size: (14/16)rem
          text-align: center

    .contentWrapper
      margin-top: (22/1920*135)vw
      margin-bottom: (20/1920*135)vw
      display: flex;

      .elementFrame
        width: (164/1920*135)vw
        height: @width
        background-image: url('../assets/p/wiki.png');
        border: (2/1920*135)vw solid
        img
          height: 100%

      .database
        text-align: left
        color: $white
        font-family: 'PPPangramSansRounded'
        font-feature-settings: 'ss02' on;
        margin-left: (30/1920*135)vw
        height: (164/1920*135)vw
        p
          white-space: wrap
          overflow: hidden
          text-overflow: ellipsis;
          font-size: (16/1920*135)vw
          letter-spacing: 0.02em;
          font-weight: 600
          margin-bottom: (10/1920*135)vw
.flipTexture-enter-active,
.flipTexture-leave-active
  -webkit-transform: translateZ(10000px)
  transition: transform 150ms ease-out

.flipTexture-enter-from
  transform: rotateY(-90deg);

.flipTexture-leave-to
  transform: rotateY(90deg);

@media (max-width:1400px)
  .tileWrapper
    .front
      width: 44vw
      height: @width

      p
        font-size: (40/1920*100)vw
        top: (12/1920*198)vw
        left (12/1920*198)vw

    .back
      width: 44vw
      height: @width
      background-color: $black
      padding: (18.7/1920*198)vw

      p
        font-size: (16/1920*198)vw
        margin-bottom: (20/1920*198)vw
      .headWrapper
        h2
          font-size: (30/1920*198)vw
      .ingredientWrapper
        margin-top: (10/1920*198)vw
        .graph
          height: (116/1920*198)vw
          width: (61/1920*198)vw

      .contentWrapper
        margin-top: (22/1920*198)vw
        margin-bottom: (14/1920*198)vw

        .elementFrame
          width: (164/1920*198)vw
          height: @width
          border: (2/1920*198)vw solid

        .database
          margin-left: (30/1920*198)vw
          p
            font-size: (16/1920*198)vw
            margin-bottom: (10/1920*198)vw

@media (max-width:750px)
  .tileWrapper
    margin-bottom: 5vw
    .front
      width: 96vw
      height: @width
      border-radius: 8px

      p
        font-size: (20/1920*415)vw
        top: (12/1920*415)vw
        left (12/1920*415)vw

    .back
      width: 96vw
      height: @width
      background-color: $black
      padding: (18.7/1920*415)vw
      border-radius: 8px

      p
        font-size: (16/1920*415)vw
        margin-bottom: (20/1920*415)vw
      .headWrapper
        h2
          font-size: (30/1920*415)vw
      .ingredientWrapper
        margin-top: (10/1920*415)vw
        .graph
          height: (116/1920*415)vw
          width: (61/1920*415)vw

      .contentWrapper
        margin-top: (22/1920*415)vw
        margin-bottom: (14/1920*415)vw

        .elementFrame
          width: (164/1920*415)vw
          height: @width
          border: (2/1920*415)vw solid

        .database
          margin-left: (30/1920*415)vw
          p
            font-size: (16/1920*415)vw
            margin-bottom: (10/1920*415)vw

</style>
