<template lang="html">
  <div class="formElement">
    <ui-header-help
    title="Taste profile"
    infoTitle="Creating a taste profile"
    infoText="We perceive taste in the combination of the five basic tastes on our tongue. They are sweet, sour, salty, umami and fatty. Each dish consists of a unique combination of these five tastes that form its flavor. <br><br> To describe the taste profile of your dish, you can adjust the intensity of each individual taste. If you think a certain taste is not included in your dish you may just not add any intensity to it."/>
    <p v-if="errorMessage" class="error">{{errorMessage}}</p>
    <div class="inputWrapper">
      <ui-taste-input @setRange="handleInputSweet" :rangeIn="tasteProfile.sweet - 1"/>
      <p class="marginal">Sweet</p>
    </div>
    <div class="inputWrapper">
      <ui-taste-input @setRange="handleInputSour" :rangeIn="tasteProfile.sour - 1"/>
      <p class="marginal">Sour</p>
    </div>
    <div class="inputWrapper">
      <ui-taste-input @setRange="handleInputSalty" :rangeIn="tasteProfile.salty - 1"/>
      <p class="marginal">Salty</p>
    </div>
    <div class="inputWrapper">
      <ui-taste-input @setRange="handleInputUmami" :rangeIn="tasteProfile.umami - 1"/>
      <p class="marginal">Umami</p>
    </div>
    <div class="inputWrapper">
      <ui-taste-input @setRange="handleInputFatty" :rangeIn="tasteProfile.fatty - 1"/>
      <p class="marginal">Fatty</p>
    </div>
    <!-- <p>{{tasteProfile}}</p> -->
  </div>
</template>

<script>
import UiTasteInput from '@/components/UiTasteInput.vue';
import UiHeaderHelp from '@/components/UiHeaderHelp.vue';

export default {
  components: {
    UiTasteInput,
    UiHeaderHelp,
  },
  created() {
    this.tasteProfile = Object.assign(this.tasteProfileIn);
  },
  props: {
    tasteProfileIn: Object,
    errorMessage: String,
  },
  methods: {
    handleInputSweet(value) {
      this.tasteProfile.sweet = value + 1;
      this.$emit('output', this.tasteProfile);
    },
    handleInputSour(value) {
      this.tasteProfile.sour = value + 1;
      this.$emit('output', this.tasteProfile);
    },
    handleInputSalty(value) {
      this.tasteProfile.salty = value + 1;
      this.$emit('output', this.tasteProfile);
    },
    handleInputUmami(value) {
      this.tasteProfile.umami = value + 1;
      this.$emit('output', this.tasteProfile);
    },
    handleInputFatty(value) {
      this.tasteProfile.fatty = value + 1;
      this.$emit('output', this.tasteProfile);
    },
  },
  data() {
    return {
      tasteProfile: {
        sweet: 0,
        sour: 0,
        salty: 0,
        umami: 0,
        fatty: 0,
      },
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.formElement
  color: $white
  .error
    color: $uiError
    position: absolute;
    top: 4rem
    font-family: 'PPPangramSansRounded'
    font-size: (16/16)rem
    font-weight: 600
    font-feature-settings: 'ss02' on;
  .inputWrapper
    .marginal
      margin-top: (15/16)rem
      margin-bottom: (30/16)rem
      font-family: 'PPPangramSansRounded'
      font-size: (16/16)rem
      font-weight: 500
      font-feature-settings: 'ss02' on;
@media(max-width: 1350px)
  .formElement
    .error
      color: $uiError
      position: relative;
      top: 0
      margin-bottom: 1rem
    .inputWrapper
      .marginal
        margin-top: 1.5vh
        margin-bottom: 4vh
        font-size: $mobileLegend
</style>
