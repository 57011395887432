<template lang="html">
  <div class="tasteGraphWrapper">
    <p class="subTitle">Taste Profile</p>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 588 942" class="graph">
      <g v-if="tasteProfile.length === 5 ">
        <g data-name="back">
          <path fill="#fffefc" stroke="#2f232d" d="M521.07 179.25a33 33 0 00-16.23-28.42L319.69 41.58a33 33 0 00-33.2-.2L83.49 158a33 33 0 00-16.56 28.62v99a33.09 33.09 0 001.59 10.14 33 33 0 00-1.59 10.14v99a33.09 33.09 0 001.59 10.1 33.09 33.09 0 00-1.59 10.14v99a33 33 0 001.59 10.14 33.1 33.1 0 00-1.59 10.15v99a33.09 33.09 0 001.59 10.14 33 33 0 00-1.59 10.14v99a33 33 0 0016.23 28.42l185.13 109.29a33 33 0 0033.2.2l203-116.57a33 33 0 0016.56-28.62v-99a33.09 33.09 0 00-1.59-10.14 33 33 0 001.59-10.14v-99a33.09 33.09 0 00-1.57-10.15 33.09 33.09 0 001.59-10.14v-99a33 33 0 00-1.59-10.14 33.1 33.1 0 001.59-10.15v-99a33.09 33.09 0 00-1.59-10.14 33 33 0 001.59-10.14z"/>
          <g fill="#2f232d">
            <path d="M285.06 877a5 5 0 01-2.54-.69L97.39 767.05a5 5 0 01-2.46-4.3v-99a5 5 0 012.51-4.34l10-5.72-10-5.92a5 5 0 01-2.46-4.3v-99a5 5 0 012.51-4.34l10-5.73-10-5.91a5 5 0 01-2.46-4.31v-99a5 5 0 012.51-4.33l10-5.73-10-5.91a5 5 0 01-2.46-4.31v-99a5 5 0 012.51-4.34l10-5.72-10-5.92a5 5 0 01-2.46-4.3v-99a5 5 0 012.51-4.34l203-116.57a5 5 0 015 0l184.97 109.23a5 5 0 012.46 4.31v99a5 5 0 01-2.51 4.34l-10 5.72 10 5.92a5 5 0 012.46 4.3v99a5 5 0 01-2.51 4.34l-10 5.73 10 5.91a5 5 0 012.46 4.31v99a5 5 0 01-2.51 4.33l-10 5.73 10 5.91a5 5 0 012.46 4.31v99a5 5 0 01-2.51 4.34l-10 5.72 10 5.92a5 5 0 012.46 4.3v99a5 5 0 01-2.51 4.34l-203 116.57a5 5 0 01-2.3.71z"/>
            <path d="M302.92 70l185.15 109.25v99l-17.42 10 17.42 10.28v99l-17.42 10 17.42 10.28v99l-17.42 10 17.42 10.28v99l-17.42 10 17.42 10.28v99L285.06 872 99.93 762.75v-99l17.42-10-17.42-10.29v-99l17.42-10-17.42-10.3v-99l17.42-10-17.42-10.29v-99l17.42-10-17.42-10.29v-99L302.92 70m0-10a9.94 9.94 0 00-5 1.33L95 177.89a10 10 0 00-5 8.68v99a10 10 0 004.91 8.61l2.63 1.55-2.54 1.46a10 10 0 00-5 8.67v99a10 10 0 004.91 8.61l2.56 1.53-2.47 1.48a10 10 0 00-5 8.67v99a10 10 0 004.91 8.62l2.63 1.54-2.54 1.46a10 10 0 00-5 8.68v99a10 10 0 004.91 8.61l2.63 1.55-2.54 1.46a10 10 0 00-5 8.67v99a10 10 0 004.91 8.61L280 880.61a10 10 0 0010.06.06l203-116.56a10 10 0 005-8.68v-99a10 10 0 00-4.91-8.61l-2.63-1.55 2.52-1.45a10 10 0 005-8.67v-99a10 10 0 00-4.91-8.61l-2.6-1.54 2.52-1.45a10 10 0 005-8.67v-99a10 10 0 00-4.91-8.62l-2.63-1.54 2.52-1.45a10 10 0 005-8.68v-99a10 10 0 00-4.91-8.61l-2.63-1.55 2.52-1.45a10 10 0 005-8.67v-99a10 10 0 00-4.91-8.61L308 61.39a9.91 9.91 0 00-5.08-1.39z"/>
          </g>
        </g>
        <g stroke="#2f232d" stroke-linejoin="round" stroke-width="2" data-name="fife">
          <path :fill="tasteProfile[4].main ? tasteProfile[4].colorShade : '#b3b2b1'" d="M285.06 773L99.93 663.74l203-116.57 185.14 109.25z"/>
          <path :fill="tasteProfile[4].main ? tasteProfile[4].color : '#fffefc'" d="M99.93 762.75v-99.01l185.13 109.25V872L99.93 762.75zM488.07 656.42v99.01L285.06 872v-99.01l203.01-116.57z"/>
        </g>
        <g stroke="#2f232d" stroke-linejoin="round" stroke-width="2" data-name="four">
          <path :fill="tasteProfile[3].main ? tasteProfile[3].colorShade : '#b3b2b1'" d="M285.06 653.7L99.93 544.45l203-116.57 185.14 109.25z"/>
          <path :fill="tasteProfile[3].main ? tasteProfile[3].color : '#fffefc'" d="M99.93 643.46v-99.01L285.06 653.7v99.01L99.93 643.46zM488.07 537.13v99.01L285.06 752.71V653.7l203.01-116.57z"/>
        </g>
        <g stroke="#2f232d" stroke-linejoin="round" stroke-width="2" data-name="three">
          <path :fill="tasteProfile[2].main ? tasteProfile[2].colorShade : '#b3b2b1'" d="M285.06 534.4L99.93 425.15l203-116.56 185.14 109.25z"/>
          <path :fill="tasteProfile[2].main ? tasteProfile[2].color : '#fffefc'" d="M99.93 524.16v-99.01L285.06 534.4v99.01L99.93 524.16zM488.07 417.84v99.01L285.06 633.41V534.4l203.01-116.56z"/>
        </g>
        <g stroke="#2f232d" stroke-linejoin="round" stroke-width="2" data-name="two">
          <path :fill="tasteProfile[1].main ? tasteProfile[1].colorShade : '#b3b2b1'" d="M285.06 415.11L99.93 305.86l203-116.57 185.14 109.25z"/>
          <path :fill="tasteProfile[1].main ? tasteProfile[1].color : '#fffefc'" d="M99.93 404.87v-99.01l185.13 109.25v99.01L99.93 404.87zM488.07 298.54v99.02L285.06 514.12v-99.01l203.01-116.57z"/>
        </g>
        <g :fill="tasteProfile[0].main ? tasteProfile[0].color : '#fffefc'" stroke="#2f232d" stroke-linejoin="round" stroke-width="2" data-name="one">
          <path d="M285.06 295.82L99.93 186.57 302.92 70l185.15 109.25z"/>
          <path d="M99.93 285.58v-99.01l185.13 109.25v99.01L99.93 285.58zM488.07 179.25v99.01L285.06 394.83v-99.01l203.01-116.57z"/>
        </g>
        <text :class="{main: tasteProfile[0].main}" class="tasteText" font-size="58" font-weight="500" transform="matrix(.81 .48 0 1 110.36 263.07)">
          {{tasteProfile[0].taste}}
        </text>
        <text :class="{main: tasteProfile[1].main}" class="tasteText" font-size="58" font-weight="500" transform="matrix(.81 .48 0 1 110.36 384.91)">
          {{tasteProfile[1].taste}}
        </text>
        <text :class="{main: tasteProfile[2].main}" class="tasteText" font-size="58" font-weight="500" transform="matrix(.81 .48 0 1 110.36 505.24)">
          {{tasteProfile[2].taste}}
        </text>
        <text :class="{main: tasteProfile[3].main}" class="tasteText" font-size="58" font-weight="500" transform="matrix(.81 .48 0 1 110.36 622.46)">
          {{tasteProfile[3].taste}}
        </text>
        <text :class="{main: tasteProfile[4].main}" class="tasteText" font-size="58" font-weight="500" transform="matrix(.81 .48 0 1 110.36 742.72)">
          {{tasteProfile[4].taste}}
        </text>
      </g>
      <g v-if="tasteProfile.length === 4" data-name="4_Tastes" class="graph">
        <path fill="#fffefc" stroke="#2f232d" d="M521.07 179.25a33 33 0 00-16.23-28.42L319.69 41.58a33 33 0 00-33.2-.2L83.49 158a33 33 0 00-16.56 28.62v124a33 33 0 002.82 13.35 33 33 0 00-2.82 13.36v124a33 33 0 002.82 13.36A33 33 0 0066.93 488v124a33 33 0 002.82 13.35 33 33 0 00-2.82 13.36v124a33 33 0 0016.23 28.42l185.13 109.29a33 33 0 0033.72-.11L505 778.75a33 33 0 0016-28.32v-119a33 33 0 00-4-15.86 32.9 32.9 0 004.06-15.86v-119a33 33 0 00-4.06-15.87 32.86 32.86 0 004.07-15.84V330a33 33 0 00-4.07-15.88 32.87 32.87 0 004.06-15.86z"/>
        <g fill="#2f232d">
          <path d="M285.06 877.5a5.51 5.51 0 01-2.8-.76L97.13 767.49a5.49 5.49 0 01-2.7-4.74v-124a5.49 5.49 0 012.76-4.75l14.75-8.47-14.81-8.74a5.49 5.49 0 01-2.7-4.73V488a5.49 5.49 0 012.76-4.77l14.75-8.46L97.13 466a5.51 5.51 0 01-2.7-4.74v-124a5.49 5.49 0 012.76-4.77l14.75-8.47-14.81-8.73a5.51 5.51 0 01-2.7-4.74v-124a5.49 5.49 0 012.76-4.77l203-116.57a5.52 5.52 0 015.54 0l185.14 109.3a5.51 5.51 0 012.7 4.74v119a5.48 5.48 0 01-2.67 4.75l-18.74 11.2 18.71 11a5.51 5.51 0 012.7 4.73V449a5.51 5.51 0 01-2.67 4.72l-18.74 11.21 18.71 11a5.51 5.51 0 012.7 4.74v119a5.5 5.5 0 01-2.67 4.72l-18.74 11.22 18.71 11a5.51 5.51 0 012.7 4.74v119a5.48 5.48 0 01-2.67 4.72l-203 121.57a5.51 5.51 0 01-2.84.86z"/>
          <path d="M302.92 70l185.15 109.25v119l-26.67 16L488.07 330v119l-26.67 16 26.67 15.74v119l-26.67 16 26.67 15.74v119L285.06 872 99.93 762.75v-124l22.94-13.18L99.93 612V488l22.94-13.17-22.94-13.53v-124l22.94-13.17-22.94-13.55v-124L302.92 70m0-11a11.05 11.05 0 00-5.48 1.46L94.45 177a11 11 0 00-5.52 9.54v124a11 11 0 005.41 9.47L101 324l-6.55 3.76a11 11 0 00-5.52 9.54v124a11 11 0 005.41 9.48l6.66 3.93-6.55 3.76a11 11 0 00-5.52 9.53v124a11 11 0 005.41 9.47l6.66 3.93-6.55 3.77a11 11 0 00-5.52 9.54v124a11 11 0 005.41 9.47l185.13 109.29a11 11 0 0011.24 0l203-121.57a11 11 0 005.35-9.44v-119a11 11 0 00-5.41-9.47l-10.73-6.34 10.79-6.46a11 11 0 005.35-9.44v-119a11 11 0 00-5.41-9.48l-10.73-6.33 10.79-6.47a11 11 0 005.35-9.43V330a11 11 0 00-5.41-9.47l-10.73-6.33 10.79-6.47a11 11 0 005.35-9.44v-119a11 11 0 00-5.41-9.47L308.51 60.53a10.94 10.94 0 00-5.59-1.53z"/>
        </g>
        <g stroke="#2f232d" stroke-linejoin="round" stroke-width="2" data-name="four">
          <path :fill="tasteProfile[3].main ? tasteProfile[3].colorShade : '#b3b2b1'" d="M285.06 748L99.93 638.74l203-116.57 185.14 109.25z"/>
          <path :fill="tasteProfile[3].main ? tasteProfile[3].color : '#fffefc'" d="M99.93 762.75V638.74l185.13 109.25V872L99.93 762.75zM488.07 631.42v119.01L285.06 872V747.99l203.01-116.57z"/>
        </g>
        <g stroke="#2f232d" stroke-linejoin="round" stroke-width="2" data-name="three">
          <path :fill="tasteProfile[2].main ? tasteProfile[2].colorShade : '#b3b2b1'" d="M285.06 597.26L99.93 488l203-116.56L488.07 480.7z"/>
          <path :fill="tasteProfile[2].main ? tasteProfile[2].color : '#fffefc'" d="M99.93 612.03V488.01l185.13 109.25v124.02L99.93 612.03zM488.07 480.7v119.01L285.06 721.28V597.26L488.07 480.7z"/>
        </g>
        <g stroke="#2f232d" stroke-linejoin="round" stroke-width="2" data-name="two">
          <path :fill="tasteProfile[1].main ? tasteProfile[1].colorShade : '#b3b2b1'" d="M285.06 446.54L99.93 337.29l203-116.57L488.07 330z"/>
          <path :fill="tasteProfile[1].main ? tasteProfile[1].color : '#fffefc'" d="M99.93 461.3V337.29l185.13 109.25v124.01L99.93 461.3zM488.07 329.97v119.02L285.06 570.55V446.54l203.01-116.57z"/>
        </g>
        <g :fill="tasteProfile[0].main ? tasteProfile[0].color : '#fffefc'" stroke="#2f232d" stroke-linejoin="round" stroke-width="2" data-name="one">
          <path d="M285.06 295.82L99.93 186.57 302.92 70l185.15 109.25z"/>
          <path d="M99.93 310.58V186.57l185.13 109.25v124.01L99.93 310.58zM488.07 179.25v119.01L285.06 419.83V295.82l203.01-116.57z"/>
        </g>
        <text :class="{main: tasteProfile[0].main}" class="tasteText" font-size="58" font-weight="500" transform="matrix(.81 .48 0 1 110.74 275.43)">
          {{tasteProfile[0].taste}}
        </text>
        <text :class="{main: tasteProfile[1].main}" class="tasteText" font-size="58" font-weight="500" transform="matrix(.81 .48 0 1 110.74 428.65)">
          {{tasteProfile[1].taste}}
        </text>
        <text :class="{main: tasteProfile[2].main}" class="tasteText" font-size="58" font-weight="500" transform="matrix(.81 .48 0 1 110.74 581.28)">
          {{tasteProfile[2].taste}}
        </text>
        <text :class="{main: tasteProfile[3].main}" class="tasteText" font-size="58" font-weight="500" transform="matrix(.81 .48 0 1 110.74 729.7)">
          {{tasteProfile[3].taste}}
        </text>
      </g>
      <g v-if="tasteProfile.length === 3" data-name="3_Tastes" class="graph">
        <path fill="#fffefc" stroke="#2f232d" d="M521.07 179.25a33 33 0 00-16.23-28.42L319.69 41.58a33 33 0 00-33.2-.2L83.49 158a33 33 0 00-16.56 28.62v174a32.89 32.89 0 002.9 13.54 32.81 32.81 0 00-2.9 13.53v174a32.87 32.87 0 002.9 13.53 32.87 32.87 0 00-2.9 13.53v174a33 33 0 0016.22 28.42l185.14 109.25a33 33 0 0033.72-.11L505 778.75a33 33 0 0016-28.32v-169a33 33 0 00-4.15-16 33 33 0 004.15-16v-169a33 33 0 00-4.15-16 33 33 0 004.15-16z"/>
        <g fill="#2f232d">
          <path d="M285.06 877.5a5.51 5.51 0 01-2.8-.76L97.13 767.49a5.49 5.49 0 01-2.7-4.74v-174a5.49 5.49 0 012.76-4.75l15-8.64-15.06-8.96a5.49 5.49 0 01-2.7-4.73v-174a5.49 5.49 0 012.76-4.77l15-8.64-15.11-8.92a5.49 5.49 0 01-2.7-4.74v-174a5.49 5.49 0 012.76-4.77l203-116.57a5.52 5.52 0 015.54 0l185.19 109.25a5.51 5.51 0 012.7 4.74v169a5.51 5.51 0 01-2.67 4.75l-19 11.39 19 11.22a5.51 5.51 0 012.7 4.73v169a5.5 5.5 0 01-2.67 4.72l-19 11.4 19 11.21a5.51 5.51 0 012.7 4.74v169a5.48 5.48 0 01-2.67 4.72l-203 121.57a5.48 5.48 0 01-2.9.8z"/>
          <path d="M302.92 70l185.15 109.25v169l-27 16.15 27 15.91v169l-27 16.15 27 15.92v169L285.06 872 99.93 762.75v-174l23.24-13.35-23.24-13.73v-174l23.24-13.35-23.24-13.74v-174L302.92 70m0-11a11.05 11.05 0 00-5.48 1.46L94.45 177a11 11 0 00-5.52 9.54v174a11 11 0 005.4 9.48l7 4.11-6.86 3.94a11 11 0 00-5.52 9.54v174a11 11 0 005.4 9.47l7 4.11-6.86 3.94a11 11 0 00-5.52 9.54v174a11 11 0 005.4 9.47l185.1 109.33a11 11 0 0011.24 0l203-121.57a11 11 0 005.35-9.44v-169a11 11 0 00-5.41-9.48l-11-6.51 11.09-6.64a11 11 0 005.35-9.44v-169a11 11 0 00-5.41-9.47l-11-6.51 11.09-6.65a11 11 0 005.35-9.43v-169a11 11 0 00-5.41-9.47L308.51 60.53a11 11 0 00-5.59-1.53z"/>
        </g>
        <g stroke="#2f232d" stroke-linejoin="round" stroke-width="2" data-name="three">
          <path :fill="tasteProfile[2].main ? tasteProfile[2].colorShade : '#b3b2b1'" d="M285.06 698L99.93 588.73l203-116.56 185.14 109.25z"/>
          <path :fill="tasteProfile[2].main ? tasteProfile[2].color : '#fffefc'" d="M99.92 762.75V588.73l185.14 109.25V872L99.92 762.75zM488.07 581.42v169.01L285.06 872V697.98l203.01-116.56z"/>
        </g>
        <g stroke="#2f232d" stroke-linejoin="round" stroke-width="2" data-name="two">
          <path :fill="tasteProfile[1].main ? tasteProfile[1].colorShade : '#b3b2b1'" d="M285.06 496.9L99.93 387.65l203-116.57 185.14 109.25z"/>
          <path :fill="tasteProfile[1].main ? tasteProfile[1].color : '#fffefc'" d="M99.92 561.67V387.65L285.06 496.9v174.02L99.92 561.67zM488.07 380.33v169.02L285.06 670.92V496.9l203.01-116.57z"/>
        </g>
        <g :fill="tasteProfile[0].main ? tasteProfile[0].color : '#fffefc'" stroke="#2f232d" stroke-linejoin="round" stroke-width="2" data-name="one">
          <path d="M285.06 295.82L99.93 186.57 302.92 70l185.15 109.25zM99.92 360.58V186.57l185.14 109.25v174.01L99.92 360.58z"/>
          <path d="M488.07 179.25v169.02L285.06 469.83V295.82l203.01-116.57z"/>
        </g>
        <text :class="{main: tasteProfile[0].main}" class="tasteText" font-size="58" font-weight="500" transform="matrix(.81 .48 0 1 112.31 304.27)">
          {{tasteProfile[0].taste}}
        </text>
        <text :class="{main: tasteProfile[1].main}" class="tasteText" font-size="58" font-weight="500" transform="matrix(.81 .48 0 1 112.31 506.24)">
          {{tasteProfile[1].taste}}
        </text>
        <text :class="{main: tasteProfile[2].main}" class="tasteText" font-size="58" font-weight="500" transform="matrix(.81 .48 0 1 112.31 709.31)">
          {{tasteProfile[2].taste}}
        </text>
      </g>
      <g v-if="tasteProfile.length === 2" data-name="2_Tastes" class="graph">
        <g data-name="back">
          <path fill="#fffefc" stroke="#2f232d" d="M521.07 179.25a33 33 0 00-16.23-28.42L319.69 41.58a33 33 0 00-33.2-.2L83.49 158a33 33 0 00-16.56 28.62v274a32.84 32.84 0 003.15 14.07 32.84 32.84 0 00-3.15 14.07v274a33 33 0 0016.23 28.42l185.13 109.24a33 33 0 0033.72-.11L505 778.75a33 33 0 0016-28.32v-269a32.93 32.93 0 00-4.46-16.57 32.93 32.93 0 004.46-16.57z"/>
          <g fill="#2f232d">
            <path d="M285.06 877.5a5.51 5.51 0 01-2.8-.76L97.13 767.49a5.51 5.51 0 01-2.7-4.74v-274a5.49 5.49 0 012.76-4.75l16-9.17-16-9.46a5.52 5.52 0 01-2.7-4.74v-274a5.49 5.49 0 012.76-4.77l203-116.57a5.52 5.52 0 015.54 0l185.08 109.22a5.51 5.51 0 012.7 4.74v269a5.48 5.48 0 01-2.67 4.75L471 464.93l19.91 11.75a5.49 5.49 0 012.7 4.73v269a5.48 5.48 0 01-2.67 4.72l-203 121.57a5.51 5.51 0 01-2.88.8z"/>
            <path d="M302.92 70l185.15 109.25v269L460.2 465l27.87 16.45v269L285.06 872 99.93 762.75v-274l24.16-13.88-24.16-14.28v-274L302.92 70m0-11a11.05 11.05 0 00-5.48 1.46L94.45 177a11 11 0 00-5.52 9.54v274a11 11 0 005.41 9.47l7.89 4.66-7.78 4.47a11 11 0 00-5.52 9.54v274a11 11 0 005.41 9.47l185.13 109.32a11 11 0 0011.24 0l203-121.57a11 11 0 005.35-9.44v-269a11 11 0 00-5.41-9.47l-11.93-7.05 12-7.18a11 11 0 005.35-9.44v-269a11 11 0 00-5.41-9.47L308.51 60.53a10.94 10.94 0 00-5.59-1.53z"/>
          </g>
        </g>
        <g stroke="#2f232d" stroke-linejoin="round" stroke-width="2" data-name="two">
          <path :fill="tasteProfile[1].main ? tasteProfile[1].colorShade : '#b3b2b1'" d="M285.06 598L99.93 488.73l203-116.57 185.14 109.25z"/>
          <path :fill="tasteProfile[1].main ? tasteProfile[1].color : '#fffefc'" d="M99.93 762.75V488.73l185.13 109.25V872L99.93 762.75zM488.07 481.41v269.02L285.06 872V597.98l203.01-116.57z"/>
        </g>
        <g :fill="tasteProfile[0].main ? tasteProfile[0].color : '#fffefc'" stroke="#2f232d" stroke-linejoin="round" stroke-width="2" data-name="one">
          <path d="M285.06 295.82L99.93 186.57 302.92 70l185.15 109.25z"/>
          <path d="M99.93 460.59V186.57l185.13 109.25v274.02L99.93 460.59zM488.07 179.25v269.02L285.06 569.84V295.82l203.01-116.57z"/>
        </g>
        <text :class="{main: tasteProfile[0].main}" class="tasteText" font-size="58" font-weight="500" transform="matrix(.81 .48 0 1 116.98 352.61)">
          {{tasteProfile[0].taste}}
        </text>
        <text :class="{main: tasteProfile[1].main}" class="tasteText" font-size="58" font-weight="500" transform="matrix(.81 .48 0 1 116.98 667.15)">
          {{tasteProfile[1].taste}}
        </text>
      </g>
      <g v-if="tasteProfile.length === 1" data-name="1_Taste" class="graph">
        <g data-name="back">
          <path fill="#fffefc" stroke="#2f232d" d="M504.84 150.83L319.69 41.58a33 33 0 00-33.2-.2L83.49 158a33 33 0 00-16.56 28.62v576.13a33 33 0 0016.23 28.42l185.13 109.25a33 33 0 0033.72-.11L505 778.75a33 33 0 0016-28.32V179.25a33 33 0 00-16.16-28.42z"/>
          <g fill="#2f232d">
            <path d="M285.06 877.5a5.51 5.51 0 01-2.8-.76L97.13 767.49a5.51 5.51 0 01-2.7-4.74V186.57a5.49 5.49 0 012.76-4.77l203-116.57a5.52 5.52 0 015.54 0l185.14 109.28a5.51 5.51 0 012.7 4.74v571.18a5.48 5.48 0 01-2.67 4.72l-203 121.57a5.45 5.45 0 01-2.84.78z"/>
            <path d="M302.92 70l185.15 109.25v571.18L285.06 872 99.93 762.75V186.57L302.92 70m0-11a11.05 11.05 0 00-5.48 1.46L94.45 177a11 11 0 00-5.52 9.54v576.21a11 11 0 005.41 9.47l185.13 109.25a11 11 0 0011.24 0l203-121.57a11 11 0 005.35-9.44V179.25a11 11 0 00-5.41-9.47L308.51 60.53a10.94 10.94 0 00-5.59-1.53z"/>
          </g>
        </g>
        <g :fill="tasteProfile[0].main ? tasteProfile[0].color : '#fffefc'" stroke="#2f232d" stroke-linejoin="round" stroke-width="2" data-name="one">
          <path d="M285.06 295.82L99.93 186.57 302.92 70l185.15 109.25z"/>
          <path d="M99.93 762.75V186.57l185.13 109.25V872L99.93 762.75zM488.07 179.25v571.18L285.06 872V295.82l203.01-116.57z"/>
        </g>
        <text :class="{main: tasteProfile[0].main}" class="tasteText" font-size="58" font-weight="500" transform="matrix(.81 .48 0 1 109.5 484.07)">
          {{tasteProfile[0].taste}}
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    tasteProfile: Array,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'

.tasteGraphWrapper
  width: (588/1080*100)vh
  height: (942/1080*100)vh
  background-color: $white
  box-shadow: 0 0 0 (2/1080*100)vh $black
  border-radius: $tileRadiusDesktop
  padding-top: (18/1080*100)vh
  padding-left: (14/1080*100)vh
  padding-right: (14/1080*100)vh
  .graph
    height:(870/1080*100)vh
    .tasteText
      text-transform: capitalize;
      font-family: 'PPPangramSansRounded'
      font-feature-settings: 'ss02' on;

  .subTitle
    font-size: $subHead
    font-weight: 600
    color: $gray
    // padding-top: (18/1080*100)vh

  .main
    fill: $white

@media (max-width: 750px)
  .tasteGraphWrapper
      width: (520/1080*100)vh
      height: (942/1080*100)vh
      background-color: $white
      box-shadow: 0 0 0 (2/1080*100)vh $black
      border-radius: $tileRadiusDesktop
      padding-top: (18/1080*100)vh
      padding-left: (14/1080*100)vh
      padding-right: (14/1080*100)vh
      .graph
        widht: 110%
        margin-left: -5%
        margin-top: 5%
        .tasteText
          text-transform: capitalize;
          font-family: 'PPPangramSansRounded'
          font-feature-settings: 'ss02' on;

      .subTitle
        font-size: (13/16)rem
        font-weight: 600
        color: $gray
        // padding-top: (18/1080*100)vh

      .main
        fill: $white

</style>
