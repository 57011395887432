<template lang="html">
  <div class="formElement">
    <ui-header-help
    title="Add ingredients"
    infoTitle="Adding ingredients"
    infoText="Each dish consists of carefully selected ingredients that complement each other. If you are unsure how many ingredients you want to add, eight is good way to start – the average amount of ingredients in western dishes. <br><br> To add ingredients, enter their name and amount. You can choose the unit from the dropdown menu. Confirm your entries using the enter key or the Add button on the right. The ingredient is added to your list. If you accidentally add something wrong, you can delete the ingredient label by simply clicking on it. <br><br> For now, you can only choose from ingredients that are part of the application. For more information visit the wiki."
    />
    <p v-if="error || errorMessage" class="errorIn">{{checkError}}</p>
    <div class="inputWrapper">
      <div class="inputForm">
        <Ui-Input-Search
          ref="focusIngredient"
          class="ingredientInput"
          :error="errorIngredient"
          @onError="handleIngredientError"
          defaultText="Ingredient name"
          @inputEnter="handleIngredientInput"
          :dataBaseItems="ingredientData"
          type="ingredient"/>
        <Ui-Input ref="focusAmmount" class="ammountInput" :error="errorAmmount" defaultText="1" @inputEnter="handleAmmountInput"/>
        <ui-unit-picker ref="focusDropdown" class="dropdown" :contentElements="contentElements" @changeUnit="handleDropdown"/>
        <Ui-Button ref="focusButton" class="addButton" label="Add" mode="cta" icon="add" @buttonClick="addIngredient"/>
      </div>
      <!-- <p v-if="error" class="errorMessage">{{error}}</p> -->
    </div>
    <div class="contentWrapper">
      <transition-group name="enterContent">
        <ui-content-label
        v-for="(ingredient,index) in ingredients"
        class="label"
        :key= "ingredient.title"
        :label="`${ingredient.title}, ${ingredient.ammount}`"
        @removeItem="removeIngredient(index)"/>
      </transition-group>
    </div>
    <!-- <p>{{ingredients}}</p> -->
</div>
</template>

<script>
import UiButton from '@/components/UiButton.vue';
import UiInput from '@/components/UiInput.vue';
import UiInputSearch from '@/components/UiInputSearch.vue';
import UiUnitPicker from '@/components/UiUnitPicker.vue';
import UiContentLabel from '@/components/UiContentLabel.vue';
import UiHeaderHelp from '@/components/UiHeaderHelp.vue';

export default {
  created() {
    this.ingredientData = Array.from(this.$store.state.ingredients.keys());
    // // console.log((this.ingredientData);
  },
  mounted() {
    this.ingredients = [...this.ingredientIn];
  },
  props: {
    ingredientIn: Array,
    errorMessage: String,
  },
  components: {
    UiButton,
    UiInput,
    UiInputSearch,
    UiUnitPicker,
    UiContentLabel,
    UiHeaderHelp,
  },
  computed: {
    checkError() {
      if (this.error) return this.error;
      if (this.errorMessage) return this.errorMessage;
      return '';
    },
  },
  data() {
    return {
      ingredientData: [],
      ingredientInput: '',
      ammountInput: '',
      ammountUnit: 'pc.',
      errorAmmount: false,
      errorIngredient: false,
      problemInput: '',
      contentElements: ['pc.', 'tbsp.', 'tsp.', 'gram', 'ml'],
      ingredients: [],
      error: '',
    };
  },
  methods: {
    handleIngredientError(error) {
      this.error = error;
      this.errorIngredient = true;
    },
    handleDropdown(value) {
      // // console.log((value);
      // // console.log((this.contentElements[value]);
      if (value === 3) this.ammountUnit = 'g';
      else this.ammountUnit = this.contentElements[value];
    },
    addIngredient() {
      // // console.log(('added');
      // Hier muss man noch eine Validation einbauen!
      // Check for duplicate Keys
      let duplicateKey = false;
      for (let i = 0; i < this.ingredients.length; i += 1) {
        if (this.ingredients[i].title === this.ingredientInput) duplicateKey = true;
      }
      // // console.log((duplicateKey);

      // Check if Ingredient is part of the Database
      let foundInDatabse = false;
      for (let i = 0; i < this.ingredientData.length; i += 1) {
        if (this.ingredientData[i] === this.ingredientInput) foundInDatabse = true;
      }
      // // console.log((foundInDatabse);
      if (duplicateKey) {
        this.errorIngredient = true;
        this.error = `You already added ${this.ingredientInput}`;
        this.problemInput = this.ingredientInput;
      } else if (!foundInDatabse) {
        this.errorIngredient = true;
        this.error = "Unfortunately this ingredient doesn't exist";
        this.problemInput = this.ingredientInput;
      } else if (this.ammountInput && this.ingredientInput && !this.errorAmmount && !this.errorIngredient && !duplicateKey && foundInDatabse) {
        const element = {
          title: this.ingredientInput,
          ammount: `${this.ammountInput} ${this.ammountUnit}`,
          main: false,
        };
        this.ingredients.unshift(element);
        this.$emit('output', this.ingredients);
      } else if (!this.ammountInput && this.ingredientInput) {
        this.errorAmmount = true;
        this.error = 'Please add an ammount to your ingredient';
      } else if (!this.ingredientInput && this.ammountInput) {
        this.errorIngredient = true;
        this.error = 'Please add an ingredient';
      } else if (!this.ingredientInput && !this.ammountInput) {
        this.errorIngredient = true;
        this.errorAmmount = true;
        this.error = 'You need to add something frist';
        // // // console.log((this.$refs.focusIngredient.focus());
        // this.$refs.focusIngredient.focus();
      }
      // Only add ingredient if it does not exist already
    },
    removeIngredient(index) {
      // Wenn das problematische Ingredient rausgelöscht wird, lösche auch den Error
      if (this.ingredients[index].title === this.problemInput) {
        this.error = '';
        this.errorIngredient = false;
      }
      this.ingredients.splice(index, 1);
      this.$emit('output', this.ingredients);
    },
    isNumeric(value) {
      return /^-?\d+$/.test(value);
    },
    handleIngredientInput(value) {
      this.errorIngredient = false;
      this.error = '';
      this.ingredientInput = value;
      // // console.log((value);
    },
    handleAmmountInput(value) {
      // Check if Input is Number Format or not
      if (this.isNumeric(value)) {
        this.ammountInput = value;
        this.error = '';
        this.errorAmmount = false;
      } else {
        this.error = 'Invalid number format please check input';
        this.errorAmmount = true;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.formElement
  color: $white
  position: relative;
  .errorIn
    color: $uiError
    position: absolute;
    top: 4rem
    font-family: 'PPPangramSansRounded'
    font-size: (16/16)rem
    font-weight: 600
    font-feature-settings: 'ss02' on;
  .inputWrapper
    .inputForm
      z-index: 1
      display: flex
      justify-content: space-between;
      height: (48/16)rem

      .ingredientInput
        z-index: 2
        flex-basis: 55%
        margin-right: (16/16)rem

      .ammountInput
        margin-right: (16/16)rem
        flex-basis: 18%

      .dropdown
        width: 17%
        margin-right: (16/16)rem
        height: (48/16)rem

      .addButton
        margin-left: (32/16)rem
  .contentWrapper
    z-index: 0
    margin-top: (36/16)rem
    position: relative;
    height: 250px
    -webkit-mask-image: linear-gradient(180deg, $deepBlack 70%, transparent);
    overflow: scroll;

    .label
      margin-bottom: (14/16)rem
      width: 100%

.enterContent-enter-active,
.enterContent-leave-active
  transition: opacity 250ms ease-out, transform 250ms ease-out

.enterContent-leave-active
  position: absolute;

.enterContent-enter-from,
.enterContent-leave-to
  opacity: 0
  transform: translateY(30%)

.enterContent-move
  transition: transform 250ms ease

.enterContent-enter-to,
.enterContent-leave-from
  opacity: 1
  transform: translateY(0%)

@media (max-width:750px)
  .formElement
    color: $white
    .errorIn
      color: $uiError
      position: relative;
      top: 0
      margin-bottom: 1rem
    .inputWrapper
      .inputForm
        z-index: 1
        display: flex
        justify-content: space-between;
        flex-wrap: wrap
        height: auto

        .ingredientInput
          z-index: 2
          flex-basis: 77.5%
          margin-right: 0

        .ammountInput
          margin-right: 0
          flex-basis: 20%

        .dropdown
          margin-top: (12/16)rem
          flex-basis: 100%
          margin-right: 0
          height: (48/16)rem

        .addButton
          margin-top: (12/16)rem
          margin-left: 0
          flex-basis: 100%

      .errorMessage
        color: $uiError
        margin-left: (20/1920*100) vw
        margin-top: (12/16)rem
        font-family: 'PPPangramSansRounded'
        font-size: (14/16)rem
        font-weight: 600
        font-feature-settings: 'ss02' on;
    .contentWrapper
      margin-top: 1rem
      z-index: 0
      position: relative;
      height: auto
      -webkit-mask-image: inherit;
      overflow: inherit;
      margin-bottom: 2rem

      .label
        margin-bottom: (10/16)rem
        width: 100%

</style>
