<template lang="html">
  <div class="triggerWrapper">
    <div class="navWrapper">
      <p class="subTitle">Trigger</p>
      <!-- <TasteButton label="Close Trigger" mode="cta" icon="close" @buttonClick="$emit('buttonClick', $event)"/> -->
      <taste-button  :label="buttonType === 'cta' ? 'Close': ''" :mode="buttonType" icon="back" @buttonClick="$emit('buttonClick', $event)"/>
    </div>
    <div class="illustrationWrapper">
      <svg class="leftArrow" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" @click="flipPage(-1)" v-if="trigger.length > 1">
      <path d="M1 8.5L13 15.4282L13 1.5718L1 8.5Z" stroke-width="2" stroke-linejoin="round"/>
      </svg>
      <!-- <transition mode="out-in" name="fade"> -->
        <TasteTrigger class="illustration"
        :trigger="trigger[currentPage].title"
        :firstColor="firstColor"
        :secondColor="secondColor"
        :firstColorShade="firstColorShade"
        :secondColorShade="secondColorShade"
        :direction="direction"
        :style="[trigger.length === 1 ? 'margin-top: 2vh' : 'margin-top: 0']"
        />
      <!-- </transition> -->
      <svg class="rightArrow" width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" @click="flipPage(1)" v-if="trigger.length > 1">
        <path d="M16 8.5L4 15.4282L4 1.5718L16 8.5Z" stroke-width="2" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="pageIndicator" v-show="trigger.length > 1">
      <UiPageIndicator
      v-for="page in trigger.length"
      :key="page"
      :class="{active: currentPage === page - 1}"
      @click="currentPage = page-1"
      />
    </div>
    <p v-html="trigger[currentPage].descrption" class="triggerText" :style="[trigger.length === 1 ? 'margin-top: 6vh' : 'margin-top: 0']"></p>
  </div>
</template>

<script>
import TasteTrigger from '@/components/TasteTrigger.vue';
import TasteButton from '@/components/TasteButton.vue';
import UiPageIndicator from '@/components/UiPageIndicator.vue';

export default {
  created() {
    // Check wich color is currently main Color and apply shades
    const firstTaste = this.tastes[0];
    const secondTaste = this.tastes[1];

    switch (firstTaste) {
      case 'sweet':
        this.firstColor = '#FF546D';
        this.firstColorShade = '#A63747';
        break;
      case 'sour':
        this.firstColor = '#DEF25C';
        this.firstColorShade = '#96A343';
        break;
      case 'umami':
        this.firstColor = '#733F4B';
        this.firstColorShade = '#4D2C33';
        break;
      case 'salty':
        this.firstColor = '#CEDEED';
        this.firstColorShade = '#8D9AA6';
        break;
      case 'fatty':
        this.firstColor = '#E5DFB8';
        this.firstColorShade = '#ADA88C';
        break;

      default:
        this.firstColor = 'red';
        this.firstColorShade = 'blue';
    }

    switch (secondTaste) {
      case 'sweet':
        this.secondColor = '#FF546D';
        this.secondColorShade = '#A63747';
        break;
      case 'sour':
        this.secondColor = '#DEF25C';
        this.secondColorShade = '#96A343';
        break;
      case 'umami':
        this.secondColor = '#733F4B';
        this.secondColorShade = '#4D2C33';
        break;
      case 'salty':
        this.secondColor = '#CEDEED';
        this.secondColorShade = '#8D9AA6';
        break;
      case 'fatty':
        this.secondColor = '#E5DFB8';
        this.secondColorShade = '#ADA88C';
        break;

      default:
        this.secondColor = 'red';
        this.secondColorShade = 'blue';
    }
    this.buttonType = 'cta';
    if (window.innerWidth < 750) this.buttonType = 'noLabel';
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  components: {
    TasteTrigger,
    TasteButton,
    UiPageIndicator,
  },
  props: {
    trigger: Array,
    tastes: Array,
  },
  data() {
    return {
      buttonType: 'cta',
      direction: 'left',
      currentPage: 0,
      firstColor: '#FF546D',
      firstColorShade: '#A63747',
      secondColor: '#E5DFB8',
      secondColorShade: '#ADA88C',
    };
  },
  methods: {
    flipPage(direction) {
      const newPage = this.currentPage + direction;
      // // // console.log(newPage);
      if (newPage > this.trigger.length - 1) {
        this.currentPage = 0;
      } else if (newPage < 0) {
        this.currentPage = this.trigger.length - 1;
      } else {
        this.currentPage = newPage;
      }
      if (direction === -1) { this.direction = 'right'; }
      if (direction === 1) { this.direction = 'left'; }
    },
  },
  watch: {
    windowWidth(newValue) {
      if (newValue < 750) {
        this.buttonType = 'noLabel';
      } else {
        this.buttonType = 'cta';
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.triggerWrapper
  background-color: $black
  border-radius: $tileRadiusDesktop
  color: $white
  text-align: left
  padding: (23/1080*100)vh
  width: (733/1080*100)vh
  height: (942/1080*100)vh
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0

  .navWrapper
    display: flex
    justify-content: space-between
    // margin-bottom: (20/1080*100)vh

    .subTitle
      font-size: $subHead
      font-weight: 600

  .illustrationWrapper
    height: (680/1080 * 100)vh
    display: flex

    .leftArrow, .rightArrow
      align-self: center
      width: (17/1080*100)vh
      fill: $black
      stroke: $white

      &:hover
        fill: $white
        cursor: pointer

  .pageIndicator
    text-align: center
    margin-bottom: (40/1080*100)vh
    .circle
      background-color: $black
      border: (2/1080*100)vh solid $white
      display: inline-block;

      &:hover
        cursor: pointer
        border-color: $uiPurple
        box-shadow: 0 0 0 (2/1080*100)vh $uiPurple

      &.active
        background-color: $white

  .triggerText
    font-feature-settings: 'ss02' on;
    font-weight: 400
    font-size: $copyTrigger
    line-height: 122%

    &::v-deep(strong)
      font-weight: 600
      color: $uiPurple

@media (max-width: 750px)
  .triggerWrapper
    padding: (18/1080*100)vh
    width: (500/1080*100)vh
    height: (942/1080*100)vh

    .navWrapper
      .subTitle
        font-size: (13/16)rem
        font-weight: 600

    .illustrationWrapper
      height: (650/1080 * 100)vh
      display: flex

      .leftArrow, .rightArrow
        align-self: center
        width: (12/16)rem
        fill: $black
        stroke: $white

        &:hover
          fill: $white
          cursor: pointer

    .pageIndicator
      text-align: center
      margin-bottom: (40/1080*100)vh
      .circle
        background-color: $black
        border: (2/1080*100)vh solid $white
        display: inline-block;

        &:hover
          cursor: pointer
          border-color: $uiPurple
          box-shadow: 0 0 0 (2/1080*100)vh $uiPurple

        &.active
          background-color: $white

    .triggerText
      font-feature-settings: 'ss02' on;
      font-weight: 400
      font-size: (16/16)rem
      line-height: 115%

      &::v-deep(strong)
        font-weight: 600
        color: $uiPurple
</style>
