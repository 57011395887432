export default function createTasteprofile(tasteInput) {
  // Erstelle ein Objekt aus den Angewählten Tastes
  const tastesToKeep = Object.keys(tasteInput).filter((key) => {
    let keep = false;
    if (tasteInput[key] !== 0) keep = true;
    return keep;
  });
  // console.log(tastesToKeep);
  const tastes = {};
  tastesToKeep.forEach((key) => {
    tastes[key] = tasteInput[key];
  });

  // console.log(tastes);
  // Erstelle ein Array aus den Werten des Objekts
  const arr = Object.values(tastes);
  const max = Math.max(...arr);
  // console.log(min, max);

  // Erstelle das Geschmacksprofil Array
  const tasteArr = [];
  const keys = Object.keys(tastes);
  keys.forEach((key) => {
    // Lade die Farben
    let currentColor; let currentColorShade;
    if (key === 'sweet') {
      currentColor = '#FF546D';
      currentColorShade = '#A63747';
    } else if (key === 'sour') {
      currentColor = '#DEF25C';
      currentColorShade = '#96A343';
    } else if (key === 'salty') {
      currentColor = '#CEDEED';
      currentColorShade = '#8D9AA6';
    } else if (key === 'umami') {
      currentColor = '#733F4B';
      currentColorShade = '#4D2C33';
    } else if (key === 'fatty') {
      currentColor = '#E5DFB8';
      currentColorShade = '#ADA88C';
    }
    if (tastes[key] === max) {
      tasteArr.push({
        taste: key,
        main: true,
        color: currentColor,
        colorShade: currentColorShade,
      });
    } else {
      tasteArr.push({
        taste: key,
        main: false,
        color: currentColor,
        colorShade: currentColorShade,
      });
    }
  });
  return tasteArr;
}
