export default function createTasteText(aromaProfile) {
  // Ein Array erstellen aus allen Hauptaromen sortiert nach deren Größe
  const mainAromas = [];
  const keys = Object.keys(aromaProfile);

  let lastValue = -Infinity;
  keys.forEach((key) => {
    if (aromaProfile[key] > 5) {
      if (aromaProfile[key] > lastValue) {
        mainAromas.unshift(key);
      } else {
        mainAromas.push(key);
      }
      lastValue = aromaProfile[key];
    }
  });
  // // console.log(aromaProfile);
  // // console.log(mainAromas);
  // Je nachdem wie lang das Array ist erstelle daraus unterschiedliche Sätze
  let aromaText = '';
  if (mainAromas.length === 1) {
    aromaText = `The aroma of the dish is determined by its main ingredients and tastes especially ${mainAromas[0]}.`;
  } else if (mainAromas.length === 2) {
    aromaText = `The aroma of the dish is determined by its main ingredients and tastes especially ${mainAromas[0]} and ${mainAromas[1]}.`;
  } else {
    const veryLastAroma = mainAromas.splice(-1);
    const lastAroma = mainAromas.splice(-1);
    aromaText = `The aroma of the dish is determined by its main ingredients and tastes especially ${mainAromas.join(', ')} and ${lastAroma} with subtle ${veryLastAroma} nuances.`;
  }
  return aromaText;
}
