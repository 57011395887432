<template lang="html">
  <button class="buttonCenter" ref="button" :disabled="checkError" :class="{active: active}" @click="[active = !active, $emit('buttonClick', $event)]" @focus="$emit('focus', $event)">
    {{label}}
  </button>
</template>

<script>

export default {
  computed: {
    checkError() {
      if (this.errorButton && !this.active) return true;
      return false;
    },
  },
  mounted() {
    this.active = this.activeButton;
  },
  props: {
    label: String,
    mode: String,
    errorButton: Boolean,
    activeButton: Boolean,
  },
  data() {
    return {
      active: false,
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'

button
  box-sizing: border-box;
  width 100%;
  height: (70/16)rem
  outline: none
  padding-left: (30/16)rem
  padding-right: (30/16)rem
  border: 1px solid $white
  border-radius: $uiRadius
  font-family: 'PPPangramSansRounded'
  font-size: (20/16)rem
  font-weight: 500
  font-feature-settings: 'ss02' on;
  text-align: center
  background-color: $deepBlack
  color: $white
  transition: all 250ms ease-out

  .icon
    width: (24/16) rem
    height: @widthw

  &:hover
    cursor: pointer
    background-color: $focusBlack
  &:focus
    border-color: $white
    cursor: pointer
  &:disabled
    border-color: $gray
    color: $gray
    &:hover
      border-color: $gray
      color: $gray
      background-color: $deepBlack
      cursor: default;

  &.active
    background-color: $white
    color: $black
    &:hover
      background-color: $gray
      color: $white

</style>
