<template lang="html">
  <div class="pageWrapper">
    <div class="buttonWrapper">
      <!-- <ui-button mode="noLabel" icon="share"/> -->
      <ui-button mode="noLabel" icon="back" @buttonClick="navigateHome"/>
    </div>

    <div class="scrollStoryWrapper" ref="scrollFrame" v-if="currentDish">
      <taste-head-frame
      :dishTitle = "currentDish.title"
      :intensity="currentDish.data.intensity"
      :season="currentDish.data.season"
      :weight="currentDish.data.weight"
      :complexity="currentDish.data.complexity"
      :nationality="currentDish.data.nationality[0]"
      />
      <div class="scrollWrapper" @wheel="startScroll">
      <!-- <div class="scrollWrapper" :style="{transform: `translateX(${-moveContainerTexture}vh)`}"> -->

        <div class="scrollBanner" ref="scrollContainer" :style="{transform: `translateX(${scroll}px)`}">

          <!-- Fist Scroll Container -->

          <TasteFirstScrollContainer
          :keyIngredients="keyIngredients"
          :taste="currentDish.taste"
          @toggleTrigger="toggleTasteTrigger"
          @hoverTrigger="hoverTasteTrigger"/>

          <taste-trigger-frame
          v-if="currentDish.taste.trigger[0].title"
          :trigger="currentDish.taste.trigger"
          :tastes="mainTastes"
          class="tasteTrigger"
          :style="{opacity: `${tasteTriggerOpacity}`}"
          @buttonClick="closeTrigger('taste')"
          />

          <!-- Second Scroll Container -->

          <TasteSecondScrollContainer
          :tasteProfile="currentDish.taste.tasteProfile"
          :aroma="currentDish.aroma"
          :style="{
          marginLeft: `${moveContainerTaste}vh`,
          marginRight: `${moveContainerTexture}vh`
          }"
          />

          <taste-trigger-frame
          class="textureTrigger"
          v-if="currentDish.texture.trigger.length > 0"
          :trigger="currentDish.texture.trigger"
          :tastes="mainTastes"
          :style="{opacity: `${textureTriggerOpacity}`}"
          @buttonClick="closeTrigger('texture')"
          />

          <!-- Third Scroll Container -->

          <TasteThirdScrollContainer
          :keyTextures="keyTextures"
          :texture="currentDish.texture"
          :taste="mainTastes"
          @toggleTrigger="toggleTextureTrigger"
          @hoverTrigger="hoverTextureTrigger"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TasteTriggerFrame from '@/components/TasteTriggerFrame.vue';
import UiButton from '@/components/UiButton.vue';
import TasteHeadFrame from '@/components/TasteHeadFrame.vue';
import TasteFirstScrollContainer from '@/components/TasteFirstScrollContainer.vue';
import TasteSecondScrollContainer from '@/components/TasteSecondScrollContainer.vue';
import TasteThirdScrollContainer from '@/components/TasteThirdScrollContainer.vue';
import db from '@/db';

export default {
  components: {
    TasteTriggerFrame,
    UiButton,
    TasteHeadFrame,
    TasteFirstScrollContainer,
    TasteSecondScrollContainer,
    TasteThirdScrollContainer,
  },
  methods: {
    startScroll(e) {
      e.preventDefault();
      this.scroll -= e.deltaY;
      if (Math.abs(this.scroll) >= (this.bannerWidth - window.innerWidth + this.scrollFrameMargin)) {
        this.scroll = (this.bannerWidth - window.innerWidth + this.scrollFrameMargin) * -1;
      }
      if (this.scroll > 0) this.scroll = 0;
    },
    closeTrigger(type) {
      // // console.log((type);
      if (type === 'taste') {
        this.tasteTriggerActive = false;
        this.moveContainerTaste = this.initalTriggerWidth * -1;
        this.tasteTriggerOpacity = 0;
        this.bannerWidth -= this.triggerWidth;
        // // console.log(('close taste');
      }
      if (type === 'texture') {
        this.textureTriggerActive = false;
        this.moveContainerTexture = 0;
        this.textureTriggerOpacity = 0;
        this.bannerWidth -= this.triggerWidth;
        if (Math.abs(this.scroll) >= (this.bannerWidth - window.innerWidth + this.scrollFrameMargin)) {
          this.scroll = (this.bannerWidth - window.innerWidth + this.scrollFrameMargin) * -1;
        }
        this.$refs.scrollContainer.classList.add('move');
        setTimeout(() => { this.$refs.scrollContainer.classList.remove('move'); }, 350);
      }
    },
    toggleTasteTrigger(data) {
      // // console.log((this.bannerWidth);
      if (data || !this.tasteTriggerActive) {
        this.moveContainerTaste = 0;
        this.tasteTriggerOpacity = 1;
        this.tasteTriggerActive = true;
        this.bannerWidth += this.triggerWidth;
      } else {
        this.moveContainerTaste = this.initalTriggerWidth * -1;
        this.tasteTriggerOpacity = 0;
        this.tasteTriggerActive = false;
        this.bannerWidth -= this.triggerWidth;
      }
      // // console.log((this.bannerWidth);
      // // console.log((data);
    },
    hoverTasteTrigger(hover) {
      // // console.log((hover);
      if (hover && this.tasteTriggerActive) {
        // this.moveContainerTaste = -10;
        this.tasteTriggerOpacity = 0.3;
      } else if (!hover && this.tasteTriggerActive) {
        this.moveContainerTaste = 0;
        this.tasteTriggerOpacity = 1;
      } else if (hover && !this.tasteTriggerActive) {
        this.moveContainerTaste = this.initalTriggerWidth * -1 + 10;
        this.tasteTriggerOpacity = 0.3;
      } else {
        this.moveContainerTaste = this.initalTriggerWidth * -1;
        this.tasteTriggerOpacity = 0;
      }
    },
    toggleTextureTrigger(data) {
      if (data || !this.textureTriggerActive) {
        this.moveContainerTexture = this.initalTriggerWidth;
        this.textureTriggerOpacity = 1;
        this.textureTriggerActive = true;
        this.bannerWidth += this.triggerWidth;
      } else {
        this.moveContainerTexture = 0;
        this.textureTriggerOpacity = 0;
        this.textureTriggerActive = false;
        this.bannerWidth -= this.triggerWidth;
        // Check if the current Scrollposition is out of Range after the Trigger toggles and move accodingly
        if (Math.abs(this.scroll) >= (this.bannerWidth - window.innerWidth + this.scrollFrameMargin)) {
          this.scroll = (this.bannerWidth - window.innerWidth + this.scrollFrameMargin) * -1;
        }
        this.$refs.scrollContainer.classList.add('move');
        setTimeout(() => { this.$refs.scrollContainer.classList.remove('move'); }, 350);
      }
      // // console.log((data);
    },
    hoverTextureTrigger(hover) {
      // // console.log((hover);
      if (hover && this.textureTriggerActive) {
        // this.moveContainerTexture = (this.initalTriggerWidth - 10);
        // this.bannerWidth -= ((10 * window.innerHeight) / 100);
        this.textureTriggerOpacity = 0.3;
      } else if (!hover && this.textureTriggerActive) {
        this.moveContainerTexture = this.initalTriggerWidth;
        this.textureTriggerOpacity = 1;
        // this.bannerWidth += ((10 * window.innerHeight) / 100);
      } else if (hover && !this.textureTriggerActive) {
        this.moveContainerTexture = 10;
        this.textureTriggerOpacity = 0.3;
      } else {
        this.moveContainerTexture = 0;
        this.textureTriggerOpacity = 0;
      }
    },
    navigateHome() {
      // console.log('home');
      if (this.$route.name === 'Home') return;
      this.$router.push({ name: 'Home' });
    },
    resizeBanner() {
      this.$store.commit('setWindowWidth', window.innerWidth);
      this.scroll = 0;
      let firstGroupWidth; let thirdGroupWidth; let secondGroupWidth;
      if (window.innerWidth < 750) {
        this.initalTriggerWidth = 46.3;
        this.triggerWidth = 46.3;
        if (this.keyIngredients.length > 2) {
          firstGroupWidth = 1410;
        } else {
          firstGroupWidth = 940;
        }
        if (this.keyTextures.length > 2) {
          thirdGroupWidth = 1410;
        } else {
          thirdGroupWidth = 940;
        }
      } else {
        this.initalTriggerWidth = 67.69;
        this.trigger = 67.69;
        if (this.keyIngredients.length > 2) {
          firstGroupWidth = 1882;
        } else {
          firstGroupWidth = 1410;
        }

        if (this.keyTextures.length > 2) {
          thirdGroupWidth = 1882;
        } else {
          thirdGroupWidth = 1410;
        }
      }
      // Claculate inner Scrollbanner Depending on Elements in Scrollstory
      if (this.currentDish.taste.trigger[0].title) {
        // Close all Triggers
        this.moveContainerTaste = this.initalTriggerWidth * -1;
        this.tasteTriggerOpacity = 0;
        this.tasteTriggerActive = false;
        this.textureTriggerActive = false;
        this.moveContainerTexture = 0;
        this.textureTriggerOpacity = 0;
        if (window.innerWidth < 750) {
          secondGroupWidth = 998;
        } else {
          secondGroupWidth = 1544;
        }
        this.bannerWidth = ((((firstGroupWidth + secondGroupWidth + thirdGroupWidth) / 1080) * 100) * window.innerHeight) / 100;
        // Rand des Frames Rechts und links
        this.scrollFrameMargin = (window.innerWidth - this.$refs.scrollFrame.offsetWidth);
        // Breite eines Trigger
        this.triggerWidth = ((this.initalTriggerWidth * window.innerHeight) / 100);
        // // console.log((this.bannerWidth, this.scrollFrameMargin, this.triggerWidth);
      } else {
        if (window.innerWidth < 750) {
          secondGroupWidth = 1000;
        } else {
          secondGroupWidth = 1542;
        }
        this.bannerWidth = ((((firstGroupWidth + secondGroupWidth + thirdGroupWidth) / 1080) * 100) * window.innerHeight) / 100;
        // Rand des Frames Rechts und links
        this.scrollFrameMargin = (window.innerWidth - this.$refs.scrollFrame.offsetWidth);
      }
    },
    getIngredientData(ingredientName) {
      return this.$store.state.ingredients.get(ingredientName);
    },
  },
  watch: {
    currentDish(newValue) {
      this.$nextTick(() => {
        if (newValue !== null) {
          // get the width of all Elements, that calculate the width of the Scrollbanner
          // Inneres scrollBanner
          this.bannerWidth = this.$refs.scrollContainer.offsetWidth;
          // Rand des Frames Rechts und links
          this.scrollFrameMargin = (window.innerWidth - this.$refs.scrollFrame.offsetWidth);
          // Breite eines Trigger
          this.triggerWidth = ((this.initalTriggerWidth * window.innerHeight) / 100);
          // // console.log((this.triggerWidth);
        }
      });
    },
  },
  mounted() {
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeBanner);
  },
  async created() {
    // Lade das akuelle Gericht aus dem $store
    try {
      // // // console.log(typeof this.$route.params.index);
      // // // console.log(await db.dishes.get(33));
      this.currentDish = await db.dishes.get(Number.parseInt(this.$route.params.index, 10));
    } catch (err) {
      // console.log(`Could not get data: ${err.message}`);
    }
    // // console.log(JSON.stringify(this.currentDish));
    // Add Resize Event Listner to set width of the Banner
    window.addEventListener('resize', this.resizeBanner);
    // Setze den Trigger für Mobil oder Desktop:
    if (window.innerWidth < 750) {
      this.initalTriggerWidth = 46.3;
      this.triggerWidth = ((this.initalTriggerWidth * window.innerHeight) / 100);
    } else {
      this.initalTriggerWidth = 67.69;
      this.triggerWidth = ((this.initalTriggerWidth * window.innerHeight) / 100);
    }
    // Check wheater there is a Taste Trigger or not and move ScrollContainer accordingly
    if (this.currentDish.taste.trigger[0].title) {
      this.moveContainerTaste = this.initalTriggerWidth * -1;
    } else {
      this.moveContainerTaste = 0;
    }

    // Check how many Ingredients have priority and create new Array from it
    for (let i = 0; i < this.currentDish.taste.ingredients.length; i += 1) {
      if (this.currentDish.taste.ingredients[i].main) {
        this.keyIngredients.push(this.currentDish.taste.ingredients[i]);
        // // console.log((this.getIngredientData(this.currentDish.taste.ingredients[i].title).taste);
        // // console.log((this.currentDish.taste.ingredients[i].taste);
        this.mainTastes.push(this.getIngredientData(this.currentDish.taste.ingredients[i].title).taste);
      }
    }
    // Check how many textures have priority and create new Array
    for (let i = 0; i < this.currentDish.texture.textures.length; i += 1) {
      if (this.currentDish.texture.textures[i].main) {
        const element = {};
        element.title = this.currentDish.texture.textures[i].title;

        // Assign the main colors of main Ingredients
        // If there are less keyIngredients than Textures
        // take a random Taste from the Key tastes

        if (i > this.mainTastes.length - 1) {
          const randomIngredientIndex = Math.round(Math.random(this.mainTastes.length - 1));
          element.taste = this.mainTastes[randomIngredientIndex];
        } else {
          element.taste = this.mainTastes[i];
        }
        this.keyTextures.push(element);
      }
    }

    // // console.log((this.keyTextures);
    // // console.log((this.keyIngredients);
  },
  data() {
    return {
      startY: 0,
      initalTriggerWidth: 67.69,
      currentDish: null,
      scrollFrameMargin: 0,
      bannerWidth: 0,
      triggerWidth: 0,
      scroll: 0,
      moveContainerTaste: -67.69,
      moveContainerTexture: 0,
      tasteTriggerOpacity: 0,
      textureTriggerOpacity: 0,
      tasteTriggerActive: false,
      textureTriggerActive: false,
      keyIngredients: [],
      keyTextures: [],
      mainTastes: [],
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.pageWrapper
  position: absolute
  top: 0
  left: 0
  right:0
  bottom: 0
  display: flex
  justify-content: center
  align-items: center
  background-color: $white

  .buttonWrapper
    display: flex
    position: fixed
    right: 2%
    bottom: 1%
    z-index: 2
    .button
      background-color: $uiPurple
      margin-left: (10/16)rem
      box-shadow: 1px 6px 6px rgba(0, 0, 0, 0.15);
      &:hover
        background-color: $uiPurpleHover
  .scrollStoryWrapper
    width: (1876/1920*100)vw
    height: (1010/1080*100)vh
    background-color: $black
    border-radius: $tileRadiusDesktop
    box-shadow: 0 0 0 (2/1080*100)vh $black;
    overflow-y: hidden
    overflow-x: scroll
    .headBar
      position: fixed

    .scrollWrapper
      transition: transform 350ms ease-out
      position: relative

      .scrollBanner
        // Move down the ammount of Head Bars height
        margin-top: (68/1080*100)vh
        display: flex
        flex-direction: row;
        height:(942/1080*100)vh
        z-index: 1
        position: absolute;

        &.move
          transition: transform 350ms ease-out

      .tasteTrigger
        transition: opacity 350ms ease-out
        z-index: 0

      .secondScrollGroup
        margin-left: (-(733 - 2)/1080*100)vh
        transition: margin 350ms ease-out

      .textureTrigger
        margin-left: (-(733 - 2)/1080*100)vh
        transition: opacity 350ms ease-out

      .secondScrollGroup
        z-index: 1
@media (max-width: 750px)
  .pageWrapper
    position: fixed
    overflow: none
    top: 0
    left: 0
    right:0
    bottom: 0
    display: flex
    justify-content: center
    align-items: center
    background-color: $white

    .buttonWrapper
      display: flex
      position: fixed
      right: 2%
      bottom: 1%
      z-index: 3
      .button
        background-color: $uiPurple
        margin-left: (10/16)rem
        box-shadow: 1px 6px 6px rgba(0, 0, 0, 0.15);
        &:hover
          background-color: $uiPurpleHover
    .scrollStoryWrapper
      position: fixed
      width: (1876/1920*100)vw
      height: (1010/1080*100)vh
      background-color: $black
      border-radius: $tileRadiusDesktop
      box-shadow: 0 0 0 (2/1080*100)vh $black;
      overflow-y: hidden
      overflow-x: scroll
      .headBar
        position: fixed

      .scrollWrapper
        transition: transform 350ms ease-out
        position: relative

        .scrollBanner
          // Move down the ammount of Head Bars height
          margin-top: (68/1080*100)vh
          display: flex
          flex-direction: row;
          height:(942/1080*100)vh
          z-index: 1
          position: absolute;

          &.move
            transition: transform 350ms ease-out

        .tasteTrigger
          transition: opacity 350ms ease-out
          z-index: 0

        .secondScrollGroup
          margin-left: (-(500 - 2)/1080*100)vh
          transition: margin 350ms ease-out

        .textureTrigger
          margin-left: (-(500 - 2)/1080*100)vh
          transition: opacity 350ms ease-out

        .secondScrollGroup
          z-index: 1
</style>
