<template lang="html">
  <div class="aromaFrame">
    <p class="subTitle">Aroma Overview</p>
    <div class="aromaWrapper">
      <taste-aroma-graph
      v-for="aroma in aromaArray"
      :key="aroma.title"
      class="graph"
      :intensity="aroma.intensity"
      :aroma="aroma.title"
      :mainAroma="aroma.mainAroma"/>
    </div>
  </div>
</template>

<script>
import TasteAromaGraph from '@/components/TasteAromaGraph.vue';

export default {
  components: {
    TasteAromaGraph,
  },
  mounted() {
    // Konvertiere das Object in ein Array aus Objekten
    const keys = Object.keys(this.aromaProfile);
    if (window.innerWidth < 750) {
      const arr = [];
      let highestIndex = 0;
      keys.forEach((key) => {
        if (this.aromaProfile[key] > highestIndex) {
          arr.unshift({
            title: key,
            intensity: this.aromaProfile[key],
            mainAroma: this.aromaProfile[key] >= 5,
          });
          highestIndex = this.aromaProfile[key];
        } else {
          arr.push({
            title: key,
            intensity: this.aromaProfile[key],
            mainAroma: this.aromaProfile[key] >= 5,
          });
        }
        this.aromaArray = arr.slice(0, 8);
      });
    } else {
      keys.forEach((key) => {
        this.aromaArray.push({
          title: key,
          intensity: this.aromaProfile[key],
          mainAroma: this.aromaProfile[key] >= 5,
        });
      });
      // console.log(this.aromaArray);
    }
  },
  watch: {
    windowWidth(newValue) {
      // console.log(newValue);
      this.aromaArray = [];
      const keys = Object.keys(this.aromaProfile);
      if (newValue < 750) {
        const arr = [];
        let highestIndex = 0;
        keys.forEach((key) => {
          if (this.aromaProfile[key] > highestIndex) {
            arr.unshift({
              title: key,
              intensity: this.aromaProfile[key],
              mainAroma: this.aromaProfile[key] >= 5,
            });
            highestIndex = this.aromaProfile[key];
          } else {
            arr.push({
              title: key,
              intensity: this.aromaProfile[key],
              mainAroma: this.aromaProfile[key] >= 5,
            });
          }
          this.aromaArray = arr.slice(0, 8);
        });
      } else {
        keys.forEach((key) => {
          this.aromaArray.push({
            title: key,
            intensity: this.aromaProfile[key],
            mainAroma: this.aromaProfile[key] >= 5,
          });
        });
        // console.log(this.aromaArray);
      }
    },
  },
  computed: {
    windowWidth() {
      // console.log(this.$store.state.windowWidth);
      return this.$store.state.windowWidth;
    },
  },
  props: {
    aromaProfile: Object,
  },
  data() {
    return {
      aromaArray: [],
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'

.aromaFrame
  display: inline-flex
  flex-direction: column;
  width: (942/1080 * 100)vh
  height: (470/1080 * 100)vh
  font-feature-settings: 'ss02' on;
  background-color: $white
  box-shadow: 0 0 0 (2/1080*100)vh $black
  border-radius: $tileRadiusDesktop

  .subTitle
    padding: (14/1080*100)vh
    padding-top: (18/1080*100)vh
    font-size: $subHead
    font-weight: 600
    color: $gray
  .aromaWrapper
    padding: (14/1080*100)vh
    padding-top: (18/1080*100)vh
    display: grid
    width: 100%
    grid-template-columns: repeat(8, (95/1080 * 100)vh)
    grid-template-rows: repeat(2,(181/1080 * 100)vh)
    grid-row-gap: (20/1080 * 100)vh
    justify-content: space-between;
    .graph
      box-shadow: none;
      width: (92/1080 * 100)vh
      height: (181/1080 * 100)vh

@media (max-width: 750px)
  .aromaFrame
    display: inline-flex
    flex-direction: column;
    width: (470/1080 * 100)vh
    height: (470/1080 * 100)vh
    font-feature-settings: 'ss02' on;
    background-color: $white
    box-shadow: 0 0 0 (2/1080*100)vh $black
    border-radius: $tileRadiusDesktop

    .subTitle
      padding: (14/1080*100)vh
      padding-top: (18/1080*100)vh
      font-size: (13/16)rem
      font-weight: 600
      color: $gray
    .aromaWrapper
      padding: (18/1080*100)vh
      padding-top: (18/1080*100)vh
      display: grid
      width: 100%
      grid-template-columns: repeat(4, (95/1080 * 100)vh)
      grid-template-rows: repeat(2,(181/1080 * 100)vh)
      grid-row-gap: (23/1080 * 100)vh
      justify-content: space-between;
      .graph
        box-shadow: none;
        border: 1px solid $white
        width: (92/1080 * 100)vh
        height: (175/1080 * 100)vh
      .marignals
        font-size: (10/16)rem

</style>
