<template lang="html">
  <div class="contentElement" @click="$emit('removeItem')">
    <p>{{label}}</p>
    <ui-icons icon="delete"/>
  </div>
</template>

<script>
import UiIcons from '@/components/UiIcons.vue';

export default {
  props: {
    label: String,
  },
  components: {
    UiIcons,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'

.contentElement
  padding: (7/16)rem (13/16)rem  (7/16)rem  (15/16)rem
  height: (48/16)rem
  width: 100%
  background-color: $focusBlack
  color: $white
  display: flex
  align-items: center
  justify-content: space-between;
  // text-transform: capitalize;
  border-radius: (5/16)rem
  transition: all 150ms ease-out
  &:hover
    color: $uiError
    cursor: pointer
    background-color: $black

  p
    width: 100%
    font-family: 'PPPangramSansRounded'
    font-size: (16/16)rem
    font-weight: 500
    font-feature-settings: 'ss02' on;
    &:first-letter
      text-transform: uppercase;

  .icon
    flex-shrink: 0
    height: (30/1080*100)vh
</style>
