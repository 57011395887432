<template lang="html">
  <div class="backTileWrapper">
    <div class="headWrapper">
      <h2>{{texture.title}}</h2>
      <taste-button  :label="buttonType === 'cta' ? 'Close': ''" :mode="buttonType" icon="close" @buttonClick="$emit('buttonClick', $event)"/>
    </div>
    <div class="contentWrapper">
      <div class="elementFrame" :class="taste">
        <img :src="`${require(`@/assets/texture/square/${texture.title}.png`)}`" :alt="texture">
      </div>
      <div class="database">
        <p>{{texture.category}}</p>
        <p>{{`Bio.: ${texture.bio}`}}</p>
        <p>{{`Contrast: ${texture.contrast.join(', ')}`}}</p>
        <!-- <p>{{`Ingredients: ${texture.ingredients.join(', ')}`}}</p> -->
      </div>
    </div>
    <p>Related Ingredients:</p>
    <div class="ingredientWrapper">
      <div class="ingredientTextWrapper">
        <Taste-ingredient :width="10" :ingredient="texture.ingredients[0]" mode="outlined" strokeColor="#E5DFB8"/>
        <p>{{texture.ingredients[0]}}</p>
      </div>
      <div class="ingredientTextWrapper">
        <Taste-ingredient :width="10" :ingredient="texture.ingredients[1]" mode="outlined" strokeColor="#CEDEED"/>
        <p>{{texture.ingredients[1]}}</p>
      </div>
      <div class="ingredientTextWrapper">
        <Taste-ingredient :width="10" :ingredient="texture.ingredients[2]" mode="outlined" strokeColor="#FF546D"/>
        <p>{{texture.ingredients[2]}}</p>
      </div>
      <div class="ingredientTextWrapper">
        <Taste-ingredient :width="10" :ingredient="texture.ingredients[3]" mode="outlined" strokeColor="#E5DFB8"/>
        <p>{{texture.ingredients[3]}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import TasteButton from '@/components/TasteButton.vue';
import TasteIngredient from '@/components/TasteIngredient.vue';

export default {
  components: {
    TasteButton,
    TasteIngredient,
  },
  mounted() {
    this.buttonType = 'cta';
    if (window.innerWidth < 750) this.buttonType = 'noLabel';
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    texture() {
      return this.$store.state.textures.get(this.textureId);
    },
  },
  watch: {
    windowWidth(newValue) {
      if (newValue < 750) {
        this.buttonType = 'noLabel';
      } else {
        this.buttonType = 'cta';
      }
    },
  },
  methods: {
    ingredientTitle(ingredientId) {
      // // console.log(ingredientId);
      // // console.log(this.$store.state.ingredients.get('almond').ingredient);
      return this.$store.state.ingredients.get(ingredientId).ingredient;
    },
  },
  props: {
    textureId: String,
    taste: String,
    // ingredients: Array,
  },
  data() {
    return {
      buttonType: 'cta',
      ingredients: ['butter', 'asparagus', 'vinegar', 'butter'],
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.backTileWrapper
  height: (470/1080*100)vh
  width: (470/1080*100)vh
  display: flex
  flex-direction: column
  background-color: $black
  padding: (23/1080*100)vh
  border-radius: $tileRadiusDesktop
  color: $white
  text-align: left
  p
    font-size: (14/1080*100)vh
    font-weight: 500
    text-transform: capitalize;
  .headWrapper
      display: flex
      flex-direction: row
      justify-content: space-between;
      color: $white
    h2
      display: block;
      flex-shrink: 1
      text-overflow: ellipsis
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden
  .ingredientWrapper
    display: flex
    height: (130/1080 * 100)vh
    margin-top: (10/1080 * 100)vh
    .ingredientTextWrapper
      .ingredient
        height: (100/1080*100)vh
      p
        margin-top: (10/1080 * 100)vh
        text-align: center

  .contentWrapper
    margin-top: (22/1080*100)vh
    margin-bottom: (18/1080*100)vh
    display: flex;

    .elementFrame
      width: (182/1080*100)vh
      height: @width
      background-image: url('../assets/p/wiki.png');
      border: 2px solid
      img
        width: 100%
        height: 100%
      &.sweet
        border-color: $sweetRed
      &.umami
        border-color: $umamiPurple
      &.fatty
        border-color: $fattyYellow
      &.salty
        border-color: $saltyBlue
      &.sour
        border-color: $sourGreen

    .database
      text-align: left
      color: $white
      font-family: 'PPPangramSansRounded'
      font-feature-settings: 'ss02' on;
      margin-left: (33/1080*100)vh
      width:  40%
      height: (182/1080*100)vh
      overflow: hidden
      p
        font-size: $subHead
        letter-spacing: 0.02em;
        font-weight: 600
        margin-bottom: (10/1080*100)vh

@media (max-width: 750px)
  .backTileWrapper
    height: (470/1080*100)vh
    width: (470/1080*100)vh
    display: flex
    flex-direction: column
    background-color: $black
    padding: (23/1080*100)vh
    border-radius: $tileRadiusDesktop
    color: $white
    text-align: left
    p
      font-size: (14/1080*100)vh
      font-weight: 500
      text-transform: capitalize;
    .headWrapper
        display: flex
        flex-direction: row
        justify-content: space-between;
        color: $white
      h2
        font-size: (23/16)rem
        font-weight: 500
        display: block;
        flex-shrink: 1
        text-overflow: ellipsis
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden
    .ingredientWrapper
      display: flex
      height: (130/1080 * 100)vh
      margin-top: (10/1080 * 100)vh
      .ingredientTextWrapper
        .ingredient
          height: (100/1080*100)vh
        p
          margin-top: (10/1080 * 100)vh
          text-align: center

    .contentWrapper
      margin-top: (22/1080*100)vh
      margin-bottom: (18/1080*100)vh
      display: flex;

      .elementFrame
        width: (182/1080*100)vh
        height: @width
        background-image: url('../assets/p/wiki.png');
        border: 2px solid
        img
          width: 100%
          height: 100%
        &.sweet
          border-color: $sweetRed
        &.umami
          border-color: $umamiPurple
        &.fatty
          border-color: $fattyYellow
        &.salty
          border-color: $saltyBlue
        &.sour
          border-color: $sourGreen

      .database
        text-align: left
        color: $white
        font-family: 'PPPangramSansRounded'
        font-feature-settings: 'ss02' on;
        margin-left: (33/1080*100)vh
        width:  40%
        height: (182/1080*100)vh
        overflow: hidden
        p
          font-size: (12/16)rem
          letter-spacing: 0.02em;
          font-weight: 600
          margin-bottom: (5/1080*100)vh
</style>
