<template lang="html">
  <div class="dropDown">
    <Ui-Button
    :label="currentUnit"
    mode="dark"
    :icon="isOpen ? 'dropdownUp' : 'dropdown'"
    @buttonClick="isOpen = !isOpen"
    @focus="isOped = true"
    @keydown.down="onArrowDown"
    @keydown.up="onArrowUp"
    @keydown.enter="onEnter"/>
    <transition name="openDropdown">
      <ul class="menuWrapper" v-show="isOpen">
        <li
        class="menuItem"
        v-for ="(content, index) in contentElements"
        :key= "index"
        :class="{ 'isActive': index === arrowCounter }"
        @click ="setContent(index)"
        @mouseover = " arrowCounter = -1"
        >
          {{content}}
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import UiButton from '@/components/UiButton.vue';

export default {
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  components: {
    UiButton,
  },
  props: {
    contentElements: Array,
  },
  methods: {
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
    onArrowDown(event) {
      event.preventDefault();
      if (!this.isOpen) { this.isOpen = true; }
      if (this.arrowCounter < this.contentElements.length - 1) {
        this.arrowCounter += 1;
        this.currentUnit = this.contentElements[this.arrowCounter];
      } else {
        this.arrowCounter = this.contentElements.length - 1;
      }
    },
    onArrowUp(event) {
      event.preventDefault();
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.currentUnit = this.contentElements[this.arrowCounter];
      }
    },
    onEnter() {
      // console.log('ENTER');
      if (this.arrowCounter === -1) {
        this.arrowCounter = 0;
      }
      this.currentUnit = this.contentElements[this.arrowCounter];
      this.isOpen = false;
      this.$emit('changeUnit', this.arrowCounter);
      this.arrowCounter = -1;
    },
    setContent(index) {
      this.currentUnit = this.contentElements[index];
      this.$emit('changeUnit', index);
      this.isOpen = false;
    },
  },
  data() {
    return {
      currentUnit: 'pc.',
      isOpen: false,
      arrowCounter: -1,
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'

.dropdown
  .button
    height: 100%
    width: 100%

  .menuWrapper
    width: 100%
    position: relative;
    padding:0;
    margin: 0;
    z-index: 5
    border: 1px solid $white;
    border-radius: 5px
    min-height: 1em;
    max-height: 14em;
    margin-top: (10/16)rem
    overflow: auto;
    background-color: $black
    display: block

    .menuItem
      list-style: none;
      text-align: left;
      padding: 8px 0;
      cursor: pointer;
      text-indent:  (15/1920*100)vw
      background-color: $focusBlack
      margin-bottom: (3/16)rem
      font-family: 'PPPangramSansRounded'
      font-size: (16/16)rem
      font-weight: 500
      font-feature-settings: 'ss02' on;

      &.isActive
        background-color: $uiPurple;
        color: white;

      &:hover
        background-color: $black;
        color: white;

.openDropdown-enter-active,
.openDropdown-leave-active
  transition: opacity 200ms ease-out, transform 150ms ease-out

.openDropdown-enter-from,
.openDropdown-leave-to
  opacity: 0
  transform: scaleY(0.5) translateY(-60%);

.openDropdown-enter-to,
.openDropdown-leave-from
  opacity: 1
  transform: scaleY(1) translateY(0);

</style>
