<template>
  <div class="home">
    <div class="headWrapper">
      <ui-progress-head :title="getTitle" :currentScreen="currentPage" :screens="pages - 1"/>
      <div class="rightWrapper">
        <Ui-Button @buttonClick="visibleModal=true" :label="buttonType === 'dark' ? 'Get Help': ''" :mode="buttonType" icon="help"/>
        <ui-modal @close="visibleModal=false" :visible="visibleModal" :title="getInfoTitle" confirm="Continue editing" :text="getInfo"/>
        <Ui-Button @buttonClick="handleClose" :label="buttonType === 'dark' ? 'Close' : ''" :mode="buttonType" icon="close"/>
        <!-- <ui-toasty @decline="navigateHome" @confirm="visibleToast=false" :visible="visibleToast" confirm="Continue" decline="Discard" text="Do you realy want to stop editing? All your changes will be discarded"/> -->
      </div>
    </div>
    <div v-if="!finished && onload" class="content">
      <div class="pageWrapper" v-if="!mobile">
        <transition mode="out-in" :name="direction === 'right' ? 'pageFlipRight' : 'pageFlipLeft'">
          <div class="contentPage"  v-if="currentPage === 0">
            <div class="groupWrapper">
              <FormTitle :titleInput="dishDataOutput.title" @output="handleTitleOutput" :errorMessage="titleError"/>
              <FormIngredients :ingredientIn="dishDataOutput.taste.ingredients" @output="handleIngredientOutput"  :errorMessage="ingredientError"/>
            </div>
            <div class="groupWrapper">
              <FormPreview :previewIn="dishDataOutput.preview" @outputCol="handlePreviewCol" @outputUrl="handlePreviewUrl"/>
              <FormNationality :nationalityIn = "dishDataOutput.data.nationality" @output="handleNationalityOutput"  :errorMessage="nationalityError"/>
            </div>
          </div>
          <div class="contentPage"  v-else-if="currentPage === 1">
            <FormTaste v-if="currentPage === 1" :tasteProfileIn="dishDataOutput.tasteIntensities" @output="handleTasteOutput"  :errorMessage="tasteprofileError"/>
            <FormTexture v-if="currentPage === 1" :texturesIn ="dishDataOutput.texture.textures" @output="handleTextureOutput"  :errorMessage="mouthfeelError"/>
          </div>

          <div class="contentPage"  v-else-if="currentPage === 2">
            <FormKeyIngredients :ingredients="dishDataOutput.taste.ingredients" v-if="currentPage === 2" @output="handleKeyIngredientOutput" :errorMessage="keyIngredientsError"/>
            <FormKeyTextures :textures="dishDataOutput.texture.textures" v-if="currentPage === 2" @output="handleKeyTextureOutput" :errorMessage="keyTexturesError"/>
          </div>
        </transition>

      </div>

      <div class="pageWrapper" v-else-if="mobile">
        <transition mode="out-in" :name="direction === 'right' ? 'pageFlipRight' : 'pageFlipLeft'">
          <div class="groupWrapper" v-if="currentPage === 0">
            <FormTitle :titleInput="dishDataOutput.title" @output="handleTitleOutput" :errorMessage="titleError"/>
            <FormIngredients :ingredientIn="dishDataOutput.taste.ingredients" @output="handleIngredientOutput" :errorMessage="ingredientError"/>
          </div>
          <div class="groupWrapper" v-else-if="currentPage === 1">
            <FormPreview :previewIn="dishDataOutput.preview" @outputCol="handlePreviewCol" @outputUrl="handlePreviewUrl"/>
            <FormNationality :nationalityIn = "dishDataOutput.data.nationality" @output="handleNationalityOutput" :errorMessage="nationalityError"/>
          </div>
          <FormTaste v-else-if="currentPage === 2" :tasteProfileIn="dishDataOutput.tasteIntensities" @output="handleTasteOutput" :errorMessage="tasteprofileError"/>
          <FormTexture v-else-if="currentPage === 3" :texturesIn ="dishDataOutput.texture.textures" @output="handleTextureOutput" :errorMessage="mouthfeelError"/>
          <FormKeyIngredients :ingredients="dishDataOutput.taste.ingredients" v-else-if="currentPage === 4" @output="handleKeyIngredientOutput" :errorMessage="keyIngredientsError"/>
          <FormKeyTextures :textures="dishDataOutput.texture.textures" v-else-if="currentPage === 5" @output="handleKeyTextureOutput" :errorMessage="keyTexturesError"/>
        </transition>
      </div>
      <div class="buttonNavigation">
        <Ui-Button v-if="currentPage > 0" @buttonClick="[currentPage -= 1, direction = 'left']" label="Previous Step" mode="dark" icon="previous"/>
        <Ui-Button :style="{marginLeft: currentPage > 0 ? '1rem' : '0'}" v-if="currentPage < pages -1 " @buttonClick="handleClick" label="Next Step" mode="cta" icon="next"/>
        <Ui-Button  class="finish" v-if="currentPage === pages -1 " @buttonClick="handleCreateDish" label="Create Dish" mode="cta" icon="finish"/>
      </div>
    </div>
    <transition name ="scrollPage">
      <div v-if="finished" class="finishDish">
        <div class="content">
          <div class="leftWrapper">
            <p class="head">Congratulations, you created a new dish!</p>
            <p class="copy">{{`»${dishDataOutput.title}« has been added to your dashboard. You can visit it now or later using the dasboard.`}}</p>
            <div class="buttonWrapper">
              <Ui-Button @buttonClick="navigateHome" label="Back to dashboard" mode="light" icon=""/>
              <Ui-Button @buttonClick="navigateDish" label="Explore creation" mode="cta" icon="finish"/>
            </div>
          </div>
          <div class="illustrationWrapper">
            <TasteTrigger class="illustration" trigger="finish"/>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src

import db from '@/db';
import { cloneDeep } from 'lodash-es';

import UiButton from '@/components/UiButton.vue';
import UiProgressHead from '@/components/UiProgressHead.vue';
import FormIngredients from '@/components/FormIngredients.vue';
import FormNationality from '@/components/FormNationality.vue';
// import UiToasty from '@/components/UiToasty.vue';
import UiModal from '@/components/UiModal.vue';
import FormTexture from '@/components/FormTexture.vue';
import FormTaste from '@/components/FormTaste.vue';
import FormTitle from '@/components/FormTitle.vue';
import FormPreview from '@/components/FormPreview.vue';
import FormKeyIngredients from '@/components/FormKeyIngredients.vue';
import FormKeyTextures from '@/components/FormKeyTextures.vue';
import TasteTrigger from '@/components/TasteTrigger.vue';

import content from '../assets/data/content';
import calculateWeight from '../assets/scripts/calculateWeight';
import calculateIntensity from '../assets/scripts/calculateIntensity';
import calculateSeason from '../assets/scripts/calculateSeason';
import createTasteprofile from '../assets/scripts/createTasteprofile';
import createAromaProfile from '../assets/scripts/createAromaProfile';
import findTasteTrigger from '../assets/scripts/findTasteTrigger';
import findTextureTrigger from '../assets/scripts/findTextureTrigger';
import createTasteText from '../assets/scripts/createTasteText';
import createAromaText from '../assets/scripts/createAromaText';
import createTextureText from '../assets/scripts/createTextureText';

// function nextBiggest(arr) {
//   let max = -Infinity; let result = -Infinity;
//   arr.forEach((nr) => {
//     if (nr > max) {
//       [result, max] = [max, nr]; // save previous max
//     } else if (nr < max && nr > result) {
//       result = nr; // new second biggest
//     }
//   });
//   return result;
// }

export default {
  name: 'Home',
  components: {
    UiButton,
    UiProgressHead,
    FormIngredients,
    FormNationality,
    // UiToasty,
    UiModal,
    FormTexture,
    FormTaste,
    FormTitle,
    FormPreview,
    FormKeyIngredients,
    FormKeyTextures,
    TasteTrigger,
  },
  async created() {
    // Wenn es ein Gericht zu bearbeiten gibt
    // // console.log(this.$route.params.index !== 'new');
    if (this.$route.params.index !== 'new') {
      try {
        this.dishDataOutput = await db.dishes.get(Number.parseInt(this.$route.params.index, 10));
      } catch (err) {
        // console.log(`Could not get data: ${err.message}`);
      }
      this.onload = true;
      // // console.log(this.dishDataOutput);
      // // console.log(this.dishDataOutput.tasteIntensities);
      this.forms.title.error = false;
      this.forms.ingredients.error = false;
      this.forms.nationality.error = false;
      this.forms.tasteprofile.error = false;
      this.forms.mouthfeel.error = false;
      this.forms.keyingredients.error = false;
      this.forms.keytextures.error = false;
    } else {
      this.onload = true;
    }
  },
  computed: {
    getTitle() {
      if (this.mobile) return this.content.create.titles.mobile[this.currentPage].title;
      return this.content.create.titles.desktop[this.currentPage].title;
    },
    getInfoTitle() {
      if (this.mobile) return this.content.create.titles.mobile[this.currentPage].infoTitle;
      return this.content.create.titles.desktop[this.currentPage].infoTitle;
    },
    getInfo() {
      if (this.mobile) return this.content.create.titles.mobile[this.currentPage].info;
      return this.content.create.titles.desktop[this.currentPage].info;
    },
  },
  data() {
    return {
      onload: false,
      finished: false,
      dishId: 0,
      forms: {
        title: {
          error: true,
          errorMessage: 'You need to add the title of your dish',
        },
        ingredients: {
          error: true,
          errorMessage: 'You need to add at least two ingredients',
        },
        nationality: {
          error: true,
          errorMessage: 'You need to add a nationality',
        },
        tasteprofile: {
          error: true,
          errorMessage: 'You need to define your dishes taste',
        },
        mouthfeel: {
          error: true,
          errorMessage: 'You need to define your dishes mouthfeel',
        },
        keyingredients: {
          error: true,
          errorMessage: 'You need to define at least two key ingredients',
        },
        keytextures: {
          error: true,
          errorMessage: 'You need to define your dishes key textures',
        },
      },
      titleError: '',
      ingredientError: '',
      nationalityError: '',
      tasteprofileError: '',
      mouthfeelError: '',
      keyIngredientsError: '',
      keyTexturesError: '',
      content,
      direction: '',
      dishDataOutput: {
        title: '',
        preview: {
          color: '',
          imageId: '',
        },
        data: {
          intensity: '',
          season: '',
          weight: '',
          complexity: '',
          nationality: [],
        },
        tasteIntensities: {},
        taste: {
          text: '',
          ingredients: [], // Object Input
          tasteProfile: [], // Array Input
          trigger: [],
        },
        aroma: {
          text: '',
          aromaProfile: [],
        },
        texture: {
          text: '',
          textures: [], // Object Input
          trigger: [],
        },
      },
      buttonType: 'dark',
      windowWidth: 0,
      currentPage: 0,
      pages: 3,
      mobile: false,
      visibleToast: false,
      visibleModal: false,
      pageTitles: {
        desktop: [
          {
            title: 'General informations', infoTitle: 'Adding General Informations', info: 'To get started with your new dish, you need to name it first. Than add as many Ingredients as you need to best describe the taste of your dish. And add it’s origin nationalities. Wheather it is optional to add the ammount of each ingredient, it helps others better understanding your dish. In the end choose the preview tile color that later on appears in your dashboard.',
          },
          {
            title: 'Taste & Mouthfeel', info: 'After you have already added main informations about your dish, you can now describe it in more detail. Add a taste profile to describe the basic taste of a dish and add consistencies to describe its mouthfeel.',
          },
          {
            title: 'Key Elements', info: 'Key ingredients build the core of your dish, they describe it’s main taste and aroma. If you are unsure wich ingredients to classify as key ingredient the title is usually a good start. You can prioritise up to four of your previously added ingredients. ',
          },
        ],
        mobil: [
          'General informations',
          'General informations',
          'Taste profile',
          'Consitencies',
          'Key Ingredients',
          'Key Textures',
        ],
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.windowWidth = window.innerWidth;
    if (this.windowWidth < 1350) {
      this.buttonType = 'noLabel';
      this.mobile = true;
      this.pages = 6;
    } else {
      this.mobile = false;
      this.pages = 3;
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    windowWidth(newValue) {
      if (newValue < 1350) {
        this.buttonType = 'noLabel';
        this.mobile = true;
        this.pages = 6;
      } else {
        this.buttonType = 'dark';
        this.mobile = false;
        this.pages = 3;
      }
    },
    mobile(newValue) {
      if (newValue === true) {
        this.currentPage *= 2;
      } else {
        this.currentPage = Math.floor(this.currentPage / 2);
      }
    },
    currentPage(newValue, oldValue) {
      console.log(oldValue, newValue);
    },
  },
  methods: {
    handleClick() {
      if (!this.mobile && this.currentPage === 0) {
        if (!this.forms.title.error && !this.forms.ingredients.error && !this.forms.nationality.error) {
          this.currentPage += 1;
          this.direction = 'right';
        }
        if (this.forms.title.error) {
          this.titleError = this.forms.title.errorMessage;
        }
        if (this.forms.ingredients.error) {
          this.ingredientError = this.forms.ingredients.errorMessage;
        }
        if (this.forms.nationality.error) {
          this.nationalityError = this.forms.nationality.errorMessage;
        }
      } else if (!this.mobile && this.currentPage === 1) {
        if (!this.forms.tasteprofile.error && !this.forms.mouthfeel.error) {
          this.currentPage += 1;
          this.direction = 'right';
        }
        if (this.forms.mouthfeel.error) {
          this.mouthfeelError = this.forms.mouthfeel.errorMessage;
        }
        if (this.forms.tasteprofile.error) {
          this.tasteprofileError = this.forms.tasteprofile.errorMessage;
        }
      }

      // Validation mobile
      if (this.mobile && this.currentPage === 0) {
        if (!this.forms.title.error && !this.forms.ingredients.error) {
          this.currentPage += 1;
          this.direction = 'right';
        }
        if (this.forms.title.error) {
          this.titleError = this.forms.title.errorMessage;
        }
        if (this.forms.ingredients.error) {
          this.ingredientError = this.forms.ingredients.errorMessage;
        }
      } else if (this.mobile && this.currentPage === 1) {
        if (!this.forms.nationality.error) {
          this.currentPage += 1;
          this.direction = 'right';
        }
        if (this.forms.nationality.error) {
          this.nationalityError = this.forms.nationality.errorMessage;
        }
      } else if (this.mobile && this.currentPage === 2) {
        if (!this.forms.tasteprofile.error) {
          this.currentPage += 1;
          this.direction = 'right';
        }
        if (this.forms.tasteprofile.error) {
          this.tasteprofileError = this.forms.tasteprofile.errorMessage;
        }
      } else if (this.mobile && this.currentPage === 3) {
        if (!this.forms.mouthfeel.error) {
          this.currentPage += 1;
          this.direction = 'right';
        }
        if (this.forms.mouthfeel.error) {
          this.mouthfeelError = this.forms.mouthfeel.errorMessage;
        }
      } else if (this.mobile && this.currentPage === 4) {
        if (!this.forms.keyingredients.error) {
          this.currentPage += 1;
          this.direction = 'right';
        }
        if (this.forms.keyingredients.error) {
          this.keyIngredientsError = this.forms.keyingredients.errorMessage;
        }
      }
    },
    handleClose() {
      this.$store.commit('addToasty', {
        action: () => {
          this.$router.push({ name: 'Home' });
          // this.$store.commit('closeToasty', this.$store.state.toasties[this.$store.state.toasties.length - 1].id);
        },
        labelDecline: 'Continue',
        labelConfirm: 'Discard',
        iconConfirm: 'delete',
        iconDecline: 'finish',
        text: 'Do you really want to stop editing? All your changes will be discarded.',
      });
    },
    handleTitleOutput(value) {
      this.dishDataOutput.title = value;
      if (value) {
        this.forms.title.error = false;
        this.titleError = '';
      } else if (value === '') {
        this.forms.title.error = true;
      }
    },
    handleKeyIngredientOutput(value) {
      this.dishDataOutput.taste.ingredients = value;
      let mainCountIngredients = 0;
      value.forEach((element) => {
        if (element.main) mainCountIngredients += 1;
      });
      if (mainCountIngredients < 2) {
        this.forms.keyingredients.error = true;
      } else if (value.length >= 2) {
        this.forms.keyingredients.error = false;
        this.ingredientError = '';
      }
      // // // console.log(this.$store.state.ingredients.get(this.dishDataOutput.taste.ingredients[0].title).weight);
    },
    handleIngredientOutput(value) {
      this.dishDataOutput.taste.ingredients = value;
      if (value.length < 2) {
        this.forms.ingredients.error = true;
      } else if (value.length >= 2) {
        this.forms.ingredients.error = false;
        this.ingredientError = '';
      }
      // // // console.log(this.$store.state.ingredients.get(this.dishDataOutput.taste.ingredients[0].title).weight);
    },
    handleNationalityOutput(value) {
      this.dishDataOutput.data.nationality = value;
      // // console.log(value.length);
      if (value.length === 0) {
        this.forms.nationality.error = true;
      } else if (value.length > 0) {
        this.forms.nationality.error = false;
        this.nationalityError = '';
      }
    },
    handlePreviewCol(value) {
      this.dishDataOutput.preview.color = value;
    },
    handlePreviewUrl(value) {
      this.dishDataOutput.preview.imageId = value;
    },
    handleTasteOutput(value) {
      this.dishDataOutput.tasteIntensities = Object.assign(value);
      // Überprüfe ob wenigstens ein Wert anders ist als 0
      const keys = Object.keys(value);
      let validTasteProfile = false;
      keys.forEach((key) => {
        if (value[key]) validTasteProfile = true;
      });
      if (validTasteProfile) {
        this.forms.tasteprofile.error = false;
        this.tasteprofileError = '';
      } else {
        this.forms.tasteprofile.error = true;
      }
    },
    handleTextureOutput(value) {
      // // console.log(value.length);
      // Check if a texture is already set as main
      value.forEach((element) => {
        this.dishDataOutput.texture.textures.forEach((texture) => {
          if (element.title === texture.title && texture.main) {
            element.main = true; // eslint-disable-line no-param-reassign
          }
        });
      });

      this.dishDataOutput.texture.textures = value;
      if (value.length === 0) {
        this.forms.mouthfeel.error = true;
      } else {
        this.forms.mouthfeel.error = false;
        this.mouthfeelError = '';
      }
    },
    handleKeyTextureOutput(value) {
      this.dishDataOutput.texture.textures = value;
      let mainCountTextures = 0;
      value.forEach((element) => {
        if (element.main) mainCountTextures += 1;
      });
      if (mainCountTextures < 2) {
        this.forms.keytextures.error = true;
      } else if (value.length >= 2) {
        this.forms.keytextures.error = false;
        this.keyTexturesError = '';
      }
    },
    // handleInput(value) {
    //   // // console.log((value);
    // },
    onResize() {
      // // console.log((window.innerWidth);
      this.$store.commit('setWindowWidth', window.innerWidth);
      this.windowWidth = window.innerWidth;
      // // console.log((this.$store.state.windowWidth);
    },
    navigateHome() {
      if (this.$route.name === 'Home') return;
      this.$router.push({ name: 'Home' });
    },
    handleCreateDish() {
      // Check input
      if (!this.mobile) {
        if (!this.forms.keyingredients.error && !this.forms.keytextures.error) {
          this.createDish();
        }
        if (this.forms.keyingredients.error) {
          this.keyIngredientsError = this.forms.keyingredients.errorMessage;
        }
        if (this.forms.keytextures.error) {
          this.keyTexturesError = this.forms.keytextures.errorMessage;
        }
      } else {
        if (!this.forms.keytextures.error) {
          // this.currentPage += 1;
          this.createDish();
        }
        if (this.forms.keytextures.error) {
          this.keyTexturesError = this.forms.keytextures.errorMessage;
        }
      }
    },
    navigateDish() {
      this.$router.push({ name: 'Tastestory', params: { index: this.dishId } });
    },
    async createDish() {
      // this.currentPage = this.pages;
      // // console.log((this.dishDataOutput);
      // Gewicht des Gerichts
      this.dishDataOutput.data.weight = calculateWeight(this.dishDataOutput.taste.ingredients);
      // Intensität des Gerichts
      this.dishDataOutput.data.intensity = calculateIntensity(this.dishDataOutput.taste.ingredients);
      // Saisonalität des Gerichts
      this.dishDataOutput.data.season = calculateSeason(this.dishDataOutput.taste.ingredients);
      // Das Geschmacksprofil Auswerten
      this.dishDataOutput.taste.tasteProfile = createTasteprofile(this.dishDataOutput.tasteIntensities);
      // // console.log(this.dishDataOutput.taste.tasteProfile);
      // Aromenprofil Auswerten
      this.dishDataOutput.aroma.aromaProfile = createAromaProfile(this.dishDataOutput.taste.ingredients);
      // // console.log(this.dishDataOutput.aroma.aromaProfile);
      // Bestimmte den Taste Trigger
      this.dishDataOutput.taste.trigger.push(findTasteTrigger(this.dishDataOutput.tasteIntensities));
      // Texturtrigger bestimmen
      this.dishDataOutput.texture.trigger = findTextureTrigger(this.dishDataOutput.texture.textures);
      // Den Geschmaks Text Generieren
      this.dishDataOutput.taste.text = createTasteText(this.dishDataOutput.taste.ingredients, this.dishDataOutput.tasteIntensities, this.dishDataOutput.taste.trigger);
      // // console.log(this.dishDataOutput.taste.text);
      // Den Aroma Text Generieren
      this.dishDataOutput.aroma.text = createAromaText(this.dishDataOutput.aroma.aromaProfile);
      // Den Texturen Text Generieren
      this.dishDataOutput.texture.text = createTextureText(this.dishDataOutput.texture.textures, this.dishDataOutput.texture.trigger);

      // Adden oder Bearbeiten:
      try {
        this.dishId = await db.dishes.put(cloneDeep(this.dishDataOutput));
        console.log(JSON.parse(JSON.stringify(this.dishDataOutput)));
        this.finished = true;
      } catch (err) {
        console.warn(`Error pushing new dish into db: ${err.message}`);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.home
  background-color: $deepBlack
  font-feature-settings: 'ss02' on;
  .finishDish
    width:100vw
    background-color: $white
    border-radius: (18/16)rem (18/16)rem 0 0
    position: fixed;
    left: 0
    top: (188/16)rem
    bottom: 0
    .content
      position: relative;
      top: 10%
      margin: 0 auto
      width: 90%
      height: 100%
      display: flex
      flex-direction: row;
      justify-content: space-between;
      .leftWrapper
        flex-basis: 45%
        .head
          font-size: (60/16)rem
        .copy
          font-size: (25/16)rem
          margin-top: (25/16)rem
          line-height: 130%
        .buttonWrapper
          margin-top: (25/16)rem
          display: flex
          .button
            margin-right: 1rem
      .illustrationWrapper
        flex-basis: 40%
  .headWrapper
    position: fixed
    top: (40/16) rem
    display: grid
    grid-template-columns: 1fr 1fr
    grid-template-rows: auto
    width: 90%

    h3
      color: $white
      margin-bottom: (36/16)rem
    .leftWrapper
      grid-row: 2
      display: flex
      .inputWrapper
        max-width: (700/16)rem
        width: 28vw
    .rightWrapper
      grid-column: 2
      grid-row: 1 /span 2
      display: flex
      align-self: end
      justify-self: end
      .button
        margin-left:$buttonSpacingDesktop
  .content
    width:100%
    height: auto
    margin: 0 auto
    position: relative;
    left: 0
    top: (230/16)rem
    .pageWrapper
      .contentPage
        display: flex
        justify-content: space-between;
        .groupWrapper
          width: 45%
          .formElement
            width: 100%
        .formElement
          width: 45%
    .buttonNavigation
      position: fixed
      bottom: 4%
      right: 4%

      display: flex
      .button
        margin-left: $buttonSpacingDesktop
      .finish
        margin-left: 1rem

.pageFlipRight-enter-active,
.pageFlipRight-leave-active
  transition: opacity 150ms ease-out, transform 150ms ease-out

.pageFlipRight-enter-from
  transform: translateX(10%)
  opacity: 0

.pageFlipRight-leave-to
  opacity: 0
  transform: translateX(-10%)

.pageFlipRight-enter-to,
.pageFlipRight-leave-from
  opacity: 1
  transform: translateX(0%)

.pageFlipLeft-enter-active,
.pageFlipLeft-leave-active
  transition: opacity 150ms ease-out, transform 150ms ease-out

.pageFlipLeft-enter-from
  transform: translateX(-10%)
  opacity: 0

.pageFlipLeft-leave-to
  opacity: 0
  transform: translateX(10%)

.pageFlipLeft-enter-to,
.pageFlipLeft-leave-from
  opacity: 1
  transform: translateX(0%)

.scrollPage-enter-active,
.scrollPage-leave-active
  transition: opacity 350ms ease-out, transform 350ms ease-out

.scrollPage-leave-to,
.scrollPage-enter-from
  opacity: 0
  transform: translateY(100%)

.scrollPage-enter-to,
.scrollPage-leave-from
  opacity: 1
  transform: translateY(0%)

@media (max-width: 1350px)
  .home
    .finishDish
      width:100vw
      background-color: $white
      border-radius: (18/16)rem (18/16)rem 0 0
      position: fixed;
      left: 0
      top: (188/16)rem
      bottom: 0
      .content
        position: relative;
        top: 10%
        margin: 0 auto
        width: 90%
        height: 100%
        display: flex
        flex-direction: row;
        justify-content: space-between;
        .leftWrapper
          flex-basis: 45%
          .head
            font-size: (45/16)rem
          .copy
            font-size: (20/16)rem
            margin-top: (25/16)rem
            line-height: 130%
          .buttonWrapper
            margin-top: (25/16)rem
            display: flex
            .button
              margin-right: 1rem
    .headWrapper
      position: relative;
      top: (20/16) rem
      display: grid
      grid-template-columns: 1fr 1fr
      grid-template-rows: auto
      width: 100%

      h3
        color: $white
        margin-bottom: (36/16)rem
      .leftWrapper
        grid-row: 2
        display: flex
        .inputWrapper
          max-width: (700/16)rem
          width: 28vw
      .rightWrapper
        grid-column: 2
        grid-row: 1 /span 2
        display: flex
        align-self: end
        justify-self: end
        .button
          margin-left:$buttonSpacingDesktop
    .content
      min-height: 80vh
      display: flex
      flex-direction: column;
      width:100%
      height: auto
      margin: 0 auto
      position: relative;
      left: 0
      top: (100/16)rem
      // margin-top: 0
      .pageWrapper
        display: block
        .groupWrapper
          width: 100%
        .formElement
          width: 100%
      .buttonNavigation
        margin-top: auto
        position: inherit;
        margin-bottom: 10%
        bottom: 0
        right: 0
        left: 0
        display: flex
        .button
          width: 100%
          margin-left: 0
        .finish
          margin-left: 1rem

@media (max-width: 800px)
  .home
    .finishDish
      width:100vw
      background-color: $white
      border-radius: (18/16)rem (18/16)rem 0 0
      position: fixed;
      left: 0
      top: (110/16)rem
      bottom: 0
      .content
        position: relative;
        top: 5%
        margin: 0 auto
        width: 90%
        height: 100%
        display: block
        flex-direction: column;
        justify-content: flex-start;
        .leftWrapper
          flex-basis: 30%
          height: auto
          .head
            font-size: (25/16)rem
          .copy
            font-size: (16/16)rem
            margin-top: (10/16)rem
            line-height: 130%
          .buttonWrapper
            position: fixed
            bottom: 3%
            width 94%
            margin-top: (25/16)rem
            display: flex
            .button
              margin-right: 1rem
              width: 100%
        .illustrationWrapper
          flex-basis: 100%
          margin-top: 2vh
          display: flex
          align-items: center
          .illustration
            width: 45vh
            margin: 0 auto
    .headWrapper
      position: relative;
      top: (30/16) rem
      display: grid
      grid-template-columns: 1fr 1fr
      grid-template-rows: auto
      width: 100%

      h3
        color: $white
        margin-bottom: (36/16)rem
      .leftWrapper
        grid-row: 2
        display: flex
        .inputWrapper
          max-width: (700/16)rem
          width: 28vw
      .rightWrapper
        grid-column: 2
        grid-row: 1 /span 2
        display: flex
        align-self: end
        justify-self: end
        .button
          margin-left:$buttonSpacingDesktop
    .content
      min-height: 80vh
      display: flex
      flex-direction: column;
      width:100%
      height: auto
      margin: 0 auto
      position: relative;
      left: 0
      top: (100/16)rem
      // margin-top: 0
      .pageWrapper
        display: block
        .groupWrapper
          width: 100%
        .formElement
          width: 100%
      .buttonNavigation
        margin-top: auto
        position: inherit;
        margin-bottom: 10%
        bottom: 0
        right: 0
        left: 0
        display: flex
        .button
          width: 100%
          margin-left: 0
        .finish
          margin-left: 1rem
</style>
