<template lang="html">
<div class="inputRangeWrapper">
  <div class="leftFlex">
      <div class="rangeElement"
      v-for="(number, index) in 6"
      :key="index"
      :class="{highlight: highlight >= index, active: range >= index, activeHighlight: hover && range >= index && index > highlight}"
      @mouseover="[highlight = index, hover = true]"
      @mouseleave="[highlight = -1, hover = false]"
      @click="[range = index, $emit('setRange', index)]"
      >
    </div>
  </div>
  <transition name="iconFade">
    <Ui-Icons v-if="range > -1" icon="removeEntry" @click="[range = -1, $emit('setRange', -1)]"/>
  </transition>
</div>
</template>

<script>
import UiIcons from '@/components/UiIcons.vue';

export default {
  components: {
    UiIcons,
  },
  mounted() {
    this.range = this.rangeIn;
  },
  props: {
    rangeIn: Number,
  },
  data() {
    return {
      hover: false,
      range: -1,
      highlight: -1,
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.inputRangeWrapper
  display: flex
  width: 100%
  justify-content: space-between;
  align-items: center
  .leftFlex
    display: flex
    width: 91%
    justify-content: space-between;
    .rangeElement
      height: (28/16)rem
      background-color: $deepBlack
      border: 1px solid $white
      border-radius: 5px
      width: 14.5%
      transition: background-color 150ms ease-out
      &:hover
        cursor: pointer
      &.highlight
        background-color: $focusBlack
      &.active
        background-color: $white
      &.activeHighlight
        background-color: $gray
  .icon
    &:hover
      color: $uiPurple
      cursor: pointer;
.iconFade-enter-active,
.iconFade-leave-active
  transition: opacity 250ms ease-out

.iconFade-enter-from,
.iconFade-leave-to
  opacity: 0

.iconFade-enter-to,
.iconFade-leave-from
  opacity: 1

</style>
