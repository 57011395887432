<template lang="html">
  <div class="progressHead">
    <p class="subhead">{{`0${currentScreen} → ${title}`}}</p>
    <svg class="progress" viewBox="0 0 302 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="300" height="7" rx="3.5" stroke="#FFFEFC" stroke-width="2"/>
      <rect x="2" y="2" :width="(300/(screens + 1)) * currentScreen" height="5" rx="2.5" fill="#FFFEFC" stroke="#FFFEFC" stroke-width="2"/>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    currentScreen: Number,
    screens: Number,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'

.progressHead
  .subhead
    font-family: 'PPPangramSansRounded'
    font-size: (16/16)rem
    color: $white
    font-weight: 500
    font-feature-settings: 'ss02' on;
    margin-bottom: (25/16)rem
  .progress
    max-width:(300/16)rem
    rect
      transition: width 250ms ease-out

@media (max-width: 750px)
  .progressHead
    .subhead
      font-family: 'PPPangramSansRounded'
      font-size: $mobileLegend
      color: $white
      font-weight: 500
      font-feature-settings: 'ss02' on;
      margin-bottom: (13/16)rem
    .progress
      width: (170/16)rem
      rect
        transition: width 250ms ease-out
</style>
