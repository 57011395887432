<template lang="html">
  <div class="formElement">
    <ui-header-help
    :title="`Key ingredients (${activeIngredients}/4)`"
    infoTitle="Add key ingredients"
    infoText="Key ingredients build the core of your dish, they describe its main taste and aroma. If you are unsure which ingredients to prioritize the title is usually a good clue. You can highlight up to four of your previously added ingredients as key ingredients."/>
    <p class="error">{{checkError}}</p>
    <div class="buttonWrapper">
      <ui-key-ingredient-button
      v-for="(ingredient,index) in keyIngredients"
      :key="index"
      :ingredient-id="ingredient.title"
      :active="ingredient.main"
      @click="handleOutput(index)"
      />
    </div>
    <!-- <p>{{keyIngredients}}</p> -->
  </div>
</template>

<script>
import UiHeaderHelp from '@/components/UiHeaderHelp.vue';
import UiKeyIngredientButton from '@/components/UiKeyIngredientButton.vue';

export default {
  mounted() {
    this.keyIngredients = [...this.ingredients];
  },
  computed: {
    checkError() {
      if (this.errorMessageLocal) return this.errorMessageLocal;
      if (this.errorMessage) return this.errorMessage;
      return '';
    },
  },
  components: {
    UiHeaderHelp,
    UiKeyIngredientButton,
  },
  props: {
    ingredients: Array,
    errorMessage: String,
  },
  methods: {
    handleOutput(index) {
      // // console.log(this.keyIngredients[index].main);
      if (this.keyIngredients[index].main) {
        this.keyIngredients[index].main = false;
        this.errorMessageLocal = '';
        this.$emit('output', this.keyIngredients);
      } else if (!this.keyIngredients[index].main && this.activeIngredients < 4) {
        this.keyIngredients[index].main = true;
        this.errorMessageLocal = '';
        this.$emit('output', this.keyIngredients);
      } else {
        this.errorMessageLocal = 'You already reached the maximum amount of key ingredients';
      }
      this.activeIngredients = this.keyIngredients.filter((element) => element.main).length;
    },
  },
  data() {
    return {
      errorMessageLocal: '',
      keyIngredients: [],
      activeIngredients: 0,
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.formElement
  position: relative;
  color: $white
  .error
    position: absolute;
    top: 1.8rem
    color: $uiError
    font-family: 'PPPangramSansRounded'
    font-size: (16/16)rem
    font-weight: 600
    font-feature-settings: 'ss02' on;
  .buttonWrapper
    height: 63vh
    display: flex
    flex-wrap: wrap
    align-content: flex-start;
    // justify-content: space-around;
    overflow: scroll
    -webkit-mask-image: linear-gradient(180deg, $deepBlack 70%, transparent);
    // .ingredientButton
  p
    position: absolute;
    margin-top: 30px

@media(max-width:750px)
  .formElement
    .error
      color: $uiError
      position: relative;
      top: 0
      margin-bottom: 1rem
      margin-top: 0
    .buttonWrapper
      justify-content: space-between;
      height:auto
      margin-bottom: 3rem
      overflow: inherit;
      -webkit-mask-image: inherit;
</style>
