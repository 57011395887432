<template lang="html">
  <div class="headBar">
    <p class="subTitle">{{dishTitle}}</p>
    <div class="informations">
      <div class="infoline">
        <p class="infoText">{{`${season} dish`}}</p>
        <p class="infoText">{{`Nationality: ${nationality}`}}</p>
      </div>
      <div class="infoline">
        <p class="infoText">{{`Weight: ${weight}`}}</p>
        <p class="infoText">{{`Intensity: ${intensity}`}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dishTitle: String,
    season: String,
    intensity: String,
    nationality: String,
    weight: String,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'

.headBar
  width: (1876/1920*100)vw
  height: (66/1080*100)vh
  background-color: $white
  color: $black
  box-shadow: 0 0 0 (2/1080*100)vh $black;
  border-radius: $tileRadiusDesktop
  display: flex
  justify-content: space-between;
  align-items: center
  padding: 0  (25/1080*100)vh
  margin-bottom: (2/1080*100)vh

  .subTitle
    font-size: $subHead
    font-weight: 500
    width: (300/1080*100)vh

  .informations
    display: flex

    .infoline
      margin-left: (40/1080*100)vh
      .infoText
        text-transform: capitalize;
        font-size: (16/1080*100)vh
        color:$gray
        margin: (5/1080*100)vh 0

@media (max-width: 750px)
  .headBar
    width: (1876/1920*100)vw
    height: (66/1080*100)vh
    background-color: $white
    color: $black
    box-shadow: 0 0 0 (2/1080*100)vh $black;
    border-radius: $tileRadiusDesktop
    display: flex
    justify-content: space-between;
    align-items: center
    padding: 0  (15/1080*100)vh
    margin-bottom: (2/1080*100)vh

    .subTitle
      font-size: (12/16)rem
      font-weight: 500
      width: (300/1080*100)vh

    .informations
      display: none

      .infoline
        margin-left: (40/1080*100)vh
        .infoText
          font-size: $marginal
          color:$gray
          margin: (5/1080*100)vh 0

</style>
