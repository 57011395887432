<template lang="html">
  <div class="backTileWrapper">
    <div class="headWrapper">
      <h2>{{ingredient.ingredient}}</h2>
      <taste-button  :label="buttonType === 'cta' ? 'Close': ''" :mode="buttonType" icon="close" @buttonClick="$emit('buttonClick', $event)"/>
    </div>
    <div class="contentWrapper">
      <div class="elementFrame" :style="{borderColor: ingredient.color}">
        <taste-ingredient :width="8" :ingredient="ingredient.icon" mode="wiki"/>
      </div>
      <div class="database">
        <p>{{`Cat.: ${ingredient.category}`}}</p>
        <p>{{`Taste: ${ingredient.taste}`}}</p>
        <p>{{`Season: ${ingredient.season}`}}</p>
        <p>{{`Weight: ${calcWeight}`}}</p>
        <p>{{`Intensity: ${calcIntensity}`}}</p>
      </div>
    </div>
    <p>Aroma Profile:</p>
    <div class="aromaWrapper">
      <taste-aroma-graph
      v-for="aroma in ingredientAromas"
      :key = "aroma.title"
      class="graph"
      :intensity="aroma.intensity"
      :aroma="aroma.title"
      :dark="true"
      taste="sweet"
      :mainAroma="aroma.intensity > 5"
      />
  </div>
</div>
</template>

<script>
import TasteButton from '@/components/TasteButton.vue';
import TasteIngredient from '@/components/TasteIngredient.vue';
import TasteAromaGraph from '@/components/TasteAromaGraph.vue';

export default {
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    ingredient() {
      return this.$store.state.ingredients.get(this.ingredientId);
    },
    calcWeight() {
      if (this.ingredient.weight === 3) return 'average';
      if (this.ingredient.weight > 3) return 'high';
      if (this.ingredient.weight < 3) return 'low';
      return 'undefined';
    },
    calcIntensity() {
      if (this.ingredient.intensity === 3) return 'average';
      if (this.ingredient.intensity > 3) return 'heavy';
      if (this.ingredient.intensity < 3) return 'light';
      return 'undefined';
    },
  },
  watch: {
    windowWidth(newValue) {
      if (newValue < 750) {
        this.buttonType = 'noLabel';
      } else {
        this.buttonType = 'cta';
      }
    },
  },
  data() {
    return {
      buttonType: 'cta',
      ingredientAromas: [],
    };
  },
  mounted() {
    // // console.log(this.ingredient.aroma);
    // Load all aroma entries to a local array
    Object.entries(this.ingredient.aroma).forEach(([key, value]) => {
      if (value > 0) {
        this.ingredientAromas.push({ title: key, intensity: value });
      }
    });
    this.buttonType = 'cta';
    if (window.innerWidth < 750) this.buttonType = 'noLabel';
  },
  components: {
    TasteButton,
    TasteIngredient,
    TasteAromaGraph,
  },
  props: {
    ingredientId: String,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.backTileWrapper
  height: (470/1080*100)vh
  width: (470/1080*100)vh
  background-color: $black
  padding: (23/1080*100)vh
  border-radius: $tileRadiusDesktop
  color: $white
  text-align: left
  p
    font-size: (14/1080*100)vh
    font-weight: 500
    text-transform: capitalize;
  .headWrapper
      display: flex
      flex-direction: row
      justify-content: space-between;
      color: $white
    h2
      display: block;
      flex-shrink: 0
      text-overflow: ellipsis
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden
  .aromaWrapper
    margin-top: (22/1080*100)vh
    display: flex
    justify-content: space-between;
    width: 100%
    .graph
      height: (130/1080*100)vh
      width: (68/1080*100)vh

  .contentWrapper
    margin-top: (22/1080*100)vh
    margin-bottom: (18/1080*100)vh
    display: flex;

    .elementFrame
      width: (182/1080*100)vh
      height: @width
      background-image: url('../assets/p/wiki.png');
      border: (2/1080*100)vh solid

    .database
      text-align: left
      color: $white
      font-family: 'PPPangramSansRounded'
      font-feature-settings: 'ss02' on;
      margin-left: (33/1080*100)vh
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis;
      p
        font-size: $subHead
        letter-spacing: 0.02em;
        font-weight: 600
        margin-bottom: (10/1080*100)vh
@media (max-width: 750px)
  .backTileWrapper
    height: (470/1080*100)vh
    width: (470/1080*100)vh
    background-color: $black
    padding: (23/1080*100)vh
    border-radius: $tileRadiusDesktop
    color: $white
    text-align: left
    p
      font-size: (12/16)rem
      font-weight: 500
      text-transform: capitalize;
      font-feature-settings: 'ss02' on;
    .headWrapper
        display: flex
        flex-direction: row
        justify-content: space-between;
        color: $white
      h2
        display: block;
        font-size: (23/16)rem
        font-weight: 500
        line-height: 1
        flex-shrink: 0
        text-overflow: ellipsis
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden
    .aromaWrapper
      margin-top: (22/1080*100)vh
      display: flex
      justify-content: space-between;
      width: 100%
      .graph
        height: (130/1080*100)vh
        width: (68/1080*100)vh

    .contentWrapper
      margin-top: (20/1080*100)vh
      margin-bottom: (18/1080*100)vh
      display: flex;

      .elementFrame
        width: (182/1080*100)vh
        height: @width
        background-image: url('../assets/p/wiki.png');
        border: (2/1080*100)vh solid

      .database
        text-align: left
        color: $white
        font-family: 'PPPangramSansRounded'
        font-feature-settings: 'ss02' on;
        margin-left: (33/1080*100)vh
        width: 50%
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis;
        p
          font-size: (13/16)rem
          font-feature-settings: 'ss02' on;
          letter-spacing: 0.02em;
          font-weight: 600
          margin-bottom: (5/1080*100)vh

</style>
