<template lang="html">
  <button class="button" :class="mode" @click="$emit('buttonClick', $event)" @focus="$emit('focus', $event)">
    {{label}}
    <Ui-Icons v-if="icon" :icon="icon"/>
  </button>
</template>

<script>
import UiIcons from '@/components/UiIcons.vue';

export default {
  components: {
    UiIcons,
  },
  props: {
    icon: String,
    label: String,
    mode: String,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'

button
  box-sizing: border-box;
  outline: none
  display: flex
  justify-content: space-between;
  align-items: center
  padding: (7/1080*100)vh (13/1080*100)vh  (7/1080*100)vh  (15/1080*100)vh
  border:$uiBorder
  border-radius: $uiRadius
  font-family: 'PPPangramSansRounded'
  font-size: $button
  font-weight: 500
  font-feature-settings: 'ss02' on;

  .icon
    margin-left: (10/1080*100)vh
    width: (24/1080*100)vh
    height: @width

  &.cta
    color: $white
    border-color: $uiPurple
    background-color: $uiPurple
    transition: all 100ms ease-out
    &:hover
      border-color: $white
      cursor: pointer
    &:focus
      border-color: $white
      cursor: pointer
  &.dark
    color: $white
    background-color: $deepBlack
    border-color: $white
    transition: all 100ms ease-out
    &:hover
      background-color: $focusBlack
      cursor: pointer
    &:focus
      border-color: $white
      background-color: $focusBlack
  &.noLabel
    width: auto
    height: @width
    padding: 0
    background-color: $black
    color: $white
    border-radius: 100%
    border: none
    justify-content: center;
    align-items: flex-start;
    transition: all 100ms ease-out
    .icon
      margin-left: 0
      width: inherit;
      height: inherit;

</style>
