<template lang="html">
  <div class="circle">
  </div>
</template>

<script>
export default {
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.circle
  width: (14/1080*100)vh
  height: (14/1080*100)vh
  border-radius: 100px
  border (2/1080*100)vh solid $black
  background-color:$white
  margin: 0 (3/1080*100)vh
  transition: all 150ms ease-out

  &:hover
    cursor: pointer
    border-color: $uiPurple
    box-shadow: 0 0 0 1px $uiPurple

  .active
    background-color: $uiPurple
</style>
