<template lang="html">
  <div class="formElement">
    <ui-header-help
    title="Mouthfeel"
    infoTitle="Adding consistencies"
    infoText="Besides the taste of a dish, we perceive the sensation in our mouth while we eat. We perceive individual textures but also contrasts. Hence, the interaction of textures is just as important as good taste. You can define your dishes mouthfeel by selecting consistencies from the list."/>
    <p class="error">{{errorMessage}}</p>
    <div class="inputWrapper">
      <ui-button-center
      v-for="(texture, index) in textures"
      :key = "texture.title"
      :label="texture.title"
      :activeButton="texture.active"
      @buttonClick="handleOutput(index)"
      />
    </div>
    <!-- <p>{{textureOutput}}</p> -->
  </div>
</template>

<script>
import UiHeaderHelp from '@/components/UiHeaderHelp.vue';
import UiButtonCenter from '@/components/UiButtonCenter.vue';

export default {
  components: {
    UiHeaderHelp,
    UiButtonCenter,
  },
  props: {
    texturesIn: Array,
    errorMessage: String,
  },
  created() {
    this.textureOutput = [...this.texturesIn];

    // Check for all the textures that are already active and make them active
    for (let i = 0; i < this.texturesIn.length; i += 1) {
      // // // console.log(this.texturesIn[i].title);
      const objIndex = this.textures.findIndex(((obj) => obj.title === this.texturesIn[i].title));
      // // // console.log(this.textures[objIndex].title);
      this.textures[objIndex].active = true;
    }
    // // // console.log(this.textures);
  },
  methods: {
    handleOutput(index) {
      // Handle Button state
      if (this.textures[index].active) {
        this.textures[index].active = false;
      } else {
        this.textures[index].active = true;
      }
      const prototypeArray = this.textures.filter((texture) => texture.active);
      // // // console.log(prototypeArray);
      this.textureOutput = [];
      for (let i = 0; i < prototypeArray.length; i += 1) {
        this.textureOutput.push({ title: prototypeArray[i].title, main: false });
      }
      this.$emit('output', this.textureOutput);
    },
  },
  data() {
    return {
      textureOutput: [], // Textures that go out
      textures: [ // all Textures that a part of Tastery
        {
          title: 'airy', active: false,
        },
        {
          title: 'cold', active: false,
        },
        {
          title: 'creamy', active: false,
        },
        {
          title: 'crisp', active: false,
        },
        {
          title: 'crumbly', active: false,
        },
        {
          title: 'crunchy', active: false,
        },
        {
          title: 'elastic', active: false,
        },
        {
          title: 'gooey', active: false,
        },
        {
          title: 'greasy', active: false,
        },
        {
          title: 'liquid', active: false,
        },
        {
          title: 'solid', active: false,
        },
        {
          title: 'tender', active: false,
        },
        {
          title: 'thick', active: false,
        },
        {
          title: 'warm', active: false,
        },
      ],
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.formElement
  color: $white
  .error
    color: $uiError
    position: absolute;
    top: 4rem
    font-family: 'PPPangramSansRounded'
    font-size: (16/16)rem
    font-weight: 600
    font-feature-settings: 'ss02' on;
  .inputWrapper
    display: flex
    flex-wrap: wrap
    justify-content: flex-start;
    .buttonCenter
      text-transform: capitalize;
      flex: 0 31%
      margin-bottom: 2.3%
      margin-right: 2.3%
@media(max-width:1350px)
  .formElement
    color: $white
    .error
      color: $uiError
      position: relative;
      top: 0
      margin-bottom: 1rem
    .inputWrapper
      display: flex
      flex-wrap: wrap
      justify-content: space-between;
      margin-bottom: 2rem
      .buttonCenter
        text-transform: capitalize;
        flex: 0 48%
        margin-right: 0
        margin-bottom: 4%

</style>
