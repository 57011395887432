<template lang="html">
<div class="ingredientButton"
  @mouseover="hover = true"
  @mouseleave="hover = false"
  :class="{active:active, errorButton:errorButton}">
  <Taste-ingredient :width="4" :ingredient="ingredient.icon" :mode="setMode" :strokeColor="setStroke" />
  <p class="buttonText">{{ingredient.ingredient}}</p>
</div>
</template>

<script>
import TasteIngredient from '@/components/TasteIngredient.vue';

export default {
  computed: {
    ingredient() {
      return this.$store.state.ingredients.get(this.ingredientId);
    },
    setMode() {
      if (this.active && this.hover) return 'activeHover';
      if (this.hover) return 'hover';
      if (this.active) return 'list';
      return 'button';
    },
    setStroke() {
      if (this.active && this.hover) return '#FFFEFC';
      if (this.active) return '#2F232D';
      return '#FFFEFC';
    },
  },
  components: {
    TasteIngredient,
  },
  props: {
    ingredientId: String,
    active: Boolean,
    errorButton: Boolean,
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.ingredientButton
  width: (170/1920*100)vw
  height: (170 /1920*100)vw
  text-align: center
  background-color: $deepBlack
  border: 1px solid $white
  border-radius: 7px
  display: inline-flex;
  flex-direction: column;
  padding: (10/16)rem
  padding-top: (3/16)rem
  transition: all 100ms ease-out
  margin-bottom: (16/16)rem
  margin-right: 1rem

  &:hover
    background-color: $focusBlack
    cursor: pointer
  &.active
    background-color: $white
    .buttonText
      color: $black
    &:hover
      background-color: $gray
      .buttonText
        color: $white

  .buttonText
    transition: all 100ms ease-out
    color: $white
    font-family: 'PPPangramSansRounded'
    font-size: $button
    font-weight: 500
    font-feature-settings: 'ss02' on;
    text-transform: capitalize;

@media (max-width: 1350px)
  .ingredientButton
    flex-basis: 0 20%
    width: 20.5vw
    height: 20.5vw
    margin-right: 1rem

@media (max-width: 1000px)
  .ingredientButton
    flex-basis: 0 33%
    width: 27.5vw
    height: 27.5vw
    margin-right: 1rem

@media (max-width: 750px)
  .ingredientButton
    flex-basis: 0 50%
    width: 46vw
    height: 46vw
    margin-right: 0
</style>
