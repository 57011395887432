<template lang="html">
  <div class="textFrame">
    <div class="titleBackContainer">
      <p class="title">{{title}}</p>
    </div>
    <div class="textContainer">
      <p class="subTitle">{{subTitle}}</p>
      <div class="textWrapper">
        <p class="text" v-html="text" @click="handleTrigger" @mouseover="handleHover"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
    text: String,
  },
  data() {
    return {
      toggleTrigger: false,
    };
  },
  methods: {
    handleHover(e) {
      // // // console.log(e.type);
      if (window.innerWidth < 750) return;
      if (e.target.classList.contains('trigger')) {
        this.$emit('hoverTrigger', true);
        e.target.addEventListener('mouseleave', this.handleHover);
      }
      if (e.type === 'mouseleave') {
        this.$emit('hoverTrigger', false);
        e.target.removeEventListener('mouseleave', this.handleHover);
      }
    },
    handleTrigger(e) {
      // // // // // console.log(e.target);
      if (e.target.classList.contains('trigger')) {
        if (this.toggleTrigger) {
          this.toggleTrigger = false;
          this.$emit('toggleTrigger', this.toggleTrigger);
          e.target.classList.remove('active');
        } else {
          this.toggleTrigger = true;
          this.$emit('toggleTrigger', this.toggleTrigger);
          e.target.classList.add('active');
        }
        // // // console.log(this.toggleTrigger);
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'

.textFrame
  width: (942/1080 * 100)vh
  height: (470/1080 * 100)vh
  font-feature-settings: 'ss02' on;
  grid-column-end: span 2

  .titleBackContainer
    background-color: $black
    color: $white
    font-size: $headline
    height: (118/1080 * 100)vh
    display: flex
    align-items: center
    padding-left: (14/1080*100)vh
    box-shadow: 0 0 0 (2/1080*100)vh $black
    border-radius: $tileRadiusDesktop

  .textContainer
    background-color: $white
    height: (352/1080 * 100)vh
    box-shadow: 0 0 0 (2/1080*100)vh $black
    border-radius: $tileRadiusDesktop
    padding: (14/1080*100)vh
    padding-top: (18/1080*100)vh
    font-size: $copyBig
    font-weight: 400

    .subTitle
      font-size: $subHead
      font-weight: 600
      color: $gray

    .textWrapper
      display: flex
      height: 100%
      justify-content: center;
      flex-direction: column;
      width: (800/1080*100)vh

      &::v-deep(.trigger)
        color: $uiPurple
        font-weight: 600
        display: inline-block;
        position: relative;

        &.active
          &:hover
            opacity: 0.7
          &.active:after
            transform: scaleX(1)
            transform-origin: bottom lef
            height: (5/1080*100)vh

        &:after
          border-radius: 100px
          content: ''
          position: absolute
          width: 100%
          transform: scaleX(0)
          height: (4/1080*100)vh
          bottom: 0
          left: 0
          background-color: $uiPurple
          transform-origin: bottom right;
          transition: transform 250ms ease-out

        &:hover
          cursor: pointer

        &:hover:after
          height: (5/1080*100)vh
          transform: scaleX(1)
          transform-origin: bottom left

      &::v-deep(strong)
        font-weight: 600
@media (max-width: 750px)
  .textFrame
    width: (470/1080 * 100)vh
    height: (470/1080 * 100)vh
    font-feature-settings: 'ss02' on;
    grid-column-end: inherit

    .titleBackContainer
      background-color: $black
      color: $white
      font-size: (20/16)rem
      height: (118/1080 * 100)vh
      display: flex
      align-items: center
      padding-left: (14/1080*100)vh
      box-shadow: 0 0 0 (2/1080*100)vh $black
      border-radius: $tileRadiusDesktop

    .textContainer
      background-color: $white
      height: (352/1080 * 100)vh
      box-shadow: 0 0 0 (2/1080*100)vh $black
      border-radius: $tileRadiusDesktop
      padding: (14/1080*100)vh
      padding-top: (18/1080*100)vh
      font-size: (18/16)rem
      line-height: 125%
      font-weight: 400

      .subTitle
        line-height: 100%
        font-size: (13/16)rem
        font-weight: 600
        color: $gray

      .textWrapper
        width: 100%
        display: flex
        height: 90%
        justify-content: center;
        flex-direction: column;
        // width: (800/1080*100)vh

        &::v-deep(.trigger)
          color: $uiPurple
          font-weight: 600
          display: inline-block;
          position: relative;

          &.active
            &:hover
              opacity: inherit;
            &.active:after
              transform: scaleX(1)
              transform-origin: bottom left
              height: (5/1080*100)vh

          &:after
            border-radius: 100px
            content: ''
            position: absolute
            width: 100%
            transform: scaleX(0)
            height: (4/1080*100)vh
            bottom: 0
            left: 0
            background-color: $uiPurple
            transform-origin: bottom right;
            transition: transform 250ms ease-out

          &:hover
            cursor: pointer

          &:hover:after
            height: (5/1080*100)vh
            transform: scaleX(1)
            transform-origin: bottom left

        &::v-deep(strong)
          font-weight: 600
</style>
