import Store from '../../store';

function mapValues(value, inMin, inMax, outMin, outMax) {
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

export default function createAromaProfile(ingredients) {
  const sumAroma = {
    brine: 0,
    cheesy: 0,
    citrus: 0,
    earthy: 0,
    floral: 0,
    fruity: 0,
    green: 0,
    herbal: 0,
    maritime: 0,
    meaty: 0,
    mustard: 0,
    nutty: 0,
    roasted: 0,
    spicy: 0,
    sulphuric: 0,
    woody: 0,
  };
  // // console.log('Aroma Section');
  ingredients.forEach((ingredient) => {
    const aromaProfile = Store.state.ingredients.get(ingredient.title).aroma;
    // // console.log(aromaProfile);
    const keys = Object.keys(aromaProfile);
    keys.forEach((key) => {
      if (ingredient.main) {
        sumAroma[key] += aromaProfile[key] * 1.3;
      } else {
        sumAroma[key] += aromaProfile[key];
      }
    });
  });
  // // console.log(sumAroma);
  const arr = Object.values(sumAroma);
  const max = Math.max(...arr);
  // // console.log(max);

  // Mape die Werte in eine Range von 0-10 wenn sie höher sind als 10
  if (max >= 10) {
    const keys = Object.keys(sumAroma);
    keys.forEach((key) => {
      sumAroma[key] = Math.round(mapValues(sumAroma[key], 0, max, 0, 10));
    });
  }
  return sumAroma;
}
