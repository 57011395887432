<template lang="html">
  <div class="overviewWrapper">
    <div class="ingredientContainer">
      <Taste-ingredient class="ingredient" :width="10" :ingredient="ingredient.icon" mode="list" />
      <p :class="main ? ingredient.taste : ''" :style="{fontSize: size}" class="ammount">{{ammount}}</p>
    </div>
    <p class="marginals">{{`${ingredient.ingredient}`}}</p>
  </div>
</template>

<script>
import TasteIngredient from '@/components/TasteIngredient.vue';

export default {
  computed: {
    ingredient() {
      return this.$store.state.ingredients.get(this.title);
    },
  },
  components: {
    TasteIngredient,
  },
  props: {
    title: String,
    ammount: String,
    main: Boolean,
    taste: String,
    size: String,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.overviewWrapper
  display: inline-block;
  width: 100%
  height: @width

  .ingredientContainer
    position: relative;

    .ammount
      position: absolute;
      bottom: 30%
      left: 5%
      font-weight: 500;
      font-style: italic;
      border: (2/1080*100)vh solid $black
      border-radius: 100px
      padding: (9/1080*100)vh (12/1080*100)vh (7/1080*100)vh (12/1080*100)vh ;
      display: inline-block;
      background-color: $white

      &.sweet
        border-color: $sweetRed
      &.umami
        border-color: $umamiPurple
      &.fatty
        border-color: $fattyYellow
      &.salty
        border-color: $saltyBlue
      &.sour
        border-color: $sourGreen

  .marginals
    text-align: center
    text-transform: capitalize
    font-size: $marginal
    font-weight: 500
    color: $gray

@media(max-width:750px)
  .overviewWrapper
    display: inline-block;
    width: 100%
    height: @width

    .ingredientContainer
      position: relative;

    .marginals
      text-align: center
      text-transform: capitalize
      font-size: (12/16)rem
      margin-top: (2/16)rem
      font-weight: 500
      color: $gray

</style>
