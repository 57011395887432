<template lang="html">
  <div :style="{backgroundColor: ingredient.color}" class="tileWrapper">
    <div class="headWrapper">
      <p class="subTitle">{{`Key Ingredient ${ingredient.ingredient}`}}</p>
      <Ui-icons icon="hover" iconType="negative"/>
    </div>
    <Taste-ingredient :width="strokeWidth" :ingredient="ingredient.icon" mode="fill"/>
  </div>
</template>

<script>
import UiIcons from '@/components/UiIcons.vue';
import TasteIngredient from '@/components/TasteIngredient.vue';

export default {
  computed: {
    ingredient() {
      return this.$store.state.ingredients.get(this.ingredientId);
    },
  },
  components: {
    TasteIngredient,
    UiIcons,
  },
  props: {
    ingredientId: String,
    strokeWidth: Number,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'

.tileWrapper
  display: flex
  flex-direction: column
  // height: (470/1080*100)vh
  height: 100%
  width: @height
  box-shadow: 0 0 0 (2/1080*100)vh $black;
  border-radius: $tileRadiusDesktop
  padding: (14/1080*100)vh
  padding-top: (8/1080*100)vh

  &:hover
    cursor: pointer;
    box-shadow: 0 0 0 (4/1080*100)vh $uiPurple, 10px 10px 20px rgba(47, 35, 45, 0.25);
    outline-color: $uiPurple

    .headWrapper
      .icon
        background-color: $uiPurple

  h3
    text-transform: capitalize;

  .ingredient
    width: 100%
    height: 100%

  .headWrapper
    color: $white
    display: flex
    flex-direction: row
    justify-content: space-between
    width: 100%
    align-items: center
    .subTitle
      padding-left: (3/1080*100)vh
      text-transform: capitalize;
      // padding-top: (15/1080*100)vh
      text-align: left
      font-size: $subHead
      font-weight: 600
      color: $white
    .icon
      flex-shrink: 0
      border-radius: 100px
      padding: (5/1080*100)vh
      width: (40/1080*100)vh
      height: (40/1080*100)vh
      transition: all 100ms ease

@media(max-width: 750px)
  .tileWrapper
    display: flex
    flex-direction: column
    // height: (470/1080*100)vh
    height: 100%
    width: @height
    box-shadow: 0 0 0 (2/1080*100)vh $black;
    border-radius: $tileRadiusDesktop
    padding: (14/1080*100)vh
    padding-top: (8/1080*100)vh

    &:hover
      cursor: pointer;
      box-shadow: 0 0 0 (4/1080*100)vh $uiPurple, 10px 10px 20px rgba(47, 35, 45, 0.25);
      outline-color: $uiPurple

      .headWrapper
        .icon
          background-color: $uiPurple

    h3
      text-transform: capitalize;

    .ingredient
      width: 100%
      height: 100%

    .headWrapper
      color: $white
      display: flex
      flex-direction: row
      justify-content: space-between
      width: 100%
      align-items: center
      .subTitle
        padding-left: (3/1080*100)vh
        // padding-top: (15/1080*100)vh
        text-align: left
        font-size: (13/16)rem
        font-weight: 600
        color: $white
      .icon
        flex-shrink: 0
        border-radius: 100px
        padding: (5/1080*100)vh
        width: (40/1080*100)vh
        height: (40/1080*100)vh

</style>
