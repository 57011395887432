<template lang="html">
  <transition name="modal">
    <div v-if="visible" class="toolTipp" @click.self="$emit('close')">
      <div class="container">
        <header>
          <h3 v-if="title">{{title}}</h3>
          <ui-icons icon="close" @click="$emit('close')"/>
        </header>
        <p v-html="text" class="tipp"></p>
      </div>
    </div>
  </transition>
</template>

<script>
import UiIcons from '@/components/UiIcons.vue';

export default {
  components: {
    UiIcons,
  },
  props: {
    title: String,
    text: String,
    visible: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.toolTipp
  width: (360/16)rem
  padding: (13/16)rem (13/16)rem (22/16)rem (17/16)rem;
  background-color: $deepBlack
  border: 1px solid $white
  border-radius: 5px
  drop-shadow: (0px 3px 10px rgba(0, 0, 0, 0.35));
  header
    margin-bottom: (20/16)rem
    display: flex
    justify-content: space-between;
    align-items: center
    .icon:hover
      color: $uiPurple
      cursor: pointer
  .tipp
    font-family: 'PPPangramSansRounded'
    font-size: (16/16)rem
    font-weight: 400
    font-feature-settings: 'ss02' on;
    line-height: 140%;

.modal-enter-active,
.modal-leave-active
  transition: opacity 250ms ease-out, transform 250ms ease-out

.modal-enter-from,
.modal-leave-to
  opacity: 0
  transform: translateY(30%)

.modal-enter-to,
.modal-leave-from
  opacity: 1
  transform: translateY(0%)
</style>
