import content from '../data/content';

export default function findTasteTrigger(texturesIn) {
  const texturesInDish = [];
  // Erstelle ein Array aus den Texturen
  texturesIn.forEach((texture) => {
    texturesInDish.push(texture.title);
  });

  const textureTriggerOut = [];

  // Durchsuche die Texturen nach Kontrasten

  if (texturesInDish.includes('airy') && texturesInDish.includes('tender')) {
    const protoTrigger = {
      title: 'airyTender',
      descrption: content.trigger.airyTender,
    };
    textureTriggerOut.push(protoTrigger);
  }
  if (texturesInDish.includes('crisp') && texturesInDish.includes('tender')) {
    const protoTrigger = {
      title: 'crispTender',
      descrption: content.trigger.crispTender,
    };
    textureTriggerOut.push(protoTrigger);
  }
  if (texturesInDish.includes('cold') && texturesInDish.includes('warm')) {
    const protoTrigger = {
      title: 'coldWarm',
      descrption: content.trigger.coldWarm,
    };
    textureTriggerOut.push(protoTrigger);
  }
  if (texturesInDish.includes('creamy') && texturesInDish.includes('crunchy')) {
    const protoTrigger = {
      title: 'creamyCrunchy',
      descrption: content.trigger.creamyCrunchy,
    };
    textureTriggerOut.push(protoTrigger);
  }
  if (texturesInDish.includes('crumbly') && texturesInDish.includes('liquid')) {
    const protoTrigger = {
      title: 'crumblyLiquid',
      descrption: content.trigger.crumblyLiquid,
    };
    textureTriggerOut.push(protoTrigger);
  }
  return textureTriggerOut;
}
