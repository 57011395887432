import Store from '../../store';

export default function calculateWeight(ingredients) {
  let sumOfIntensity = 0;
  ingredients.forEach((ingredient) => {
    sumOfIntensity += Store.state.ingredients.get(ingredient.title).intensity;
  });
  const intensity = Math.round(sumOfIntensity / ingredients.length);
  if (intensity === 3) return 'average';
  if (intensity > 3) return 'intense';
  if (intensity < 3) return 'light';
  return '-/-';
}
