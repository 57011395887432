<template lang="html">
  <div class="tileWrapper" :class="taste" :style="{backgroundImage: `url(${require(`@/assets/texture/square/${texture}.png`)})`}">
    <div class="headWrapper">
      <p class="subTitle">{{`Key Texture ${texture}`}}</p>
      <Ui-icons icon="hover" iconType="negative"/>
    </div>
  </div>
</template>

<script>
import UiIcons from '@/components/UiIcons.vue';

export default {
  components: {
    UiIcons,
  },
  props: {
    texture: String,
    taste: String,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'

.tileWrapper
  display: flex
  flex-direction: column
  height: (470/1080*100)vh
  width: @height
  box-shadow: 0 0 0 (2/1080*100)vh $black;
  border-radius: $tileRadiusDesktop
  padding: (14/1080*100)vh
  padding-top: (8/1080*100)vh
  background-size: cover
  transition: all 100ms ease-out

  &.sweet
    background-color: $sweetRed

    &:hover
      cursor: pointer;
      box-shadow: 0 0 0 (4/1080*100)vh $uiPurple, 10px 10px 20px rgba(47, 35, 45, 0.25);
      outline-color: $uiPurple

      .headWrapper
        .icon
          background-color: $uiPurple

  &.umami
    background-color: $umamiPurple

    &:hover
      cursor: pointer;
      box-shadow: 0 0 0 (4/1080*100)vh $uiPurple, 10px 10px 20px rgba(47, 35, 45, 0.25);
      outline-color: $uiPurple

      .headWrapper
        .icon
          background-color: $uiPurple

  &.sour
    background-color: $sourGreen

    &:hover
      cursor: pointer;
      box-shadow: 0 0 0 (4/1080*100)vh $uiPurple, 10px 10px 20px rgba(47, 35, 45, 0.25);
      outline-color: $uiPurple

      .headWrapper
        .icon
          background-color: $uiPurple

  &.salty
    background-color: $saltyBlue

    &:hover
      cursor: pointer;
      box-shadow: 0 0 0 (4/1080*100)vh $uiPurple, 10px 10px 20px rgba(47, 35, 45, 0.25);
      outline-color: $uiPurple

      .headWrapper
        .icon
          background-color: $uiPurple

  &.fatty
    background-color: $fattyYellow

    &:hover
      cursor: pointer;
      box-shadow: 0 0 0 (4/1080*100)vh $uiPurple, 10px 10px 20px rgba(47, 35, 45, 0.25);
      outline-color: $uiPurple

      .headWrapper
        .icon
          background-color: $uiPurple

  .texture
    width: 100%
    height: 100%

  .headWrapper
    color: $white
    display: flex
    flex-direction: row
    justify-content: space-between
    width: 100%
    align-items: center
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);

    .subTitle
      padding-left: (3/1080*100)vh
      // padding-top: (15/1080*100)vh
      text-align: left
      font-size: $subHead
      font-weight: 600
      color: $white
      text-transform: capitalize;
    .icon
      flex-shrink: 0
      border-radius: 100px
      padding: (5/1080*100)vh
      width: (40/1080*100)vh
      height: (40/1080*100)vh
      transition: all 100ms ease

@media(max-width: 750px)
  .tileWrapper

    .headWrapper
      color: $white
      display: flex
      flex-direction: row
      justify-content: space-between
      width: 100%
      align-items: center
      text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);

      .subTitle
        padding-left: (3/1080*100)vh
        // padding-top: (15/1080*100)vh
        text-align: left
        font-size: (13/16)rem
        font-weight: 600
        color: $white
        text-transform: capitalize;

</style>
