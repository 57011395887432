<template lang="html">
  <div class="textureOverview" :class="main ? taste : ''" :style="{backgroundImage: `url(${require(`@/assets/texture/web/${title}.png`)})`}">
    <p class="marginals">{{`${title.charAt(0).toUpperCase() + title.slice(1)}`}}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    taste: String,
    main: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.textureOverview
  // height:(160/1080*100)vh
  // width:(275/1080*100)vh
  width: 100%
  height: 100%
  background-color: $black
  background-size: cover
  border-radius: 5px
  border: (2/1080*100)vh solid $black
  background-position: center
  background-repeat: no-repeat

  &.sweet
    border-color: $sweetRed
  &.umami
    border-color: $umamiPurple
  &.fatty
    border-color: $fattyYellow
  &.salty
    border-color: $saltyBlue
  &.sour
    border-color: $sourGreen

  .marginals
    text-align: left
    color: $white
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.55);
    font-size: $subHead
    font-weight: 600
    padding: (10/1080*100)vh
</style>
