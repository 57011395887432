<template lang="html">
  <div class="frameWrapper">
    <p class="subTitle">Ingredients Overview</p>
    <div class="ingredientWrapper" :style="centerFlex ? 'justify-content: center' : 'justify-content: unset'">
      <svg class="leftArrow" v-if="pages > 1" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" @click="flipPage(-1)">
      <path d="M1 8.5L13 15.4282L13 1.5718L1 8.5Z" stroke-width="2" stroke-linejoin="round"/>
      </svg>
      <transition mode="out-in" :name="direction">
        <div class="ingredientContainer"
        :key="currentPage"
        :style="[
          centerFlex && !mobile ? 'align-self: center' : 'align-self: flex-start',
          ]">

            <TasteIngredientOverviewTile
            v-for= "(ingredient,index) in ingredientPages[currentPage]"
            class="ingredientTile"
            :key = "index"
            :title = "ingredient.title"
            :ammount = "ingredient.ammount"
            :main = "ingredient.main"
            :taste = "ingredient.taste"
            :style="{flexBasis: setFlexBasis, marginRight: setMargins}"
            :size="size"
            />
        </div>
    </transition>
      <svg class="rightArrow" v-if="pages > 1" width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" @click="flipPage(1)">
        <path d="M16 8.5L4 15.4282L4 1.5718L16 8.5Z" stroke-width="2" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="pageIndicator" v-if="pages > 1">
      <UiPageIndicator
      v-for="page in pages"
      :key="page"
      :class="{active: currentPage === page - 1}"
      @click="currentPage = page-1"
      />
    </div>
  </div>
</template>

<script>
import TasteIngredientOverviewTile from '@/components/TasteIngredientOverviewTile.vue';
import UiPageIndicator from '@/components/UiPageIndicator.vue';

export default {
  components: {
    TasteIngredientOverviewTile,
    UiPageIndicator,
  },
  props: {
    ingredients: Array,
  },
  mounted() {
    // // console.log(window.innerWidth);
    if (window.innerWidth < 750) {
      this.calcPages(4);
      this.mobile = true;
    } else {
      this.calcPages(8);
      this.mobile = false;
    }
  },
  watch: {
    windowWidth(newValue) {
      // // console.log(newValue);
      this.currentPage = 0;
      if (newValue < 750) {
        this.mobile = true;
        this.calcPages(4);
      } else {
        this.mobile = false;
        this.calcPages(8);
      }
    },
  },
  methods: {
    calcPages(num) {
      this.ingredientPages = [];
      this.pages = Math.ceil(this.ingredients.length / num);
      if (this.pages > 1) {
        for (let i = 0; i < this.pages; i += 1) {
          this.ingredientPages.push(this.ingredients.slice(i * num, ((i + 1) * num)));
        }
      } else {
        this.centerFlex = true;
        this.ingredientPages.push(this.ingredients.slice(0, this.ingredients.length));
      }
    },
    flipPage(direction) {
      const newPage = this.currentPage + direction;
      // // // console.log(newPage);
      if (newPage > this.pages - 1) {
        this.currentPage = 0;
      } else if (newPage < 0) {
        this.currentPage = this.pages - 1;
      } else {
        this.currentPage = newPage;
      }
      if (direction === -1) { this.direction = 'right'; }
      if (direction === 1) { this.direction = 'left'; }
    },
  },
  computed: {
    windowWidth() {
      // // console.log(this.$store.state.windowWidth);
      return this.$store.state.windowWidth;
    },
    setMargins() {
      if (this.mobile) return '0';
      if (this.pages > 1) { return '3.29vh'; }
      if (this.pages === 1 && this.ingredientPages[0].length > 3) { return '3.33vh'; }
      return '0';
    },
    size() {
      if (this.mobile) return ' 0.875rem';
      if (this.ingredients.length === 2) { return `${((35 / 1080) * 100)}vh`; }
      if (this.ingredients.length === 3) { return `${((30 / 1080) * 100)}vh`; }
      return `${((21 / 1080) * 100)}vh`;
    },
    setFlexBasis() {
      if (this.mobile) return '41%';
      if (this.ingredientPages[0].length < 4) { return `${(100 / this.ingredientPages[0].length)}%`; }
      return '20.7%';
    },
  },
  data() {
    return {
      mobile: false,
      centerFlex: false,
      direction: 'left',
      currentPage: 0,
      pages: 1,
      ingredientPages: [],
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.frameWrapper
  // display: inline-flex
  // flex-direction: column;
  // justify-content: space-between;
  width: (942/1080 * 100)vh
  height: (470/1080 * 100)vh
  font-feature-settings: 'ss02' on;
  background-color: $white
  box-shadow: 0 0 0 (2/1080*100)vh $black
  border-radius: $tileRadiusDesktop
  overflow: hidden
  position: relative
  text-align: center

  .ingredientWrapper
    display: flex
    padding: (10/1080*100)vh 0
    padding-top:0
    height: (380/1080*100)vh
    margin-top: (20/1080*100)vh

    .leftArrow
      align-self: center
      height: (16/1080*100)vh
      flex-shrink: 0
      stroke: $black
      fill: $white
      margin-left: (10/1080*100)vh
      transition: all 150ms ease-out

      &:hover
        fill: $uiPurple
        cursor: pointer

    .rightArrow
      align-self: center
      height: (16/1080*100)vh
      margin-right: (10/1080*100)vh
      flex-shrink: 0
      stroke: $black
      fill: $white
      transition: all 150ms ease-out
      &:hover
        fill: $uiPurple
        cursor: pointer

    .ingredientContainer
      display: flex
      flex-wrap: wrap
      width: (900/1080 * 100)vh

      &.left-enter-active, &.left-leave-active
        transition: all 150ms ease-out;

      &.left-enter-from
        transform: translateX(30%)
        opacity: 0

      &.left-leave-to
        transform: translateX(-30%)
        opacity: 0

      &.right-enter-active, &.right-leave-active
        transition: all 150ms ease-out;

      &.right-enter-from
        transform: translateX(-30%)
        opacity: 0

      &.right-leave-to
        transform: translateX(30%)
        opacity: 0

      .ingredientTile
        margin-bottom: (10/1080*100)vh

  .pageIndicator
    display: inline-flex;
    flex-direction: row
    margin-bottom: (14/1080*100)vh
    margin-left: auto
    margin-right: auto
    .circle.active
      background-color: $uiPurple
      border-color: $uiPurple

  .subTitle
    padding-left: (14/1080*100)vh
    padding-top: (18/1080*100)vh
    text-align: left
    font-size: $subHead
    font-weight: 600
    color: $gray

@media (max-width: 750px)
  .frameWrapper
    // display: inline-flex
    // flex-direction: column;
    // justify-content: space-between;
    width: (470/1080 * 100)vh
    height: (470/1080 * 100)vh
    font-feature-settings: inherrit;
    grid-column-end: inherit

    .ingredientWrapper
      display: flex
      padding: (10/1080*100)vh 0
      padding-top:0
      height: (380/1080*100)vh
      margin-top: (12/1080*100)vh

      height: (380/1080*100)vh
      .leftArrow
        align-self: center
        height: (16/1080*100)vh
        flex-shrink: 0
        stroke: $black
        fill: $white
        margin-left: (10/1080*100)vh
        transition: all 150ms ease-out

        &:hover
          fill: $uiPurple
          cursor: pointer

      .rightArrow
        align-self: center
        height: (16/1080*100)vh
        margin-right: (10/1080*100)vh
        flex-shrink: 0
        stroke: $black
        fill: $white
        transition: all 150ms ease-out
        &:hover
          fill: $uiPurple
          cursor: pointer

      .ingredientContainer
        display: flex
        flex-wrap: wrap
        justify-content: space-around;
        align-content: flex-start;
        width: 100%

        .ingredientTile
          margin-bottom: (20/1080*100)vh

    .pageIndicator
      display: inline-flex;
      flex-direction: row
      margin-bottom: (14/1080*100)vh
      margin-left: auto
      margin-right: auto
      .circle.active
        background-color: $uiPurple
        border-color: $uiPurple

    .subTitle
      padding-left: (14/1080*100)vh
      padding-top: (18/1080*100)vh
      text-align: left
      font-size: (13/16)rem
      font-weight: 600
      color: $gray
</style>
