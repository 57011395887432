<template lang="html">
  <div class="formElement">
    <ui-header-help
    title="Customize preview"
    infoTitle="Create custom preview"
    infoText="To easily navigate from the dashboard to a dish you can create a preview tile. Customize it by adding a photo and/or a color."/>
    <ui-button class="upload" label="Upload Image" icon="upload" mode="dark" @buttonClick="$refs.fileType.click()"/>
    <input type="file" ref="fileType" accept="image/*" @change="uploadImage">
    <ui-color-choose :colorIn="color" @setTileColor="handleInput"/>
    <!-- <p>{{color}}</p> -->
  </div>
</template>

<script>
import UiHeaderHelp from '@/components/UiHeaderHelp.vue';
import UiButton from '@/components/UiButton.vue';
import UiColorChoose from '@/components/UiColorChoose.vue';

export default {
  components: {
    UiHeaderHelp,
    UiColorChoose,
    UiButton,
  },
  created() {
    if (this.previewIn.color) this.color = this.previewIn.color;
  },
  methods: {
    handleInput(value) {
      this.color = value;
      this.$emit('outputCol', this.color);
    },
    uploadImage(e) {
      // // console.log(e.target.files);
      // // // console.log(URL.createObjectURL(e.target.files[0]));
      const fileReader = new FileReader();
      fileReader.onloadend = (el) => {
        const dataUrl = el.target.result;
        // // console.log(dataUrl);
        this.$emit('outputUrl', dataUrl);
      };
      fileReader.readAsDataURL(e.target.files[0]);
    },
  },
  props: {
    previewIn: Object,
  },
  data() {
    return {
      color: '',
    };
  },
  emits: [
    'outputUrl',
    'outputCol',
  ],
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.formElement
  position: relative;
  color: $white
  height: 32vh
  .upload
    width: 100%
    margin-bottom: (35/16)rem
  p
    position: absolute;
    margin-top: 30px
  input[type=file]
    display: none
@media(max-width: 750px)
  .formElement
    height: auto
    margin-bottom: 12vh
</style>
